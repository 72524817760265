import { http } from './http';
import { UserUsage } from 'types';

export const updateUser = (payload: {
  user_name: string;
  receive_newsletter: boolean;
}) => http.patch('user/', JSON.stringify(payload));

export const userUsage = (): Promise<UserUsage> => http.get('user/usage');

export const resendInvitationForActivation = (email: string) =>
  http.post(
    'user/resend-invite',
    JSON.stringify({
      email,
    })
  );

export const removeUserFromCompany = (id: string) =>
  http.delete(`user/company/${id}`);
