import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { HelpIcon } from 'assets';
import useStyles from './subtitleheader.styles';

export interface Props {
  title?: string;
  showHelpIcon: true | false;
}

export const SubtitleHeaderMisc = ({ title, showHelpIcon = true }: Props) => {
  const classes = useStyles();
  return (
    <Grid container item alignItems="center" justify="flex-start" md={12}>
      <Box className={classes.scoreLabelContainer}>
        <Typography className={classes.subtitleMisc}>
          {' '}
          {title && title.toUpperCase()}
        </Typography>
      </Box>
      {showHelpIcon && <HelpIcon />}
    </Grid>
  );
};
