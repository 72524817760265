import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { CopyToClipboardIcon } from 'assets';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import useStyles from './copyapikey.styles';
import { copyTextToClipboard } from 'utils';

type CopyApiKeyProps = {
  bg?: string;
  apiKeyString?: string;
};

export const CopyApiKey = ({ bg, apiKeyString }: CopyApiKeyProps) => {
  const [apiKey, setApiKey] = useState('');
  const classes = useStyles({
    bg,
  });
  const [copied, setCopied] = useState(false);
  const containerRef = useRef(null);

  const onClickHandler = () => {
    copyTextToClipboard(apiKey, containerRef);

    setCopied(true);
  };

  const closeSnackbarHandler = () => {
    setCopied(false);
  };

  useEffect(() => {
    setApiKey(apiKeyString || '');
  }, [setApiKey, apiKeyString]);

  return (
    <Grid ref={containerRef} className={classes.apiKeyContainer}>
      <Typography className={classes.apiKeyText}>{apiKey}</Typography>
      <Grid className={classes.copyToClipboard} onClick={onClickHandler}>
        <CopyToClipboardIcon />
      </Grid>

      <Snackbar
        open={copied}
        autoHideDuration={6000}
        onClose={closeSnackbarHandler}
      >
        <MuiAlert severity="success">Copied to clipboard!</MuiAlert>
      </Snackbar>
    </Grid>
  );
};
