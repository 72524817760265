import React from 'react';
import { Prompt } from 'react-router-dom';
import { useBeforeUnload } from './useBeforeUnload';

type usePromptProps = {
  when: boolean;
  message: string;
};

export const usePrompt = (props: usePromptProps) => {
  useBeforeUnload(props);
  return () => <Prompt {...props} />;
};
