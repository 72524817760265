import { makeStyles } from '@material-ui/core';
import { severityScheme } from 'material';
import { AlertProps, Severity } from 'types';

type StyleProps = {
  severity: Severity | undefined;
  position: string;
  floating: boolean;
  styles: AlertProps['styles'];
};

export default makeStyles(() => ({
  fullwidthBar: ({ severity, position, floating, styles }: StyleProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: floating ? 'absolute' : 'static',
    top: position === 'top' ? '0' : 'unset',
    bottom: position === 'bottom' ? '0' : 'unset',
    width: '100%',
    backgroundColor:
      severityScheme[severity || 'default']?.backgroundColor || '#4CAF50',
    '& p': {
      color: 'white',
    },

    animation: '$slideUp 1s ease-in-out',

    ...(styles || {}),
  }),

  '@keyframes slideUp': {
    '0%': {
      bottom: '-60px',
    },
    '100%': {
      bottom: '0',
    },
  },

  content: {
    width: '100%',
    height: '100%',
    position: 'relative',
    padding: '12px 32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  closeIcon: {
    '& g': {
      fill: 'white',
    },
    position: 'absolute',
    right: '12px',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  },
}));
