import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  planInfoContainer: {
    overflow: 'hidden',
    backgroundColor: colors.blue50,
    padding: '10px',
    borderRadius: '5px',
    position: 'absolute',
    top: '50px',
    width: 'max-content',
    zIndex: 1,
  },
  planInfoSpinner: {
    position: 'absolute',
    background: 'rgb(0 0 0 / 25%)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: '12px',
    display: 'flex',
    color: colors.blue5,
    letterSpacing: 'normal',
    fontFamily: 'Poppins',
  },
  textTotal: {
    color: colors.blue20,
  },
  textContainer: {
    display: 'flex',
    gap: '12px',
  },
  expirationText: {
    '& p': {
      color: colors.blue20,
      fontSize: '10px',
    },
    marginTop: '12px',
  },
}));
