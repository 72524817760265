import { http } from './http';
import {
  LoginData,
  LoginResponse,
  User,
  ActivationData,
  ActivationResponse,
  ForgotPassRequest,
  ForgotPassResponse,
} from 'types';

export const login = (data: LoginData): Promise<LoginResponse> =>
  http.post('login', new URLSearchParams({ ...data }), {
    'Content-Type': 'application/x-www-form-urlencoded',
  });

export const me = (): Promise<User> => http.get('user/');

export const activateAccount = (
  data: ActivationData
): Promise<ActivationResponse> =>
  http.post('account_activation', JSON.stringify(data));

export const forgotPasswordLinkRequest = (
  data: ForgotPassRequest
): Promise<ForgotPassResponse> =>
  http.post('reset_password_request', JSON.stringify(data), {
    'Content-Type': 'application/x-www-form-urlencoded',
  });

export const resetPassword = (
  data: ActivationData
): Promise<ActivationResponse> =>
  http.post('reset_password', JSON.stringify(data), {
    'Content-Type': 'application/x-www-form-urlencoded',
  });

export const signUpToCompany = (data: {
  id_company: string;
  email: string;
}): Promise<{ id_user: string; email: string; user_name: string }> =>
  http.post('company/signup', JSON.stringify(data));

export const changePassword = (data: { password: string }) =>
  http.post('change_password', JSON.stringify(data));
