import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  title: {
    fontSize: '24px',
    color: 'white',
    fontWeight: 700,
  },

  description: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'white',
    height: '70px',
  },

  button: {
    borderRadius: '8px',
    backgroundColor: 'white',
    boxShadow: '0px 3px 8px 0px #42497A',
    border: 'none',
  },
}));
