import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';
export default makeStyles(() => ({
  subtitleMisc: {
    fontFamily: 'Poppins',
    fontSize: '8px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: '0.6px',
    color: colors.blue50,
    marginBottom: '3px',
  },
  scoreLabelContainer: {
    maxWidth: 'auto',
    textAlign: 'left',
    marginTop: '3px',
  },
}));
