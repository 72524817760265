import React, { ReactNode, useState, ChangeEvent, useEffect } from 'react';
import { Box, Button, TextField } from '@material-ui/core';

import useStyles from './EntityForm.styles';
import { ModalWithHelpScreen, SimpleMessage, Text } from 'components';

import randomColor from 'randomcolor';
import { HelpIconOutlined } from 'assets';

type EntryFormProps = {
  openModalElement: ReactNode;
  addEntityHandler?(entity: any): void;
  editEntryHandler?(entity: any): void;
  entityExists(entityName: string): boolean;
  editMode?: boolean;
  id?: string;
  name?: string;
  description?: string;
  input_format?: string;
  output_format?: string;
  color?: string;
};

export const EntityForm = ({
  editMode = false,
  openModalElement,
  addEntityHandler,
  editEntryHandler,
  entityExists,
  id = '',
  name = '',
  description = '',
  input_format = '',
  output_format = '',
  color = '',
}: EntryFormProps) => {
  const classes = useStyles();
  const [entity, setEntity] = useState({
    id,
    name,
    description,
    input_format,
    output_format,
    color,
  });

  const [pointerHeight, setPointerHeight] = useState('');

  const [open, setOpen] = useState(false);

  const [error, setError] = useState('');

  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e?.target;

    setEntity((s) => ({ ...s, [name]: value }));
  };

  const openModalHandler = () => {
    setOpen(true);
  };

  const closeModalHandler = () => {
    setOpen(false);
  };

  const onEntityAction = () => {
    if (!entity.name) {
      setError('Entity name is required');

      return;
    }

    if (editMode) {
      setError('');
      editEntryHandler?.(entity);
      closeModalHandler();
      return;
    }

    const color = randomColor();

    if (entityExists(entity.name)) {
      setError(`Entity "${entity.name}" already exists.`);
      return;
    }

    setError('');
    addEntityHandler?.({ ...entity, color });
    setEntity({
      id: '',
      name: '',
      description: '',
      input_format: '',
      output_format: '',
      color: '',
    });
    closeModalHandler();
  };

  useEffect(() => {
    return () => {
      setError('');
    };
  }, []);

  const [helperContent, setHelperContent] = useState<ReactNode>(null);

  const openEntityNameHelper = () => {
    setPointerHeight(`152px`);
    setHelperContent(
      <div>
        <Text variant="h3" className={classes.helpTitle}>
          Entity name
        </Text>
        <Text variant="paragraph1" className={classes.helpDescription}>
          <span>
            This is the entity or field name you want to extract, and it will be
            used in the output as the key identifier.
          </span>
          <span style={{ marginTop: '24px' }}>
            It is recommended to be the most explicit possible and use the
            underscore “_” between concepts.
          </span>
          <span style={{ marginTop: '32px' }}>For example:</span>
          <span style={{ marginTop: '12px' }}>
            invoice_date, receipt_number, passport_number, expiration_date, etc.
          </span>
        </Text>
      </div>
    );
  };
  const openEntityDescHelper = () => {
    setPointerHeight(`237px`);
    setHelperContent(
      <div>
        <Text variant="h3" className={classes.helpTitle}>
          Entity description
        </Text>
        <Text variant="paragraph1" className={classes.helpDescription}>
          <span>
            This is an optional description of the entity, but it could be very
            helpful to give extra instructions to be more effective in
            identifying an entity that it is not a common type like a date,
            number, or currency.
          </span>

          <span style={{ marginTop: '12px' }}>
            For example, you can validate specific formats like: Starts with a
            letter Must be a 10 digits number It is also helpful for extracting
            a list of items, like an invoice or receipt details.
          </span>
          <span style={{ marginTop: '24px' }}>
            This is an example of a whole entity definition:
          </span>
          <span style={{ marginTop: '12px' }}>Name: line_items</span>

          <span style={{ marginTop: '4px' }}>
            Description: Each line present in the receipt
          </span>
          <span style={{ marginTop: '4px' }}>Output: List of objects</span>
        </Text>
      </div>
    );
  };

  const openEntityOutputHelper = () => {
    setPointerHeight(`337px`);
    setHelperContent(
      <div>
        <Text variant="h3" className={classes.helpTitle}>
          Output format
        </Text>
        <Text variant="paragraph1" className={classes.helpDescription}>
          <span>
            You can use this to convert an extracted value to a specific format.
          </span>
          <span style={{ marginTop: '32px' }}>Examples:</span>

          <span style={{ marginTop: '12px' }}>
            For date, you can use “MM/DD/YYYY” or “MM-DD-YYYY”, etc.
          </span>
          <span style={{ marginTop: '12px' }}>
            For text, you can use “Uppercase” or “Lowercase”.
          </span>
          <span style={{ marginTop: '32px' }}>
            To get a decimal value instead of a text with the number inside, you
            can use “float”.
          </span>
        </Text>
      </div>
    );
  };

  const closeHelperContent = () => {
    setHelperContent(null);
  };

  return (
    <>
      <Box onClick={openModalHandler}>{openModalElement}</Box>

      <ModalWithHelpScreen
        size="xs"
        isOpen={open}
        helperContent={helperContent}
        closeHelperContent={closeHelperContent}
        pointerHeight={pointerHeight}
        closeHandler={closeModalHandler}
        preventBackdropClose={true}
      >
        <Box
          className={classes.formContainer}
          display="flex"
          flexDirection="column"
          gridGap="12px"
        >
          <Text variant="h2" className={classes.title}>
            {editMode ? 'Edit entity' : 'Add entity'}
          </Text>
          <Box
            display="flex"
            gridGap="14px"
            flexDirection="column"
            marginBottom="12px"
          >
            <Box>
              <Box display="flex" gridGap="8px">
                <TextField
                  name="name"
                  label="Entity name"
                  variant="outlined"
                  placeholder=""
                  onChange={onChangeHandler}
                  className={classes.input}
                  size="medium"
                  value={entity.name}
                />
                <div className={classes.helpContainer}>
                  <HelpIconOutlined
                    className={classes.helpIcon}
                    onClick={openEntityNameHelper}
                  />
                </div>
              </Box>
              <Text variant="paragraph1" className={classes.helpText}>
                e.g. receipt_date, passport_number
              </Text>
            </Box>

            <Box>
              <Box display="flex" gridGap="8px">
                <textarea
                  name="description"
                  cols={2}
                  placeholder="Entity description (Optional)"
                  onChange={onChangeHandler}
                  className={classes.textarea}
                  value={entity.description}
                  rows={2}
                />
                <div className={classes.helpContainer}>
                  <HelpIconOutlined
                    className={classes.helpIcon}
                    onClick={openEntityDescHelper}
                  />
                </div>
              </Box>
              <Text variant="paragraph1" className={classes.helpText}>
                e.g. The passport number has a maximum of 9 characters.
              </Text>
            </Box>

            <Box>
              <Box display="flex" gridGap="8px">
                <TextField
                  label="Output format (Optional)"
                  name="output_format"
                  variant="outlined"
                  placeholder=""
                  onChange={onChangeHandler}
                  className={classes.input}
                  value={entity.output_format}
                />
                <div className={classes.helpContainer}>
                  <HelpIconOutlined
                    className={classes.helpIcon}
                    onClick={openEntityOutputHelper}
                  />
                </div>
              </Box>

              <Text variant="paragraph1" className={classes.helpText}>
                e.g. MM/DD/YYYY, Uppercase
              </Text>
            </Box>
          </Box>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            onClick={onEntityAction}
          >
            {editMode ? 'Edit' : 'Add'}
          </Button>
          {error && (
            <SimpleMessage type="error" icon={true}>
              <Text variant="paragraph1">{error}</Text>
            </SimpleMessage>
          )}
        </Box>
      </ModalWithHelpScreen>
    </>
  );
};
