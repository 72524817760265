import React, { ChangeEvent } from 'react';
import useStyles from './SearchModal.styles';
import { Dialog, DialogContent, Grid, Box } from '@material-ui/core';
import { Experiment, GenericSimpleModalProps, ModalSize } from 'types';
import Downshift from 'downshift';
import { Input } from './Input';
import { SearchElement } from './SearchElement';
import { Text } from 'components';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import { getExperimentDescriptionText, isJSON } from 'utils';

interface SearchModalProps extends Omit<GenericSimpleModalProps, 'size'> {
  size?: ModalSize;
  experiments: Experiment[];
  onChangeHandler(event: ChangeEvent<HTMLInputElement>): void;
}

export const SearchModal = ({
  experiments,
  isOpen = false,
  onChangeHandler,
  closeHandler,
}: SearchModalProps) => {
  const classes = useStyles();

  const { push } = useHistory();

  const experimentsList = experiments.map(
    ({ title, logo, type, description, task, id }) => ({
      name: title,
      logo,
      type,
      description: isJSON(description)
        ? getExperimentDescriptionText(description)
        : description,
      task,
      id,
    })
  );

  const selectExperiment = (experiment: any) => {
    push(`/experiment/${experiment.id}`);
  };

  const inputCallback = (ref: HTMLInputElement) => {
    ref?.focus();
  };

  return (
    <Dialog
      onClose={closeHandler}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      className={classes.modal}
    >
      <Grid
        container
        item
        direction="column"
        className={classes.modalContainer}
      >
        <DialogContent
          classes={{
            root: classes.customRoot,
          }}
          className={classes.dialogContent}
        >
          <Downshift
            itemToString={(experiments) =>
              experiments ? experiments.name : ''
            }
            onChange={selectExperiment}
          >
            {({
              getInputProps,
              getItemProps,
              isOpen,
              inputValue,
              highlightedIndex,
              selectedItem,
            }) => (
              <div>
                <Box className={classes.inputContainer}>
                  <Input
                    {...getInputProps({
                      placeholder: 'Search projects',
                      onChange: onChangeHandler,
                    })}
                    ref={inputCallback}
                  />
                </Box>
                {isOpen ? (
                  <div
                    className={classNames(
                      'downshift-dropdown',
                      classes.searchElementsContainer
                    )}
                  >
                    {experimentsList.map((item, index) => (
                      <SearchElement
                        {...item}
                        description={item.description}
                        name={item.name}
                        image={item.logo}
                        type={item.type}
                        task={item.task}
                        highlighted={highlightedIndex === index}
                        selected={selectedItem === item}
                        {...getItemProps({
                          key: `${item.name}-${index}`,
                          index,
                          item,
                        })}
                      />
                    ))}

                    {experimentsList.length === 0 && (
                      <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginTop="40px"
                      >
                        <Text variant="paragraph1">
                          🔍 No results for "{inputValue}"
                        </Text>
                      </Box>
                    )}
                  </div>
                ) : (
                  <Box
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="40px"
                  >
                    <Text variant="paragraph1">
                      🔍 Search results display here
                    </Text>
                  </Box>
                )}
              </div>
            )}
          </Downshift>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};
