import React from 'react';

import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import useStyles from './datanumericlabel.style';
import { HelpIcon } from 'assets';

export interface IDataNumericLabelProps {
  number: number;
  label: string;
  tooltipText?: string;
}

export const DataNumericLabel = ({
  number,
  label,
  tooltipText,
}: IDataNumericLabelProps) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Grid container justify="center" alignItems="center">
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          className={classes.accuracyBox}
        >
          <Typography className={classes.scoreLabelNumber} variant="body1">
            {number}
          </Typography>
          <Box className={classes.scoreLabelContainer}>
            <Typography className={classes.scoreTextLabe} variant="body1">
              {label}
            </Typography>
          </Box>
          <Tooltip title={tooltipText ? tooltipText : '...'}>
            <HelpIcon />
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};
