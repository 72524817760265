import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  wrapper: {
    width: '305px',
    height: '364px',
    borderRadius: '12px',
    boxShadow: '0px 16px 24px 0px rgba(72, 79, 121, 0.15)',
    backgroundColor: '#F7F9FF',
    padding: '32px 50px',
    boxSizing: 'border-box',
    border: '1px solid rgba(72, 79, 121, 0.15)',
  },

  image: {
    '& svg': {
      height: '120px',
    },
  },

  title: {
    '& > p': {
      fontWeight: 700,
      fontSize: '24px',
    },
  },

  description: {
    '& > p': {
      fontSize: '14px',
    },
  },

  selectButton: {
    padding: '0 34px',
  },
}));
