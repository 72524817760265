import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { colors } from 'material/colors';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

interface TypographyMaterialProps extends TypographyProps {
  component?: React.ElementType;
}

type StylesProps = {
  isLinkColor?: boolean;
  underlined?: boolean;
  size?: string;
};

interface StyledTextLinkProps extends TypographyMaterialProps, StylesProps {}

const useStyles = makeStyles({
  root: {
    fontFamily: "'PT Serif', serif",
    fontSize: ({ size }: StylesProps) => size || '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'underline',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: ({ isLinkColor }: StylesProps) => (isLinkColor ? colors.orange : ''),
    textDecoration: ({ underlined }: StylesProps) =>
      underlined ? 'underline' : 'none',
    cursor: 'pointer',
  },
});

export const StyledTextLink = ({
  isLinkColor = true,
  underlined = false,
  size,
  className,
  ...rest
}: StyledTextLinkProps) => {
  const classes = useStyles({ isLinkColor, underlined, size });

  return (
    <Typography className={classNames(classes.root, className)} {...rest} />
  );
};
