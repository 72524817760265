/**
 * TODO
 * Implement properly plan time period tab change
 */

export type PlanData = {
  id: string;
  planName: string;
  description: string;
  price: number;
  period: string;
  actionButtonText: string;
  properties: string[];
  hasActionButton?: boolean;
  isDark?: boolean;
  additionalPredictionText: string[];
};

export const plansInfo: { [key: number]: PlanData[] } = {
  0: [
    {
      id: '1cda01cc-bece-2001-d0fe-e24101bba91f',
      planName: 'AppSumo Tier 1',
      description: '',
      price: 79,
      period: 'One time purchase',
      actionButtonText: 'Upgrade',
      hasActionButton: false,
      properties: [
        '15,000 predictions per month',
        '50 training hours per month',
        '5 users',
        'Unlimited trainings per month',
      ],
      additionalPredictionText: [],
    },
    {
      id: '881100cc-bba1-3013-11cd-da331101a0f1',
      planName: 'AppSumo Tier 2',
      description: '',
      price: 159,
      period: 'One time purchase',
      actionButtonText: 'Upgrade',
      hasActionButton: false,
      properties: [
        '30,000 predictions per month',
        '75 training hours per month',
        '15 users',
        'Unlimited trainings per month',
      ],
      additionalPredictionText: [],
    },
    {
      id: '761420ae-fbfb-8805-515a-ea155341b0ec',
      planName: 'AppSumo Tier 3',
      description: '',
      price: 239,
      period: 'One time purchase',
      actionButtonText: 'Upgrade',
      hasActionButton: false,
      properties: [
        '75,000 predictions per month',
        '100 training hours per month',
        'Unlimited users',
        'Unlimited trainings per month',
        'Active learning',
      ],
      additionalPredictionText: [],
    },
    {
      id: '660754cd-dc1d-ddaa-a1bd-da25835510ca',
      planName: 'AppSumo Tier 4',
      description: '',
      price: 319,
      period: 'One time purchase',
      actionButtonText: 'Upgrade',
      hasActionButton: false,
      properties: [
        '100,000 predictions per month',
        '200 training hours per month',
        'Unlimited users',
        'Unlimited trainings per month',
        'Active learning',
      ],
      additionalPredictionText: [],
    },
    {
      id: '70100211-1100-05a7-77ff-afaf10012113',
      planName: 'AppSumo Tier 5',
      description: '',
      price: 399,
      period: 'One time purchase',
      actionButtonText: 'Upgrade',
      hasActionButton: false,
      properties: [
        '200,000 predictions per month',
        '300 training hours per month',
        'Unlimited users',
        'Unlimited trainings per month',
        'Active learning',
      ],
      additionalPredictionText: [],
    },
  ],
};
