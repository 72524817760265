import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Box,
} from '@material-ui/core';
import { IconClose } from 'assets';
import { GenericSimpleModalProps } from 'types';

import { Helper } from './Helper';

import useStyles from './ModalWithHelpScreen.styles';

type ModalWithHelpScreenProps = GenericSimpleModalProps & {
  helperContent: React.ReactNode | null;
  closeHelperContent: () => void;
  pointerHeight?: string;
  closeHandler: () => void;
  preventBackdropClose?: boolean;
};

export const ModalWithHelpScreen = ({
  children,
  size,
  isOpen = false,
  closeHandler,
  helperContent,
  closeHelperContent,
  pointerHeight,
  preventBackdropClose,
}: ModalWithHelpScreenProps) => {
  const classes = useStyles({
    isDouble: !!helperContent,
  });

  const closeHandlerWrapper = (e: any, reason: string) => {
    if (reason === 'backdropClick' && preventBackdropClose) return;
    closeHandler();
  };

  return (
    <Dialog
      onClose={closeHandlerWrapper}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      classes={{
        paper: classes.paper,
      }}
    >
      <Box className={classes.layout}>
        <Grid
          container
          item
          direction="column"
          className={classes.modalContainer}
        >
          <DialogTitle className={classes.modalTitle}>
            <Grid container item sm={12} md={12} justify="flex-end">
              <IconButton aria-label="close" onClick={closeHandler}>
                <IconClose />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent
            classes={{
              root: classes.customRoot,
            }}
            className={classes.dialogContent}
          >
            {children}
          </DialogContent>
        </Grid>

        {helperContent && (
          <Box className={classes.helper}>
            <Helper
              content={helperContent}
              closeHandler={closeHelperContent}
              pointerHeight={pointerHeight}
            />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
