import { getEnv } from './env';
import { http } from './http';
import { PresignedUploadResponse, PredictResponse } from 'types';

const REACT_APP_BUCKET_ENDPOINT = getEnv('REACT_APP_BUCKET_ENDPOINT');
const REACT_APP_BUCKET_NAME = getEnv('REACT_APP_BUCKET_NAME');

export const getPresignedUrl = (
  filename: string
): Promise<PresignedUploadResponse> => http.get(`upload/${filename}`);

export const getPresignedUrlPredict = (
  format: string,
  id: string,
  filename: string
): Promise<PredictResponse> =>
  http.post(
    `image/classification/predict/${id}`,
    JSON.stringify({
      format,
      base64_image: filename,
    })
  );

export const getFilename = (filename: string) => {
  return filename.split('/').pop();
};

export const buildPublicUrl = (file: string, bucket: string) => {
  return `${REACT_APP_BUCKET_ENDPOINT}/${bucket}/${file}`;
};

export const buildChatWidgetUrl = () =>
  `${REACT_APP_BUCKET_ENDPOINT}/${REACT_APP_BUCKET_NAME}/widget/chat.js`;

export const getJSONDashboardCards = () =>
  //fetch('https://static-cogniflow-dev.s3.us-east-2.amazonaws.com/app_data.json')
  fetch(`${REACT_APP_BUCKET_ENDPOINT}/${REACT_APP_BUCKET_NAME}/app_data.json`)
    .then((response) => response.json())
    .catch(() => ({
      dashboard_left_cards: [],
      dashboard_right_cards: [],
      featured_projects: [],
    }));
