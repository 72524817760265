import React from 'react';
import { Box } from '@material-ui/core';
import { Text } from 'components/Text';

import useStyles from './ProgressBar.styles';

type ProgressBarProps = {
  progress?: number;
  showText?: boolean;
};

export const ProgressBar = ({
  progress = 0,
  showText = true,
}: ProgressBarProps) => {
  const classes = useStyles({
    progress,
  });

  return (
    <Box display="flex" flexDirection="column" gridGap="8px" width="100%">
      {showText && (
        <Text
          variant="paragraph1"
          className={classes.progressText}
          align="left"
        >
          Uploading - {progress}%
        </Text>
      )}
      <Box className={classes.progressBg}>
        <Box className={classes.progress}></Box>
      </Box>
    </Box>
  );
};
