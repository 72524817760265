import { Overrides } from '@material-ui/core/styles/overrides';
import { colors } from '../colors';

export const Select: Overrides['MuiSelect'] = {
  selectMenu: {
    color: colors.blue70,
  },
  root: {
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 16,
    lineHeight: '1.5',
  },
};
