import React, { ReactNode } from 'react';
import useStyle from './loginform.styles';
import { AuthFormCard } from '../AuthFormCard';
import { useHistory } from 'react-router-dom';
import { Text } from 'components';

interface Props {
  children: ReactNode;
}

export const LoginForm = ({ children }: Props) => {
  const classes = useStyle();
  const { push } = useHistory();
  const onSignupLinkClick = () => {
    push('/signup');
  };

  return (
    <AuthFormCard
      subtitleComponent={
        <div className={classes.formTitleSection}>
          {/* <span className={classes.headerTitle}>
            Build amazing AI without any code
          </span> */}
        </div>
      }
      bodyComponent={<div className={classes.formSection}>{children}</div>}
      footerComponent={
        <div className={classes.footerSection}>
          <Text variant="paragraph1">
            New to Cogniflow?{' '}
            <label onClick={onSignupLinkClick} className={classes.signupLink}>
              Signup for free
            </label>
          </Text>
        </div>
      }
    />
  );
};
