import { Overrides } from '@material-ui/core/styles/overrides';
import { colors } from '../colors';

export const CardHeader: Overrides['MuiCardHeader'] = {
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    color: colors.blue70,
  },
};
