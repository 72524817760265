import React, { ReactNode } from 'react';
import { Box, RadioGroup } from '@material-ui/core';
import { UnControlledModal, Text } from 'components';
import { ModalItem } from './ModalItem';
import useStyles from './datasetmoda.styles';
import { DatasetProps } from 'types';

type DatasetsModalProps = {
  datasets: DatasetProps[];
  button: ReactNode;
};

export const DatasetsModal = ({ datasets, button }: DatasetsModalProps) => {
  const classes = useStyles();

  return (
    <UnControlledModal size="sm" openModalButton={button} isOpen={false}>
      <Text variant="h2">Datasets</Text>
      <Text variant="paragraph1" className={classes.modalDescription}>
        Explore some dataset examples you can use for inspiration.
      </Text>

      <RadioGroup name="dataset-options">
        <Box display="flex" flexDirection="column" gridGap="12px">
          {datasets.map(({ url, name, stats, description }) => (
            <ModalItem
              key={name}
              url={url}
              name={name}
              stats={stats}
              description={description}
            />
          ))}
          <Box display="flex" justifyContent="end">
            {/* <ActionButton variant="contained" color="secondary" size="large">
              Use dataset
            </ActionButton> */}
          </Box>
        </Box>
      </RadioGroup>
    </UnControlledModal>
  );
};
