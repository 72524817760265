import { useSetRecoilState } from 'recoil';
import { currentExperimentState } from 'store/experiments';

export const useExperimentState = () => {
  const setExperiment = useSetRecoilState(currentExperimentState);

  // TODO: Experiment property type
  const updateExperimentProp = (prop: any) => {
    setExperiment((s) => ({
      ...s,
      ...prop,
    }));
  };

  return {
    updateExperimentProp,
  };
};
