import React, { forwardRef } from 'react';

import useStyles from './Input.styles';
import { Box } from '@material-ui/core';
import { SearchIcon } from 'assets';

export const Input = forwardRef(
  ({ ...props }, ref: React.Ref<HTMLInputElement>) => {
    const classes = useStyles();

    return (
      <Box className={classes.inputBox}>
        <Box>
          <SearchIcon />
        </Box>
        <input {...props} className={classes.input} ref={ref} />
      </Box>
    );
  }
);
