import { makeStyles } from '@material-ui/core';

type StyleProps = {
  color: string;
};

export default makeStyles(() => ({
  input: {
    width: '0',
    height: '0',
    padding: '0',
    border: 'none',
    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },

    visibility: 'hidden',
  },
  circle: ({ color }: StyleProps) => ({
    display: 'block',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: color || 'gray',
    cursor: 'pointer',
  }),
}));
