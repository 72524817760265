import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  captureCameraOff: {
    display: 'none',
  },
  captureCameraOn: {
    display: 'block',
  },
  cameraWarning: {
    height: 250,
  },
}));
