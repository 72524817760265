import { EXPERIMENT_TASK, EXPERIMENT_TYPE } from 'types';

export const urlMap: Record<number, string | Record<number, string>> = {
  [EXPERIMENT_TASK.TEXT_CLASSIFICATION]: 'text/classification/predict',
  [EXPERIMENT_TASK.TEXT_LLM_CLASSIFICATION]: {
    [EXPERIMENT_TYPE.TEXT]: 'text/llm-classification/predict',
    [EXPERIMENT_TYPE.IMAGE]: 'image/llm-classification/predict',
  },
  [EXPERIMENT_TASK.TEXT_NER_RESPONSE]:
    'text/information-extraction/extract-entities',
  [EXPERIMENT_TASK.TEXT_TRANSLATION]: 'text/translation/translate',
  [EXPERIMENT_TASK.TEXT_QANDA]: 'text/question-answering/ask',
  [EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR]: {
    [EXPERIMENT_TYPE.TEXT]: 'text/smart-extraction/extract',
    [EXPERIMENT_TYPE.IMAGE]: 'image/smart-extraction/extract',
  },
  [EXPERIMENT_TASK.IMAGE_CLASSIFICATION]: 'image/classification/predict',
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: 'image/object-detection/detect',
  [EXPERIMENT_TASK.IMAGE_OCR]: 'image/ocr/recognize',
  [EXPERIMENT_TASK.IMAGE_FACE_SIMILARITY]: 'face-similarity/predict',
  [EXPERIMENT_TASK.AUDIO_CLASSIFICATION]: 'audio/classification/predict',
  [EXPERIMENT_TASK.AUDIO_SPEECH2TEXT]: 'audio/speech2text/transcribe',
};

export const getPredictionUrl = (task: number, type: number) => {
  const url = urlMap[task];
  return typeof url === 'object' ? url[type] : url;
};
