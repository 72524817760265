import { Category as ClassificationCategory } from 'components/ClassificationSettings/ClassificationSettings';
import { Application, EntityProps } from 'types';

export const EXPERIMENT_TYPE_TEXT = 0;
export const EXPERIMENT_TYPE_IMAGE = 1;
export const EXPERIMENT_TYPE_AUDIO = 2;

export const STATUS_PENDING = 0; //pending
export const STATUS_IN_QUEUE = 1; //waiting
export const STATUS_RUNNING = 2; //running
export const STATUS_FINISHED = 3; //ready
export const STATUS_CANCELED_BY_USER = 4; //canceled
export const STATUS_CANCELED_BY_TIMEOUT = 5; //timed out
export const STATUS_FINISHED_WITH_ERROR = 6; //error

export enum EXPERIMENT_TASK {
  TEXT_CLASSIFICATION = 0,
  TEXT_LLM_CLASSIFICATION = 5,
  TEXT_TRANSLATION = 1,
  TEXT_NER_RESPONSE = 2,
  TEXT_QANDA = 3,
  TEXT_CUSTOM_ENTITY_EXTRACTOR = 4,
  IMAGE_CLASSIFICATION = 100,
  IMAGE_OBJECT_DETECTION = 101,
  IMAGE_OCR = 102,
  IMAGE_FACE_SIMILARITY = 103,
  IMAGE_LLM_CLASSIFICATION = 5,
  IMAGE_CUSTOM_ENTITY_EXTRACTOR = 4,
  AUDIO_CLASSIFICATION = 200,
  AUDIO_SPEECH2TEXT = 201,
  AUDIO_CUSTOM_ENTITY_EXTRACTOR = 202,
}

export enum EXPERIMENT_TYPE_STR {
  TEXT = 'text',
  IMAGE = 'image',
  AUDIO = 'audio',
}

export enum EXPERIMENT_TYPE {
  TEXT = 0,
  IMAGE = 1,
  AUDIO = 2,
}

export type ExperimentLabel = {
  [key: string]: string;
  [key: number]: string;
};

export interface Experiment {
  id: string;
  id_user: string;
  type: 0 | 1 | 2;
  title: string;
  description: string;
  config: ExperimentConfig;
  status: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  progress: number;
  created_at: string;
  modified_at: string;
  deleted_at: string;
  logo?: string;
  models: Array<any>;
  preprocessing: { [key: string]: boolean };
  id_recommended_model: string;
  send_notification: boolean;
  task: number;
  visibility: number;
  created_by: string;
}

export interface ExperimentsResponse {
  experiments: Experiment[];
  pages?: number;
}

export enum EXPERIMENT_VISIBILITY {
  PRIVATE = 'private',
  PUBLIC = 'public',
  COMPANY = 'organization',
  COMMUNITY = 'community',
}

export enum EXPERIMENT_VISIBILITY_NUMBER {
  PRIVATE = 0,
  PUBLIC = 1,
  COMPANY = 2,
  COMMUNITY = 3,
}

export const EXPERIMENT_VISIBILITY_LABEL: { [key: number]: string } = {
  0: 'Private',
  1: 'Public',
  2: 'Organization',
  3: 'Community',
};

export enum EXPERIMENT_VISIBILITY_NUM {
  PRIVATE = 0,
  PUBLIC = 1,
  COMPANY = 2,
  COMMUNITY = 3,
}

export interface ExperimentPatch {
  title?: string;
  status?: 4;
  description?: string;
  logo?: string;
  visibility?: number;
}

export interface ExperimentKnowledgeUpdate {
  documents_to_add: string[];
  documents_to_remove: string[];
}

export interface ExperimentsQuery {
  [key: string]: any;
}

export interface IObjectKeys {
  [key: string]: boolean | string | undefined | EntityProps[];
}

export type ExperimentConfig =
  | ImageConfig
  | TextConfig
  | AudioConfig
  | PreTrainedExperimentConfig;

export interface PreTrainedExperimentConfig {
  pre_trained_score_metric: string;
  pre_trained_score_value: number;
  pre_trained_score_metric_desc: string;
  pre_trained_inference_time: number;
  pre_trained_inference_time_desc: string;
  entities?: EntityProps[];
  qa_processor_type?: string;
  llm_model_name?: string;
  llm_openai?: boolean;
  llm_gpt3_5_turbo?: boolean;
  llm_gpt3_5_turbo_16k?: boolean;
  llm_gpt3_5_turbo_1106?: boolean;
  llm_gpt4?: boolean;
  llm_gpt4_turbo?: boolean;
  convert_pdf_to_image?: string;
  ocr_engine?: string;
}

export interface ImageConfig extends IObjectKeys {
  daug_rotation: boolean;
  daug_brightness: boolean;
  daug_zoom: boolean;
  daug_horizontal_flip: boolean;
  daug_vertical_flip: boolean;
  algo_logistic_regression: boolean;
  algo_linear_sgd: boolean;
  algo_ffnn: boolean;
  algo_svc_rbf: boolean;
  cnn_small: boolean;
  cnn_xception: boolean;
  cnn_inceptionv3: boolean;
  cnn_inception_resnetv2: boolean;
  cnn_mobilenetv2: boolean;
  metric_to_optimize:
    | 'f1'
    | 'auto'
    | 'f1'
    | 'accuracy'
    | 'precision'
    | 'recall';
  entities?: EntityProps[];
}

export interface TextConfig extends IObjectKeys {
  qa_processor_type?: string;
  only_ascii: boolean;
  do_lower: boolean;
  do_stopwords_removal: 'auto' | 'true' | 'false';
  do_lemmatization: 'auto' | 'true' | 'false';
  algo_linear_sgd: boolean;
  algo_ffnn: boolean;
  algo_svc_rbf: boolean;
  algo_logistic_regression: boolean;
  vectorization_bow: boolean;
  vectorization_tfidf: boolean;
  vectorization_fasttext: boolean;
  vectorization_distilbert: boolean;
  vectorization_bert: boolean;
  vectorization_xlmroberta: boolean;
  lid_model: boolean;
  metric_to_optimize:
    | 'f1'
    | 'auto'
    | 'f1'
    | 'accuracy'
    | 'precision'
    | 'recall';
  entities?: EntityProps[];
}

export interface AudioConfig extends IObjectKeys {
  audio_type: 'env' | 'music' | 'speech';
  algo_mla: boolean;
  vectorization_l3net: boolean;
  vectorization_vggish: boolean;
  daug_rotation: boolean;
  daug_brightness: boolean;
  daug_zoom: boolean;
  daug_horizontal_flip: boolean;
  daug_vertical_flip: boolean;
  algo_logistic_regression: boolean;
  algo_linear_sgd: boolean;
  algo_ffnn: boolean;
  algo_svc_rbf: boolean;
  cnn_small: boolean;
  cnn_xception: boolean;
  cnn_inceptionv3: boolean;
  cnn_inception_resnetv2: boolean;
  cnn_mobilenetv2: boolean;
  metric_to_optimize:
    | 'f1'
    | 'auto'
    | 'f1'
    | 'accuracy'
    | 'precision'
    | 'recall';
}

export type AdvancedConfig = TextConfig | ImageConfig | AudioConfig;

export const experimentTypeMap: ExperimentLabel = {
  [EXPERIMENT_TYPE_TEXT]: 'text',
  [EXPERIMENT_TYPE_IMAGE]: 'image',
  [EXPERIMENT_TYPE_AUDIO]: 'audio',
};

export type ExtractorEntities = {
  entities: Array<{
    entity: string;
    type: string;
  }>;
};

export type ExpConfig = {
  [key: string]: boolean | string | EntityProps[] | ClassificationCategory[];
};

export interface ExperimentValues {
  title: string;
  description: string;
  train_url?: string;
  validation_url?: string;
  type: 0 | 1 | 2;
  logo?: string;
  preview?: string;
  config: {
    [key: string]:
      | boolean
      | string
      | undefined
      | EntityProps[]
      | AdvancedConfig;
  };
  send_notification?: boolean;
  task?: number;
}

export interface ExperimentTest {
  isApplication?: boolean;
  subtitle?: string | null;
  submitText?: string | null;
  fileInstruction?: string | null;
  application?: Application;
}

export type Payload = {
  [key: string]:
    | string
    | number
    | undefined
    | ExpConfig
    | boolean
    | EntityProps[];
};

export const EXPERIMENT_TASK_LABEL: ExperimentLabel = {
  [EXPERIMENT_TASK.TEXT_CLASSIFICATION]: 'Classification',
  [EXPERIMENT_TASK.AUDIO_CLASSIFICATION]: 'Classification',
  [EXPERIMENT_TASK.AUDIO_SPEECH2TEXT]: 'Transcription',
  [EXPERIMENT_TASK.TEXT_TRANSLATION]: 'Translation',
  [EXPERIMENT_TASK.TEXT_NER_RESPONSE]: 'Named Entity Recognition (NER)',
  [EXPERIMENT_TASK.TEXT_QANDA]: 'Question-Answering',
  [EXPERIMENT_TASK.IMAGE_OCR]: 'OCR',
  [EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR]: 'Smart extractor',
  [EXPERIMENT_TASK.IMAGE_CLASSIFICATION]: 'Classification',
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: 'Object detection',
  [EXPERIMENT_TASK.IMAGE_FACE_SIMILARITY]: 'Image verification',
};

export const EXPERIMENT_TYPE_LABEL: ExperimentLabel = {
  [EXPERIMENT_TYPE.TEXT]: 'Text',
  [EXPERIMENT_TYPE.IMAGE]: 'Image',
  [EXPERIMENT_TYPE.AUDIO]: 'Audio',
};

export const EXPERIMENT_TASK_DOCS_PATH: ExperimentLabel = {
  [EXPERIMENT_TASK.TEXT_CLASSIFICATION]:
    '/predict_text_classification_post_text_classification_predict__id_model__post',
  [EXPERIMENT_TASK.TEXT_QANDA]:
    '/predict_openqa_text_question_answering_ask__id_model__post',
  [EXPERIMENT_TASK.TEXT_TRANSLATION]:
    '/predict_text_translation_post_text_translation_translate__id_model__post',
  [EXPERIMENT_TASK.TEXT_NER_RESPONSE]:
    '/predict_ner_text_information_extraction_extract_entities__id_model__post',
  [EXPERIMENT_TASK.IMAGE_CLASSIFICATION]:
    '/predict_image_post_image_classification_predict__id_model__post',
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]:
    '/object_detection_post_image_object_detection_detect__id_model__post',
  [EXPERIMENT_TASK.IMAGE_FACE_SIMILARITY]:
    '/image_verification_post_image_image_verification_verify__id_model__post',
  [EXPERIMENT_TASK.IMAGE_OCR]: '/ocr_post_image_ocr_recognize__id_model__post',
  [EXPERIMENT_TASK.AUDIO_CLASSIFICATION]:
    '/predict_audio_post_audio_classification_predict__id_model__post',
  [EXPERIMENT_TASK.AUDIO_SPEECH2TEXT]:
    '/transcribe_audio_post_audio_speech2text_transcribe__id_model__post',
};

export const EXPERIMENT_TYPE_SMART_EXTRACTOR_DOCS_PATH: ExperimentLabel = {
  [EXPERIMENT_TYPE.TEXT]:
    '/smart_extraction_from_text_post_text_smart_extraction_extract__id_model__post',
  [EXPERIMENT_TYPE.IMAGE]:
    '/smart_extraction_from_image_post_image_smart_extraction_extract__id_model__post',
};
