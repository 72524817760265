import React, { ReactNode } from 'react';
import useStyle from './errormessage.styles';
import { ErrorIcon } from 'assets';
import { Grid } from '@material-ui/core';

interface Props {
  children: ReactNode;
  type: String;
  icon: Boolean;
}

export const SimpleMessage = ({ children, type, icon }: Props) => {
  const classes = useStyle();
  return (
    <Grid
      className={
        type === 'error'
          ? classes.errorMessageContainer
          : classes.infoMessageContainer
      }
    >
      {icon ? <ErrorIcon width="32px" height="32px" /> : null}
      <span className={classes.message}>{children}</span>
    </Grid>
  );
};
