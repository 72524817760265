import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'material';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    outline: 'none',
    fontSize: '16px',
    fontFamily: 'Poppins',
    border: 'none',
    borderRadius: '5px',
    color: colors.blue70,
  },

  inputBox: {
    gap: '10px',
    paddingLeft: '10px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    borderRadius: '5px',
  },
}));

export default useStyles;
