import React, { useEffect, useState } from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { User } from 'types';
import useStyles from './planinfo.styles';
import { getFormattedDate, twoDecimalsFixed } from 'utils';
import { http } from 'services/http';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

type PlanInfoProps = {
  user: User;
  onClose?: () => void;
};

const periodName: { [key: string]: string } = {
  day: 'daily',
  month: 'monthly',
};

export const PlanInfo = ({ user, onClose = () => {} }: PlanInfoProps) => {
  const classes = useStyles();
  const ref = useOnClickOutside(onClose);
  const [usage, setUsage] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const usageUrl = 'user/usage';

  const comercialPlan = user.commercial_plan;

  useEffect(() => {
    const fetchUsage = async () => {
      setLoading(true);
      try {
        setUsage(await http.get(usageUrl));
      } catch {}
      setLoading(false);
    };
    fetchUsage();
  }, [usageUrl]);

  const getPlanCount = (count: number): string | number =>
    count < 0 ? 'unlimited' : count;

  return (
    <Grid ref={ref} className={classes.planInfoContainer}>
      {loading && (
        <div className={classes.planInfoSpinner}>
          <CircularProgress color="secondary" />
        </div>
      )}

      <Grid className={classes.textContainer}>
        <Typography className={classes.text}>
          Credits ({periodName[comercialPlan?.limit_prediction_period]}):
        </Typography>
        <Typography className={classes.text}>
          {usage ? usage.predictions_in_current_period : '-'}/
          <span className={classes.textTotal}>
            {getPlanCount(comercialPlan?.limit_prediction_count)}
          </span>
        </Typography>
      </Grid>

      <Grid className={classes.textContainer}>
        <Typography className={classes.text}>
          Training hours ({periodName[comercialPlan?.limit_training_period]}):
        </Typography>
        <Typography className={classes.text}>
          <span className={classes.textTotal}>
            {usage
              ? twoDecimalsFixed(usage?.training_seconds_in_period / 3600)
              : '-'}
          </span>
          /{getPlanCount(comercialPlan?.limit_training_hours)}
        </Typography>
      </Grid>

      {user.commercial_plan_expiration && (
        <Grid className={classes.expirationText}>
          <Typography className={classes.text}>
            This plan expires on{' '}
            {getFormattedDate(new Date(user.commercial_plan_expiration), 'PP')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
