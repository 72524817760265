import React, { HTMLAttributes } from 'react';
import { Grid } from '@material-ui/core';

import useStyles from './customchip.styles';

interface CustomChipProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  onClickHandler?(): void;
}

export const CustomChip = ({
  label,
  onClickHandler,
  ...props
}: CustomChipProps) => {
  const classes = useStyles();

  return (
    <Grid onClick={onClickHandler} className={classes.chip} {...props}>
      {label}
    </Grid>
  );
};
