import React, { FC, useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@material-ui/core';
import useStyles from './UserInfo.styles';
import { Avatar } from '../Avatar';
import { Chevron } from '../Icons/Chevron';
import { ApiKeyIcon } from 'assets';
import { CopyApiKey } from 'components/CopyApiKey';
import { User } from 'types';
import { Text } from 'components/Text';
import { useAuthenticationContext } from 'context';

export type UserInfoProps = {
  user: User | null | undefined;
  onLogoutClick?(): void;
};

export const UserInfo: FC<UserInfoProps> = React.memo(
  ({ user, onLogoutClick }) => {
    const classes = useStyles();
    const { userApiKey } = useAuthenticationContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const [showApiKey, setShowApiKey] = useState(false);

    const handleClick = useCallback(
      (e) => {
        setAnchorEl(e.currentTarget);
      },
      [setAnchorEl]
    );

    const showApiKeyHandler = () => {
      setShowApiKey((s) => !s);
    };

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowApiKey(false);
      }, 10000);

      return () => clearTimeout(timer);
    }, [showApiKey]);

    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, [setAnchorEl]);

    const handleLogout = useCallback(() => {
      onLogoutClick?.();
    }, [onLogoutClick]);

    if (!user) {
      return null;
    }

    return (
      <Grid
        className={classes.userInfoContainer}
        item
        container
        justify="space-between"
        alignItems="flex-end"
      >
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleClick}
          disableRipple
        >
          <Text className={classes.userName} variant="paragraph1">
            {/* {user.user_name} */}
          </Text>
          <Avatar text={user.user_name} img={user.avatar} />
          <div className={classes.chevronButton}>
            <Chevron direction={!!anchorEl ? 'up' : 'down'} />
          </div>
        </Button>
        {anchorEl && (
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            classes={{
              paper: classes.menuPaper,
            }}
          >
            <Grid container direction="column">
              <Box
                display="flex"
                alignItems="center"
                style={{ maxWidth: '100%' }}
              >
                <Grid className={classes.avatar}>
                  <Avatar text={user.user_name} img={user.avatar} />
                </Grid>
                <Box flexGrow={1} style={{ overflow: 'hidden' }}>
                  <Typography className={classes.userTitle} variant="body2">
                    {user.user_name}
                  </Typography>
                  <span className={classes.userSubtitle}>{user.email}</span>
                </Box>
                <Box
                  alignSelf="start"
                  onClick={showApiKeyHandler}
                  className={classes.apiKeyButtonTrigger}
                >
                  <ApiKeyIcon />
                </Box>
              </Box>
            </Grid>
            {showApiKey && (
              <Grid className={classes.apiKeyContainer}>
                <Typography className={classes.apiKeyTitle}>API Key</Typography>
                <CopyApiKey apiKeyString={userApiKey} />
              </Grid>
            )}
            <Divider />
            <Box className={classes.btnOptionsContainer}>
              <Typography variant="body2">Need Help ?</Typography>
              <Button
                href="https://docs.cogniflow.ai/en"
                variant="outlined"
                color="secondary"
                target="_blank"
              >
                Visit our documentation
              </Button>
            </Box>
            <Divider />
            <List component="nav" aria-label="secondary mailbox folders">
              <Link
                to="/settings"
                onClick={handleClose}
                style={{ textDecoration: 'none' }}
              >
                <ListItem button dense>
                  <ListItemText primary="Settings" />
                </ListItem>
              </Link>
              <ListItem onClick={handleLogout} button dense>
                <ListItemText primary="Sign Out" />
              </ListItem>
            </List>
          </Popover>
        )}
      </Grid>
    );
  }
);
