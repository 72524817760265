import React, { ReactNode } from 'react';
import { Box, Button } from '@material-ui/core';

import useStyles from './Helper.styles';

type HelperProps = {
  closeHandler: () => void;
  pointerHeight?: string;
  content: ReactNode;
};

export const Helper = ({
  closeHandler,
  pointerHeight,
  content,
}: HelperProps) => {
  const classes = useStyles({
    pointerHeight,
  });

  return (
    <Box className={classes.helper}>
      <Box display="flex" flexDirection="column" gridGap="24px">
        <Box>{content}</Box>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={closeHandler}
        className={classes.button}
      >
        Hide help
      </Button>

      <Box className={classes.pointer}></Box>
    </Box>
  );
};
