import { EXPERIMENT_TASK } from './experiments.types';

export enum PROJECT_TASK {
  CHATBOT = EXPERIMENT_TASK.TEXT_QANDA,
  EXTRACTOR = EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR,
  CLASSIFIER_TEXT = EXPERIMENT_TASK.TEXT_CLASSIFICATION,
  LLM_CLASSIFIER_TEXT = EXPERIMENT_TASK.TEXT_LLM_CLASSIFICATION,
  CLASSIFIER_IMAGE = EXPERIMENT_TASK.IMAGE_CLASSIFICATION,
  LLM_CLASSIFIER_IMAGE = EXPERIMENT_TASK.IMAGE_LLM_CLASSIFICATION,
  CLASSIFIER_AUDIO = EXPERIMENT_TASK.AUDIO_CLASSIFICATION,
  OBJECT_DETECTION = EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION,
  DEFAULT = 999,
}

type ProjectTaskLabelsProps = {
  [key: number]: string;
};

export enum PROJECT_STATUS {
  STATUS_PENDING = 0, //pending
  STATUS_IN_QUEUE = 1, //waiting
  STATUS_RUNNING = 2, //running
  STATUS_FINISHED = 3, //ready
  STATUS_CANCELED_BY_USER = 4, //canceled
  STATUS_CANCELED_BY_TIMEOUT = 5, //timed out
  STATUS_FINISHED_WITH_ERROR = 6, //error
}

export const ProjectTaskLabels: ProjectTaskLabelsProps = {
  [EXPERIMENT_TASK.TEXT_CLASSIFICATION]: 'Text Classification',
  [EXPERIMENT_TASK.TEXT_LLM_CLASSIFICATION]: 'GenAI Classification',
  [EXPERIMENT_TASK.TEXT_TRANSLATION]: 'Text Translation',
  [EXPERIMENT_TASK.TEXT_NER_RESPONSE]: 'Text NER Response',
  [EXPERIMENT_TASK.TEXT_QANDA]: 'ChatBot',
  [EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR]: 'Smart Extractor',
  [EXPERIMENT_TASK.IMAGE_CLASSIFICATION]: 'Image Classification',
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: 'Object Detection',
  [EXPERIMENT_TASK.IMAGE_OCR]: 'Image OCR',
  [EXPERIMENT_TASK.IMAGE_FACE_SIMILARITY]: 'Image Face Similarity',
  [EXPERIMENT_TASK.AUDIO_CLASSIFICATION]: 'Audio Classification',
  [EXPERIMENT_TASK.AUDIO_SPEECH2TEXT]: 'Audio Speech2Text',
  [EXPERIMENT_TASK.AUDIO_CUSTOM_ENTITY_EXTRACTOR]:
    'Audio Custom Entity Extractor',
};

export enum PROJECT_TYPE {
  TEXT = 0,
  IMAGE = 1,
  AUDIO = 2,
}

export enum PROJECTS {
  CHATBOT = 'chatbot',
  EXTRACTOR = 'extractor',
  CLASSIFIER_TEXT = 'classifier_text',
  CLASSIFIER_IMAGE = 'classifier_image',
  CLASSIFIER_AUDIO = 'classifier_audio',
  OBJECT_DETECTION = 'object_detection',
}

export const PROJECT_PROPS = {
  [PROJECT_TASK.CHATBOT]: {
    name: 'ChatBot',
    description: 'ChatBot',
  },
  [PROJECT_TASK.EXTRACTOR]: {
    name: 'Smart Extractor',
    description: 'Smart Extractor',
  },
  [PROJECT_TASK.CLASSIFIER_TEXT]: {
    name: 'Text Classification',
    description: 'Text Classification',
  },
  [PROJECT_TASK.CLASSIFIER_IMAGE]: {
    name: 'Image Classification',
    description: 'Image Classification',
  },
  [PROJECT_TASK.CLASSIFIER_AUDIO]: {
    name: 'Audio Classification',
    description: 'Audio Classification',
  },
  [PROJECT_TASK.OBJECT_DETECTION]: {
    name: 'Object Detection',
    description: 'Object Detection',
  },
  [PROJECT_TASK.DEFAULT]: {
    name: 'Default',
    description: 'Default',
  },
};

export const PROJECT_TYPE_LABELS: Record<number, string> = {
  [PROJECT_TYPE.TEXT]: 'Text',
  [PROJECT_TYPE.IMAGE]: 'Image',
  [PROJECT_TYPE.AUDIO]: 'Audio',
};

export const PROJECT_STATUS_LABELS: Record<number, string> = {
  [PROJECT_STATUS.STATUS_PENDING]: 'Building',
  [PROJECT_STATUS.STATUS_IN_QUEUE]: 'Building',
  [PROJECT_STATUS.STATUS_RUNNING]: 'Building',
  [PROJECT_STATUS.STATUS_FINISHED]: 'Try it',
  [PROJECT_STATUS.STATUS_CANCELED_BY_USER]: 'Cancelled',
  [PROJECT_STATUS.STATUS_CANCELED_BY_TIMEOUT]: 'Cancelled',
  [PROJECT_STATUS.STATUS_FINISHED_WITH_ERROR]: 'Error',
};

export enum PROJECT_VISIBILITY {
  PRIVATE = 0,
  PUBLIC = 1,
  COMPANY = 2,
  COMMUNITY = 3,
}

export enum PROJECT_TASK_URL {
  CHATBOT = 'chatbot',
  EXTRACTOR = 'extractor',
  CLASSIFIER = 'classifier',
  LLM_CLASSIFIER = 'llm-classifier',
  OBJECT_DETECTION = 'object-detection',
}

export enum PROJECT_TYPE_URL {
  TEXT = 'text',
  IMAGE = 'image',
  AUDIO = 'audio',
}

export const PROJECT_TASK_URL_MAP: Record<string, number> = {
  [PROJECT_TASK_URL.CHATBOT]: PROJECT_TASK.CHATBOT,
  [PROJECT_TASK_URL.EXTRACTOR]: PROJECT_TASK.EXTRACTOR,
  [PROJECT_TASK_URL.CLASSIFIER]: PROJECT_TASK.CLASSIFIER_TEXT,
  [PROJECT_TASK_URL.LLM_CLASSIFIER]: PROJECT_TASK.LLM_CLASSIFIER_TEXT,
  [PROJECT_TASK_URL.OBJECT_DETECTION]: PROJECT_TASK.OBJECT_DETECTION,
};

export const PROJECT_TYPE_URL_MAP: Record<string, number> = {
  [PROJECT_TYPE_URL.TEXT]: PROJECT_TYPE.TEXT,
  [PROJECT_TYPE_URL.IMAGE]: PROJECT_TYPE.IMAGE,
  [PROJECT_TYPE_URL.AUDIO]: PROJECT_TYPE.AUDIO,
};
