import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';
import useStyles from './DefaultAlert.styles';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { CheckBoxIconCheck } from 'assets';
import { AlertProps } from 'types';

export const DefaultAlert: FC<AlertProps> = ({
  showing,
  resetAlert,
  message,
}) => {
  const classes = useStyles();

  return showing ? (
    <Alert
      icon={<CheckBoxIconCheck />}
      className={classes.alert}
      severity="success"
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            resetAlert();
          }}
        >
          <CloseIcon fontSize="inherit" color="error" />
        </IconButton>
      }
    >
      {message}
    </Alert>
  ) : null;
};
