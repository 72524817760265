import React, { useEffect, useState } from 'react';
import { Box, Modal } from '@material-ui/core';

import { PlanCard } from 'components/UpgradePlanModal/PlanCard';
import {
  PlanData,
  promotionalPlansInfo,
} from 'components/UpgradePlanModal/plans';

import { useAlertContext, useAuthenticationContext } from 'context';
import { ALERT_TYPES, PlAN_IDS, User } from 'types';

import useStyles from './promotionalPlanModal.styles';
import { getCommercialPlan } from 'services/commercialPlan';
import { activateFreeTrial } from 'services';
import { FetchError } from 'utils';

type PromotionalPlanModalProps = {
  open: boolean;
  onClose(): void;
  planId: string | null;
};

export const PromotionalPlanModal = ({
  planId,
  ...props
}: PromotionalPlanModalProps) => {
  const classes = useStyles();
  const { user } = useAuthenticationContext();
  const [planData, setPlanData] = useState<PlanData | null>(null);
  const { setAlertHandler } = useAlertContext();

  useEffect(() => {
    if (!planId) return;
    getCommercialPlan(planId)
      .then((data: any) => {
        setPlanData(promotionalPlansInfo[planId]({ ...data }));
      })
      .catch();
  }, [planId]);

  const onUpgradeHandler = async (planId: string) => {
    if (planId === PlAN_IDS.FREE_TRIAL) {
      try {
        await activateFreeTrial();
        setAlertHandler({
          showing: true,
          message: `🎉 Your plan has been successfully upgraded.`,
          type: ALERT_TYPES.FULLWIDTH_BAR,
          resetTime: 3000,
          severity: 'success',
        });
        setTimeout(() => window.location.reload(), 2000);
      } catch (error) {
        if (error instanceof FetchError) {
          setAlertHandler({
            showing: true,
            message: error.message,
            type: ALERT_TYPES.FULLWIDTH_BAR,
            resetTime: 3000,
            severity: 'error',
          });
        }
      }
      props.onClose();
    }
  };

  return (
    <>
      {user && planData && (
        <Modal {...props} className={classes.modalWrapper}>
          <Box>
            <PlanCard
              idx={0}
              user={user as User}
              currentPlanId={user ? user.commercial_plan.id : ''}
              onUpgradeHandler={onUpgradeHandler}
              planPeriodIndex={0}
              {...planData}
              className={classes.planCard}
              buttonIsDark
            />
          </Box>
        </Modal>
      )}
    </>
  );
};
