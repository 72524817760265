import React from 'react';
import { GenericSimpleModal } from './GenericSimpleModal';
import { GenericSimpleModalProps, ModalSize } from 'types';

interface Props extends Omit<GenericSimpleModalProps, 'size'> {
  size?: ModalSize;
}

export const ControlledModal = ({
  children,
  size = 'md',
  isOpen = false,
  closeHandler,
}: Props) => (
  <GenericSimpleModal size={size} closeHandler={closeHandler} isOpen={isOpen}>
    {children}
  </GenericSimpleModal>
);
