import React, { ReactNode, useEffect } from 'react';
import { useAlertContext } from 'context';
import { FullwidthBarAlert } from './FullwidthBarAlert';
import { ALERT_TYPES } from 'types';
import { DefaultAlert } from './DefaultAlert';

export const AlertToast = () => {
  const { alert, resetAlert } = useAlertContext();

  useEffect(() => {
    if (alert.showing) {
      setTimeout(() => {
        resetAlert();
      }, alert.resetTime || 2500);
    }
  }, [alert, resetAlert]);

  const alerts: { [key: string]: ReactNode } = {
    [ALERT_TYPES.FULLWIDTH_BAR]: (
      <FullwidthBarAlert {...alert} resetAlert={resetAlert} />
    ),
    [ALERT_TYPES.DEFAULT]: <DefaultAlert {...alert} resetAlert={resetAlert} />,
  };

  return alerts[alert.type || ALERT_TYPES.DEFAULT];
};

// <Snackbar
// open={copied}
// autoHideDuration={6000}
// onClose={closeSnackbarHandler}
// >
// <MuiAlert severity="success">Copied to clipboard!</MuiAlert>
// </Snackbar>
