import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  customRoot: {},
  modalContainer: {
    //width: '840px',
  },
  modalTitle: {
    padding: '0px 24px',
  },
  dialogContent: {
    //padding: '0px 48px 24px 48px',
    overflowY: 'unset',
  },
  dialogContainer: {
    borderRadius: '16px',
  },
  paper: {
    margin: 0,
    width: 840,
    padding: 12,
    borderRadius: 16,
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
    },
    '&::-webkit-scrollbar-thumb:active': {
      background: 'rgba(0, 0, 0, 0.6)',
    },
  },
}));
