import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  tableWrapper: {
    boxShadow: '0px 8px 16px rgba(72, 79, 121, 0.15)',
    border: `1px solid ${colors.blue10}`,
    overflow: 'hidden',
    borderRadius: '8px',
    '& .MuiTableCell-root': {
      border: 'none',
      '& p': {
        fontWeight: 'bold',
        fontSize: '12px',
      },
    },
    '& table tbody': {
      '& tr:nth-child(odd)': {
        backgroundColor: colors.blue5,
      },
    },

    '& table thead tr': {
      borderBottom: `1px solid ${colors.blue15}`,
      '& p': {
        textAlign: 'left',
      },
    },
  },

  headerTitle: {
    fontSize: '15px',
  },

  headerEditEntities: {
    color: colors.orange,
    fontSize: '13px',
  },

  tableContainer: {
    padding: '12px',
  },

  headerOptions: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gridGap: '4px',
  },
}));
