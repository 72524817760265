import { http } from './http';
import { User } from 'types';

const baseUrl = 'user/commercial-plan';

export const upgradePlanRequestService = (
  planId: string,
  paymentMonths: number
): Promise<User> =>
  http.put(
    baseUrl,
    JSON.stringify({
      new_commercial_plan_id: planId,
      payment_months: paymentMonths,
    }),
    {}
  );

export const confirmPayment = (sessionId: string): Promise<User> =>
  http.get(`payment/commercial-plan/confirm/stripe/${sessionId}`);

export const activateFreeTrial = () => http.post('user/activate_free_trial');
