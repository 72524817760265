import Papa, { ParseResult } from 'papaparse';
import Zip from 'jszip';

type CsvDatasetValidationResponse = {
  validFile: boolean;
  logs: { message: string }[];
};

export const validateCsvForDataset = (
  file: File
): Promise<CsvDatasetValidationResponse> =>
  new Promise((resolve) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: ParseResult<any>) {
        const cols = Object.keys(results.data[0]);
        const logs = [];
        let validFile = true;

        const textColExists = cols.some((col) => col === 'Text');
        const labelColExists = cols.some((col) => col === 'Label');

        if (!textColExists || !labelColExists) {
          validFile = false;
          logs.push({
            message:
              'The CSV file to upload must contain the Text and Label columns.',
          });
          logs.push({
            message: `Column names found: ${cols.join(', ')}`,
          });
          logs.push({
            message: `Important: the system is case sensitive ("Text" and "text" are not the same)`,
          });
        }

        resolve({ validFile, logs });
      },
    });
  });

type ChangeColumnNameProps = {
  colList: string[];
  colName: string;
  newColName: string;
};

export const changeColumnName = ({
  colList,
  colName,
  newColName,
}: ChangeColumnNameProps) => {
  const newColNames = [];

  for (const col of colList) {
    if (colName === col.replaceAll(`"`, '')) {
      newColNames.push(`"${newColName}"`);
      continue;
    }
    newColNames.push(col);
  }
  return newColNames;
};

// const zip = useRef(new Zip());
// const onChangeHandler = (e) => {
//   zip.current.loadAsync(e.target.files[0]).then((fi) => {
//     const [filename] = Object.keys(fi.files);

//     fi.files[filename].async("text").then((txt) => {
//       const file = new File([txt], "dataset.csv", {
//         type: "text/csv"
//       });
//       console.log(file);
//     });
//   });

// };

export const splitCsvHeaders = (csvString: string) => {
  const breakline = !csvString.includes('\r\n')
    ? csvString.includes('\r')
      ? '\r'
      : '\n'
    : '\r\n';
  return [
    csvString.slice(0, csvString.indexOf(breakline)),
    csvString.slice(csvString.indexOf(breakline), csvString.length),
  ];
};

const getColNames = (colNamesStr: string) => colNamesStr.split(',');

export const getCsvContentString = async (file: File): Promise<string> => {
  switch (file.type) {
    case 'application/vnd.ms-excel':
    case 'text/csv':
      const csvString = await file.text();
      return csvString;
    case '':
      return '';
    default:
      const zip = new Zip();
      const unZippedFiles = await zip.loadAsync(file);
      const [filename] = Object.keys(unZippedFiles.files);
      const csvStr = await unZippedFiles.files[filename].async('text');

      return csvStr;
  }
};

export const getCsvColNames = (csv: string): string[] =>
  getColNames(splitCsvHeaders(csv)[0]);
