import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(({ colors, breakpoints }) => {
  return {
    userInfoContainer: {
      flexGrow: 1,
      width: 'auto',
    },
    menuPaper: {
      width: 'min(320px, 80%)',
    },
    userName: {
      maxWidth: 180,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      marginRight: 8,
      [breakpoints.down('md')]: {
        display: 'none',
      },
      color: 'white',
    },
    avatar: {
      padding: 14,
    },
    btnOptionsContainer: {
      padding: 14,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    chevronButton: {
      marginLeft: 12,
      width: 24,
      height: 24,
    },
    userTitle: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: colors.blue70,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    userSubtitle: {
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    apiKeyButtonTrigger: {
      margin: '12px 10px 0 0',
      cursor: 'pointer',
    },
    apiKeyContainer: {
      padding: 14,
      paddingTop: 0,
    },
    apiKeyTitle: {
      fontSize: '12px',
      margin: '0 0 4px 0',
    },
  };
});
