import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  containedSecondary: {
    boxShadow: '0 10px 24px 0 rgba(255, 106, 97, 0.4)',
    '&:hover': {
      boxShadow: '0 10px 24px 0 rgba(255, 106, 97, 0.4)',
    },
  },
}));
