import React from 'react';
import { Grid, LinearProgress, Typography } from '@material-ui/core';

import useStyles from './percentagedata.styles';

type PercentageDataProps = {
  amount: number;
  percentage: number;
};

export const PercentageData = ({ amount, percentage }: PercentageDataProps) => {
  const classes = useStyles({
    progressColor: 'black',
  });

  return (
    <Grid className={classes.percentageWrapper}>
      <Typography className={classes.percentageNumber}>
        {percentage}%
      </Typography>
      <Grid className={classes.percentage}>
        <LinearProgress
          value={percentage as number}
          variant="determinate"
          className={classes.progress}
        ></LinearProgress>
      </Grid>
    </Grid>
  );
};
