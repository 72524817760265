import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { IconClose } from 'assets';
import useStyles from './genericmodal.style';
interface Props {
  //openSate: boolean;
  //handleClose: Function;
  children: React.ReactNode;
  size?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl' | undefined;
}

export const GenericModalState = atom<boolean>({
  key: 'generic-modal-state',
  default: false,
});

export const GenericModal = ({ children, size = 'md' }: Props) => {
  const classes = useStyles();
  const [openState, setToggle] = useRecoilState(GenericModalState);
  const handleClose = useCallback(() => setToggle(false), [setToggle]);

  return (
    <div>
      <Dialog
        onClose={handleClose}
        maxWidth={size}
        aria-labelledby="customized-dialog-title"
        open={openState}
        classes={{
          paper: classes.paper,
        }}
      >
        <Grid
          container
          item
          direction="column"
          className={classes.modalContainer}
        >
          <DialogTitle className={classes.modalTitle}>
            <Grid container item sm={12} md={12} justify="flex-end">
              {openState ? (
                <IconButton aria-label="close" onClick={handleClose}>
                  <IconClose />
                </IconButton>
              ) : null}
            </Grid>
          </DialogTitle>
          <DialogContent
            classes={{
              root: classes.customRoot,
            }}
            className={classes.dialogContent}
          >
            {children}
          </DialogContent>
        </Grid>
      </Dialog>
    </div>
  );
};

export default GenericModal;
