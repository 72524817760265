import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useState,
} from 'react';
import { PredictResponse } from 'types';
import { predictText } from 'services/predict';

type ImageRequestBody = {
  format?: string;
  include_output_image?: 'yes' | 'no' | 'default';
  convert_pdf_to_image?: 'yes' | 'no' | 'default';
  base64_image?: '';
};

type PredictionContextProps = {
  imageRequestBody?: ImageRequestBody;
  setImageRequestBodyHandler: (values: ImageRequestBody) => void;
  textResponse?: PredictResponse;
  setTextResponse: (response: PredictResponse) => void;
  loading?: boolean;
  setLoadingHandler: (value: boolean) => void;
  textArea: string;
  setTextAreaHandler: (value: string) => void;
  dropFileFails: string;
  imgFormat: string;
  setDropFileFailsHandler: (value: string) => void;
  setImgFormatHandler: (value: string) => void;
  setImageHandler: (value: string) => void;
  image: string;
  imagePrediction: PredictResponse | null;
  setImagePredictionHandler: (response: PredictResponse | null) => void;
  audioPredResp: PredictResponse | null;
  setAudioPredRespHandler: (response: PredictResponse | null) => void;
  showAudioResult: boolean;
  setShowAudioResultHandler: (value: boolean) => void;
  audioRecorded: any;
  setAudioRecordedHandler: (value: any) => void;
  callPredictText: ({
    modelId,
    payload,
    task,
    headers,
  }: {
    modelId: string;
    payload: any;
    task: string;
    headers: any;
  }) => Promise<PredictResponse>;

  FSImages: {
    img1: string;
    img2: string;
  };
  FSImage1Upload: (img: string) => void;
  FSImage2Upload: (img: string) => void;
};

const initialImageRequestBody: ImageRequestBody = {
  format: 'jpg',
  include_output_image: 'default',
  convert_pdf_to_image: 'default',
  base64_image: '',
};

const PredictionContext = createContext<PredictionContextProps>({
  imageRequestBody: initialImageRequestBody,
  setImageRequestBodyHandler: () => {},
  textResponse: undefined,
  setTextResponse: () => {},
  loading: false,
  setLoadingHandler: () => {},
  textArea: '',
  setTextAreaHandler: () => {},
  dropFileFails: '',
  imgFormat: '',
  setDropFileFailsHandler: () => {},
  setImgFormatHandler: () => {},
  setImageHandler: () => {},
  image: '',
  imagePrediction: null,
  setImagePredictionHandler: () => {},
  audioPredResp: null,
  setAudioPredRespHandler: () => {},
  showAudioResult: false,
  setShowAudioResultHandler: () => {},
  audioRecorded: null,
  setAudioRecordedHandler: () => {},
  callPredictText: () => Promise.resolve({} as PredictResponse),
  FSImages: {
    img1: '',
    img2: '',
  },

  FSImage1Upload: () => {},
  FSImage2Upload: () => {},
});

export const usePredictionContext = () => useContext(PredictionContext);

export const PredictionProvider: FC<{}> = ({ children }) => {
  const [imageRequestBody, setImageRequestBody] = useState<ImageRequestBody>(
    initialImageRequestBody
  );

  const [image, setImage] = useState('');
  const [dropFileFails, setDropFileFails] = useState('');
  const [imgFormat, setImgFormat] = useState('jpg');
  const [
    imagePrediction,
    setImagePrediction,
  ] = useState<PredictResponse | null>(null);

  const [textPrediction, setTextPrediction] = useState<PredictResponse>();
  const [textArea, setTextArea] = useState('');

  const [audioPredResp, setAudioPredResp] = useState<PredictResponse | null>(
    null
  );
  const [showAudioResult, setShowAudioResult] = useState(false);
  const [audioRecorded, setAudioRecorded] = useState<any>(null);

  const [loading, setLoading] = useState(false);

  const [FSImages, setFSImages] = useState({
    img1: '',
    img2: '',
  });

  const setLoadingHandler = useCallback((value: boolean) => {
    setLoading(value);
  }, []);

  // Audio prediction handlers
  const setAudioPredRespHandler = useCallback(
    (response: PredictResponse | null) => {
      setAudioPredResp(response);
    },
    []
  );

  const setAudioRecordedHandler = useCallback((value: any) => {
    setAudioRecorded(value);
  }, []);

  const setShowAudioResultHandler = useCallback((value: boolean) => {
    setShowAudioResult(value);
  }, []);

  // Image prediction handlers

  const setImagePredictionHandler = useCallback(
    (response: PredictResponse | null) => {
      setImagePrediction(response);
    },
    []
  );

  const setImageRequestBodyHandler = useCallback((values: ImageRequestBody) => {
    setImageRequestBody((s) => ({ ...s, ...values }));
  }, []);

  const setDropFileFailsHandler = useCallback((value: string) => {
    setDropFileFails(value);
  }, []);
  const setImgFormatHandler = useCallback((value: string) => {
    setImgFormat(value);
  }, []);

  const setImageHandler = useCallback((value: string) => {
    setImage(value);
  }, []);

  const FSImage1Upload = useCallback((img: string) => {
    setFSImages((s) => ({ ...s, img1: img }));
  }, []);

  const FSImage2Upload = useCallback((img: string) => {
    setFSImages((s) => ({ ...s, img2: img }));
  }, []);

  // Text prediction handlers

  const setTextResponse = useCallback((response: PredictResponse) => {
    setTextPrediction(response);
  }, []);

  const setTextAreaHandler = useCallback((value: string) => {
    setTextArea(value);
  }, []);

  const callPredictText = useCallback(
    async ({ modelId, payload, task, headers }) => {
      const response = await predictText(modelId, payload, task, headers);
      setTextResponse(response);
      setLoading(false);

      return response;
    },
    [setTextResponse]
  );

  return (
    <PredictionContext.Provider
      value={{
        imageRequestBody,
        setImageRequestBodyHandler,
        textResponse: textPrediction,
        setTextResponse,
        loading,
        setLoadingHandler,
        textArea,
        callPredictText,
        setTextAreaHandler,
        dropFileFails,
        imgFormat,
        setDropFileFailsHandler,
        setImgFormatHandler,
        setImageHandler,
        image,
        imagePrediction,
        setImagePredictionHandler,
        audioPredResp,
        setAudioPredRespHandler,
        showAudioResult,
        setShowAudioResultHandler,
        audioRecorded,
        setAudioRecordedHandler,
        FSImages,
        FSImage1Upload,
        FSImage2Upload,
      }}
    >
      {children}
    </PredictionContext.Provider>
  );
};
