import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';
import { colors } from 'material/colors';

interface StyleProps {
  tagColor: string;
  color?: string;
}

export default makeStyles(({ tagColors }: ICogniflowTheme) => ({
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },

  chipTagWrapper: ({ tagColor, color }: StyleProps) => ({
    marginRight: '3px',
    borderRadius: '5px',
    padding: '0 5px',
    backgroundColor:
      color ||
      (tagColors[tagColor] ? tagColors[tagColor].light : 'transparent'),
    marginBottom: '3px',
  }),
  chipText: ({ tagColor }: StyleProps) => ({
    marginRight: tagColors[tagColor] && '5px',
    textAlign: 'left',
    color: tagColors[tagColor] && tagColors[tagColor].dark,
  }),
  chip: ({ tagColor }: StyleProps) => ({
    fontSize: '10px',
    padding: '0 4px',
    borderRadius: '5px',
    color: tagColors[tagColor] && 'white',
    backgroundColor: tagColors[tagColor] && tagColors[tagColor].main,
  }),
}));
