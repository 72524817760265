import { Overrides } from '@material-ui/core/styles/overrides';
import { Button } from './Button';
import { Chip } from './Chip';
import { Select } from './Select';
import { OutlinedInput, InputLabel } from './Input';
import { CardHeader } from './Card';
import { StepLabel, StepIcon, Step } from './Stepper';
import { Tooltip } from './Tooltip';

export const overrides: Overrides = {
  MuiButton: Button,
  MuiChip: Chip,
  MuiSelect: Select,
  MuiOutlinedInput: OutlinedInput,
  MuiInputLabel: InputLabel,
  MuiCardHeader: CardHeader,
  MuiStepLabel: StepLabel,
  MuiStepIcon: StepIcon,
  MuiStep: Step,
  MuiTooltip: Tooltip,
};
