import { useEffect, useState } from 'react';
import { useCentrifuge } from 'context';
import { Message } from 'types';

export const useSubscription = <T>(channel: string): Nullable<Message<T>> => {
  const { client } = useCentrifuge();
  const [data, setData] = useState<Message<T>>();

  useEffect(() => {
    if (!client) return;

    const subscription = client.subscribe(channel, (data: Message<T>) =>
      setData(data)
    );

    return () => subscription.unsubscribe();
  }, [channel, client]);

  return data;
};
