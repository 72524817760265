interface handleFetchProps {
  url: string;
  file: File;
  fields: { [key: string]: string };
  onProgress(e: ProgressEvent): void;
  onUploadComplete(e: ProgressEvent): void;
  onFailed(e: ProgressEvent): void;
}

export const useUpload = () => {
  const handleFetch = ({
    url,
    file,
    fields,
    onProgress,
    onUploadComplete,
    onFailed,
  }: handleFetchProps) => {
    const ajax = new XMLHttpRequest();
    ajax.upload.onprogress = (e) => {
      onProgress(e);
    };
    ajax.upload.onerror = (e) => {
      onFailed(e);
    };
    ajax.upload.onabort = (e) => {
      onFailed(e);
    };
    ajax.onreadystatechange = () => {
      if (ajax.readyState === XMLHttpRequest.DONE) {
        if (ajax.status >= 200 && ajax.status < 300) {
          onUploadComplete(ajax.response);
        }
      }
    };

    ajax.open('POST', url);
    const data = new FormData();
    Object.keys(fields).forEach((key) => {
      data.set(key, fields[key]);
    });
    data.set('file', file);
    data.set('success_action_status', '201');
    ajax.send(data);
  };

  return { handleFetch };
};
