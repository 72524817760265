import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import useStyles from './plancard.styles';
import { CheckIcon, IcoCheck, MessageIcon } from 'assets';
import { PlAN_IDS, planIndex } from 'types';

import { User } from 'types';

type PlanCardProps = {
  id: string;
  user: User;
  planName: string;
  description: string;
  price: number;
  discountPercentage?: number;
  period: string;
  actionButtonText: string;
  properties: string[];
  isDark?: boolean;
  buttonIsDark?: boolean;
  hasActionButton?: boolean;
  onUpgradeHandler(planId: string, planPeriodIndex: number): void;
  currentPlanIdIndex?: number;
  idx: number;
  currentPlanId: string;
  planPeriodIndex: number;
  additionalPredictionText: string[];
  link?: string;
  className?: string;
};

export const PlanCard = ({
  id,
  planName,
  description,
  price,
  discountPercentage,
  user,
  period,
  actionButtonText,
  hasActionButton,
  properties,
  isDark,
  buttonIsDark,
  onUpgradeHandler,
  currentPlanId,
  idx,
  planPeriodIndex,
  additionalPredictionText,
  link,
  className,
}: PlanCardProps) => {
  const classes = useStyles({
    isDark: isDark || false,
  });

  const isEnterprisePlan = useMemo(() => id === PlAN_IDS.BUSINESS, [id]);

  const onClickActionHandler = (link?: string) => {
    if (isEnterprisePlan) {
      window.location.href = link
        ? link
        : `mailto:info@cogniflow.ai?subject=Enterprise plan upgrade&body=Hi, my name is #NAME#.%0D%0A%0D%0AI'm looking to know more about your custom plans.%0D%0A%0D%0A#Optional you can give us more information about your needs#%0D%0A%0D%0AThank you!`;
      return;
    }
    onUpgradeHandler(id, planPeriodIndex);
  };

  const showAction = useMemo(() => {
    if (!user) return false;

    return (
      user.commercial_plan.monthly_cost <= 0 || planIndex[currentPlanId] < idx
    );
  }, [user, idx, currentPlanId]);

  return (
    <Grid className={classnames(classes.card, className)}>
      {discountPercentage && (
        <Box className={classes.discount}>{`-${
          discountPercentage * 100
        }%`}</Box>
      )}

      <Grid>
        <Grid className={classes.headerDescriptionContainer}>
          <Typography className={classes.planNameText}>{planName}</Typography>
          <Typography className={classes.descriptionText}>
            {description}
          </Typography>
        </Grid>

        <Grid className={classes.priceInfo}>
          {!isEnterprisePlan ? (
            <>
              {!discountPercentage ? (
                <Typography
                  variant="h2"
                  className={classes.price}
                  align="center"
                >
                  <span className={classes.priceSym}>$</span>
                  {price}
                </Typography>
              ) : (
                <Box display="flex" alignItems="center" style={{ gap: 16 }}>
                  <Typography
                    variant="h2"
                    className={classnames(classes.price, classes.oldPrice)}
                    align="center"
                  >
                    <span className={classes.priceSym}>$</span>
                    {price}
                  </Typography>
                  <Typography
                    variant="h2"
                    className={classes.price}
                    align="center"
                  >
                    <span className={classes.priceSym}>$</span>
                    {Math.ceil(price * (1 - discountPercentage))}
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <MessageIcon width="50px" height="38px" />
          )}
          <Typography className={classes.descriptionText} align="center">
            {period}
          </Typography>
        </Grid>

        {hasActionButton && showAction && (
          <Button
            fullWidth
            variant={isDark || buttonIsDark ? 'contained' : 'outlined'}
            color="secondary"
            className={classes.upgradeButton}
            onClick={() => {
              onClickActionHandler(link);
            }}
          >
            {actionButtonText}
          </Button>
        )}

        <Grid>
          {properties.map((prop, idx) => (
            <Grid key={idx} className={classes.propertyContainer}>
              {isDark ? <CheckIcon /> : <IcoCheck />}
              <Typography
                className={classes.property}
                dangerouslySetInnerHTML={{ __html: prop }}
              ></Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid style={{ marginTop: '12px' }}>
        {additionalPredictionText.map((text, idx) => (
          <Typography key={idx} className={classes.additionalPrediction}>
            {text === 'additional hour at $2' && (
              <span style={{ marginLeft: '25px' }}></span>
            )}
            {text}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};
