import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './MainLayout.styles';
import { Navbar } from 'components';

export interface MainLayoutProps {}

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Navbar />
      <Grid className={classes.container} container direction="column">
        {children}
      </Grid>
    </>
  );
};
