import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  modalWrapper: {
    display: 'flex',
    justifyContent: 'center',
    outline: 'none',
    alignItems: 'center',
    '&:active': {
      border: 'none',
    },
    overflow: 'hidden',
  },

  appsumoPlanTitle: {
    marginBottom: '24px',
  },
  modalContainer: {
    borderRadius: '10px',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
    height: '80px',
    backgroundColor: colors.blue10,
    position: 'relative',
    '& div': {
      flex: 1,
    },
  },

  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 32px',
    alignItems: 'start',
  },
  title: {
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    gap: '12px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10px 10px 0 10px',
    marginBottom: '12px',
  },
  switchContainer: {
    margin: '0',
  },
  planCards: {
    display: 'flex',
    justifyContent: 'center',
    gap: '18px',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  containerUnderlinedSubtitle: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    textAlign: 'center',
    '& span': {
      fontStyle: 'underline',
      letterSpacing: 'normal',
      color: colors.orange,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
}));
