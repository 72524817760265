import React from 'react';

import { Box, TableCell, TableRow } from '@material-ui/core';
import { Text, Table } from 'components';
import { DeleteIcon, PencilOutlined } from 'assets';
import classnames from 'classnames';

import useStyles from './EntityTable.styles';
import { EntityForm } from '../EntityForm';

type EntityTableProps = {
  entities: any[];
  editEntityHandler: (entry: any) => void;
  deleteEntity(id: string): void;
  showActions?: boolean;
};

export const EntityTable = ({
  entities,
  editEntityHandler,
  deleteEntity,
  showActions,
}: EntityTableProps) => {
  const classes = useStyles();
  return (
    <>
      <Table
        className={classes.tableWrapper}
        cols={[
          'ENTITY NAME',
          'DESCRIPTION',
          'OUTPUT FORMAT',
          showActions && 'ACTIONS',
        ].map((col, idx) => (
          <Text
            key={idx}
            variant="paragraph1"
            className={classes.tableCol}
            align="left"
          >
            {col}
          </Text>
        ))}
        rows={entities.map((props) => (
          <TableRow key={props.id}>
            <TableCell align="left">
              <Text variant="paragraph1">
                {/* <span
                className={classes.entryTextBg}
                style={{
                  backgroundColor: props.color,
                  color: isDark(props.color) ? 'white' : 'black',
                  fontWeight: 'bold',
                }}
              >
                {props.name}
              </span> */}
                {props.name}
              </Text>
            </TableCell>
            <TableCell align="left">
              <Text variant="paragraph1" align="justify">
                {props.description}
              </Text>
            </TableCell>

            <TableCell align="left">
              <Text variant="paragraph1" align="justify">
                {props.output_format}
              </Text>
            </TableCell>

            {showActions && (
              <TableCell align="left">
                <Box display="flex" alignItems="flex-end" gridGap="10px">
                  <EntityForm
                    editMode
                    openModalElement={
                      <PencilOutlined
                        className={classnames(classes.icon, classes.editIcon)}
                      />
                    }
                    editEntryHandler={editEntityHandler}
                    {...props}
                  />
                  <DeleteIcon
                    className={classes.icon}
                    title="Delete entry"
                    onClick={() => {
                      deleteEntity(props.id);
                    }}
                  />
                </Box>
              </TableCell>
            )}
          </TableRow>
        ))}
      />
      {entities.length === 0 && (
        <Text variant="paragraph1" align="center" style={{ marginTop: '12px' }}>
          No entities added
        </Text>
      )}
    </>
  );
};
