import { makeStyles } from '@material-ui/core';
import { PROJECT_TASK } from 'types';

const classifierColors = {
  color: '#4195F7',
  background: '#F0F7FF',
};

// const selectedTagColors: { [key: number]: string } = {
//   [PROJECT_TASK.CHATBOT]: '#F7F0FF',
//   [PROJECT_TASK.EXTRACTOR]: '#F0F9FF',
//   [PROJECT_TASK.CLASSIFIER_TEXT]: '#F0FFF0',
//   [PROJECT_TASK.OBJECT_DETECTION]: '#FFF0F0',
// };

const tagColors: Record<number, any> = {
  [PROJECT_TASK.CHATBOT]: {
    color: '#995BFF',
    background: '#F8F4FF',
  },
  [PROJECT_TASK.CLASSIFIER_TEXT]: classifierColors,
  [PROJECT_TASK.CLASSIFIER_IMAGE]: classifierColors,
  [PROJECT_TASK.CLASSIFIER_AUDIO]: classifierColors,
  [PROJECT_TASK.EXTRACTOR]: {
    color: '#FF65A3',
    background: '#FFF6FA',
  },
  [PROJECT_TASK.OBJECT_DETECTION]: {
    color: '#4CAE96',
    background: '#F1FEFB',
  },
};

type StyleProps = {
  projectTask: number;
  isSelected?: boolean;
};

export default makeStyles(() => ({
  tag: ({ projectTask, isSelected }: StyleProps) => {
    const selectedColor = tagColors[projectTask].color;

    const backgroundColor = isSelected
      ? selectedColor
      : tagColors[projectTask]?.background || '';

    const fontColor = isSelected ? '#fff' : tagColors[projectTask]?.color || '';

    return {
      cursor: 'pointer',
      height: '32px',
      border: `1px solid ${tagColors[projectTask]?.color || ''}`,
      borderRadius: '20px',
      padding: '4px 12px',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      backgroundColor,
      '& p': {
        padding: '0',
        margin: '0',
        color: fontColor,
      },
    };
  },
}));
