import { Overrides } from '@material-ui/core/styles/overrides';
import { colors } from '../colors';

export const Tooltip: Overrides['MuiTooltip'] = {
  tooltip: {
    fontSize: 14,
    backgroundColor: colors.blue50,
    fontFamily: 'PTserif',
    fontWeight: 'normal',
    padding: 8,
    fontStretch: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    textAlign: 'center',
  },
};
