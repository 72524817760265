import { useRef } from 'react';

export const useConstructor = (cb: () => void) => {
  const hasRun = useRef(false);

  if (!hasRun.current) {
    cb();
    hasRun.current = true;
  }
};
