import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';

interface TextTipsProps {
  task: number;
  tips: { [key: number]: ReactNode[] };
}

export const TextTips = ({ task, tips }: TextTipsProps) => {
  return (
    <>
      {(tips[task] || []).map((tip, idx) => (
        <li
          key={idx}
          style={{
            marginTop: '4px',
          }}
        >
          <Typography variant="body2">{tip}</Typography>
        </li>
      ))}
    </>
  );
};
