import { Experiment } from 'types';
import { getExperiment } from './experiments';

const LAST_VISITED_KEY = 'last-visited-projects';
const MAX_VISITED_PROJECTS = 4;

export const addToRecents = (project: Experiment | undefined) => {
  if (!project) {
    return;
  }

  const projects = JSON.parse(localStorage.getItem(LAST_VISITED_KEY) || '[]');

  const projectIdx = projects.findIndex(
    (current: Experiment) => current.id === project.id
  );

  if (projectIdx !== -1) {
    projects.splice(projectIdx, 1);
  }

  if (projects.length === MAX_VISITED_PROJECTS && projectIdx === -1) {
    projects.pop();
  }

  projects.unshift(project);
  localStorage.setItem(LAST_VISITED_KEY, JSON.stringify(projects));
};

export const deleteLastVisitedProject = (id_delete: string) => {
  const lastVisitedProjects = JSON.parse(
    localStorage.getItem(LAST_VISITED_KEY) || '[]'
  );
  const updateLastVisited = lastVisitedProjects.filter(
    ({ id }: Experiment) => id !== id_delete
  );

  localStorage.setItem(LAST_VISITED_KEY, JSON.stringify(updateLastVisited));
};

export const getLastVisitedProjects = async () => {
  const projects = JSON.parse(localStorage.getItem(LAST_VISITED_KEY) || '[]');

  return Promise.allSettled(
    projects.map(({ id }: Experiment) => getExperiment(id))
  ).then((res) => {
    res.forEach((project, index) => {
      if (project.status === 'rejected') {
        deleteLastVisitedProject(projects[index].id);
      }
    });
    return JSON.parse(localStorage.getItem(LAST_VISITED_KEY) || '[]');
  });
};
