import React, { useState, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import useStyles from './ProjectCard.styles';
import { Text } from 'components/Text';
import { assets } from '../cardAssets';
import {
  Experiment,
  PROJECT_STATUS,
  PROJECT_STATUS_LABELS,
  PROJECT_TASK,
  ProjectTaskLabels,
} from 'types';
import { buildPublicUrl, getEnv, getExperiment } from 'services';

import { useExperimentProgress } from 'hooks';

import imgPlaceholder from 'assets/img-placeholder.svg';
import { DotsIcon } from 'assets';
import { ControlledModal } from 'components/GenericSimpleModal';
import { ExperimentSettings } from 'pages/ViewExperiment/ViewExperimentHeader/ExperimentSettings';
import { useAuthenticationContext } from 'context';
import { useHistory } from 'react-router-dom';

const REACT_APP_BUCKET_NAME = getEnv('REACT_APP_BUCKET_NAME');

export interface ProjectCardProps extends Experiment {
  title: string;
  projectTask: number;
  showCreatedBy?: boolean;
}

export const ProjectCard = ({
  projectTask,
  title,
  showCreatedBy = true,
  ...props
}: ProjectCardProps) => {
  const progMessage = useExperimentProgress(props.id);
  const [experimentStatus, setExperimentStatus] = useState(props.status);

  useEffect(() => {
    if (experimentStatus > 2) return;
    let intervalId: NodeJS.Timeout;

    const fetchStatus = () => {
      getExperiment(props.id)
        .then((data) => {
          setExperimentStatus(data.status);

          // Check if the condition is met to cancel the interval
          if (data.status > 2) {
            clearInterval(intervalId);
          }
        })
        .catch(() => {
          clearInterval(intervalId);
        })
        .catch(() => {});
    };

    intervalId = setInterval(fetchStatus, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [props.id]);

  const [modalOpen, setModalOpen] = useState(false);

  const { push } = useHistory();

  const classes = useStyles({
    projectTask,
    progress: progMessage?.data.experiment_progress || props.progress,
  });

  const { user } = useAuthenticationContext();

  const bgImg = assets[projectTask]?.bg || assets[PROJECT_TASK.DEFAULT].bg;
  const ellipseImg =
    assets[projectTask]?.ellipse || assets[PROJECT_TASK.DEFAULT].ellipse;

  const logoImg = props.logo
    ? buildPublicUrl(props.logo, REACT_APP_BUCKET_NAME || 'static')
    : imgPlaceholder;

  const goToProjectPage = () => {
    push(`/experiment/${props.id}`);
  };

  const goToTestPage = () => {
    getExperiment(props.id).then((exp) => {
      if (exp.models?.[0]) {
        const model = exp.models?.[0];
        const redirectionTab =
          exp?.task === PROJECT_TASK.CHATBOT ? 'chat' : 'test';
        push(`/experiment/${props.id}/model/${model.id}/${redirectionTab}`);
      }
    });
  };

  const openModalHandler = (e: any) => {
    e.stopPropagation();
    setModalOpen(true);
  };

  const closeModalHandler = () => {
    setModalOpen(false);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.card} onClick={goToProjectPage}>
        <Box className={classes.header}>
          <Box className={classes.image}>
            <img src={bgImg} alt="card background" />
            <Box className={classes.imgBg}></Box>
          </Box>
          <Box className={classes.background}></Box>
          <Box className={classes.ellipse}>
            <img src={ellipseImg} alt="card ellipse" />
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            position="relative"
            zIndex={2}
          >
            <Box className={classes.avatar}>
              <img src={logoImg} alt="card avatar" />
            </Box>

            {props?.id_user === user?.id && (
              <Box className={classes.dots}>
                <DotsIcon onClick={openModalHandler} />
              </Box>
            )}
          </Box>

          <Box mt="25px" position="relative" zIndex={3}>
            <Text variant="paragraph1" className={classes.task}>
              {ProjectTaskLabels[projectTask]}
            </Text>
            <Text variant="h3" className={classes.title} title={title}>
              {title}
            </Text>
          </Box>
        </Box>

        <Box className={classes.btnContainer}>
          <Button
            size="large"
            fullWidth
            variant="outlined"
            color="secondary"
            className={classes.tryItButton}
            onClick={goToTestPage}
            disabled={experimentStatus !== PROJECT_STATUS.STATUS_FINISHED}
          >
            {experimentStatus < PROJECT_STATUS.STATUS_FINISHED && (
              <Box className={classes.tryItButtonProgress}></Box>
            )}

            {PROJECT_STATUS_LABELS[experimentStatus]}
          </Button>
        </Box>
      </Box>

      {/* {false && ( */}

      {showCreatedBy && (
        <Box display="flex" gridGap="12px" mt="12px">
          <Box>{/* <Box className={classes.personImage}></Box> */}</Box>
          <Box>
            <Text
              variant="paragraph1"
              align="left"
              className={classes.createdBy}
            >
              Created by
            </Text>
            <Text variant="paragraph1" align="left" className={classes.owner}>
              {props.created_by}
              {/* Kevin Espinosa */}
            </Text>
          </Box>
        </Box>
      )}
      {/* )} */}
      <ControlledModal
        isOpen={modalOpen}
        closeHandler={closeModalHandler}
        size="sm"
      >
        <ExperimentSettings
          experiment={{ ...props, title }}
          closeSettingsModal={closeModalHandler}
        />
      </ControlledModal>
    </Box>
  );
};
