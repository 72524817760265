import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Application } from 'types';

interface ApplicationContextProps {
  application?: Application;
  setApplicationState(prop: Application | null): void;
}

const ApplicationContext = createContext<Partial<ApplicationContextProps>>({});

export const useApplicationContext = () => useContext(ApplicationContext);

export const ApplicationContextProvider: FC<{}> = ({ children }) => {
  const [application, setApplication] = useState<Application>();

  const setApplicationState = useCallback((data: Application) => {
    setApplication(data);
  }, []);

  return (
    <ApplicationContext.Provider value={{ application, setApplicationState }}>
      {children}
    </ApplicationContext.Provider>
  );
};
