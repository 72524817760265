import React, { ReactNode } from 'react';

import useStyle from './loginwrapper.styles';

interface Props {
  children: ReactNode;
}

export const LoginWrapper = ({ children }: Props) => {
  const classes = useStyle();
  return <div className={classes.loginContainer}>{children}</div>;
};
