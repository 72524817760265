import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  paper: {
    margin: 0,
    width: 448,
    padding: 48,
    boxSizing: 'border-box',
    borderRadius: 16,
  },
  confirmButton: {
    marginBottom: 16,
  },
  title: {
    marginTop: 24,
    marginBottom: 16,
  },
  subtitle: {
    marginBottom: 24,
  },
  close: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
}));
