import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  loginContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    width: '100vw',
    margin: '0 auto',
    justifyContent: 'center',
    alignItems: 'center',
    // background: `url(${LoginBackground}) no-repeat center center fixed`,
    backgroundSize: 'cover',
    height: '100vh',
    backgroundColor: 'white',
  },
}));
