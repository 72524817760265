import { twoDecimalsFixed } from './numbers';
import {
  RectDimensionScaledProps,
  DrawLabelInBoxProps,
  DrawBoxesResponseProps,
  DrawTextBGProps,
  DrawRectProps,
} from 'types';

export const calcRectDimensionScaled = ({
  n1,
  n2,
  scale,
  imgDimension,
}: RectDimensionScaledProps) => (n2 - n1) * scale * imgDimension;

export const drawInCanvas = (
  canvas: HTMLCanvasElement,
  image: HTMLImageElement
) => {
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  const scale = Math.min(
    canvas.width / image.width,
    canvas.height / image.height
  );
  const x = canvas.width / 2 - (image.width / 2) * scale;
  const y = canvas.height / 2 - (image.height / 2) * scale;

  ctx.fillStyle = 'transparent';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(image, x, y, image.width * scale, image.height * scale);
};

export const drawImageScaled = (
  canvas: HTMLCanvasElement,
  img: string | HTMLImageElement
): Promise<HTMLImageElement> =>
  new Promise((resolve) => {
    if (img instanceof HTMLImageElement) {
      drawInCanvas(canvas, img);
      return resolve(img);
    }
    const image = new Image();
    image.onload = function () {
      if (!canvas) return;

      drawInCanvas(canvas, image);
      resolve(image);
    };
    image.src = img as string;
  });

export const drawLabelInBox = ({
  ctx,
  text,
  x,
  y,
  scale = 1,
  color,
}: DrawLabelInBoxProps) => {
  const fontSize = scale * 16;
  const font = `${fontSize}px Arial`;

  drawTextBG({
    ctx,
    text,
    font,
    x,
    y: y - parseInt(font, 10) - 2,
    padding: 10,
    color: 'black',
    backgroundColor: color,
  });
};

export const drawBoxesResponse = ({
  canvas,
  image,
  boxes,
  drawLabels = false,
  boxesToShow,
  cb,
  opacity,
  fillRects,
  drawScore,
}: DrawBoxesResponseProps) => {
  if (!canvas) return;

  const scale = Math.min(
    canvas.width / image.width,
    canvas.height / image.height
  );
  const ctx = canvas.getContext('2d');

  // sX and sY represents the origin of the image inside the canvas (0, 0)
  const sX = canvas.width / 2 - (image.width / 2) * scale;
  const sY = canvas.height / 2 - (image.height / 2) * scale;

  let allBoxes = boxes;

  if (boxesToShow) {
    allBoxes = boxes.filter((box: any) => boxesToShow?.includes(box.category));
  }

  for (const { x1, y1, x2, y2, category, confidence, color } of allBoxes) {
    const w = (x2 - x1) * scale * image.width;
    const h = (y2 - y1) * scale * image.height;
    // find the box origin
    const x = x1 * image.width * scale + sX;
    const y = y1 * image.height * scale + sY;

    // console.log(`Drawing rectange at: [${x}, ${y}] -> [${x + w}, ${y + h}]`);

    if (ctx) {
      ctx.beginPath();
      ctx.lineWidth = 2;
      ctx.strokeStyle = color;
      ctx.rect(x, y, w, h);
      ctx.stroke();
      if (fillRects) {
        ctx.globalAlpha = opacity || 0;
        ctx.fillStyle = color;
        ctx.fillRect(x, y, w, h);
      }

      ctx.globalAlpha = 1.0;

      if (drawLabels || drawScore) {
        const scoreText = drawScore ? `(${twoDecimalsFixed(confidence)})` : '';
        const labelText = drawLabels ? `${category}` : '';

        drawLabelInBox({
          ctx,
          text: `${labelText}${scoreText}`,
          x,
          y,
          scale,
          color: color,
        });
      }
    }
  }

  cb?.({
    sX,
    sY,
  });
};

export const scaleToFit = (
  canvas: HTMLCanvasElement,
  img: HTMLImageElement
) => {
  if (canvas) {
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const scale = Math.min(
      canvas.width / img.width,
      canvas.height / img.height
    );
    const x = canvas.width / 2 - (img.width / 2) * scale;
    const y = canvas.height / 2 - (img.height / 2) * scale;
    ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
  }
};

export const drawTextBG = ({
  ctx,
  text,
  font,
  x,
  y,
  padding,
  color,
  backgroundColor,
}: DrawTextBGProps) => {
  ctx.font = font;
  ctx.textBaseline = 'top';
  ctx.fillStyle = backgroundColor;

  var width = ctx.measureText(text).width;
  ctx.fillRect(x, y, width + padding, parseInt(font, 10) + padding);

  ctx.lineWidth = 2;
  ctx.strokeStyle = backgroundColor;
  ctx.strokeRect(x, y, width + padding, parseInt(font, 10) + padding);

  ctx.fillStyle = color;
  ctx.fillText(text, x + padding / 2, y + padding / 2);
};

export const drawImageInCanvas = (
  canvas: HTMLCanvasElement,
  image: HTMLImageElement
) => {
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  ctx.clearRect(0, 0, canvas.width, canvas.height);
  drawImageScaled(canvas, image);
};

export const clearCanvas = (canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  ctx.clearRect(0, 0, canvas.width, canvas.height);
};

export const onRectMouseOver = () => {};

export const drawRect = ({ ctx, x, y, w, h, color }: DrawRectProps) => {
  ctx.beginPath();
  ctx.lineWidth = 2;
  ctx.strokeStyle = color;
  ctx.rect(x, y, w, h);
  ctx.stroke();
};
