import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  saveButton: {
    padding: '0 50px',
    marginTop: '40px',
  },

  extractorWrapper: {
    borderRadius: '16px',
    boxShadow: '0px 8px 16px 0px rgba(72, 79, 121, 0.15)',
  },
}));
