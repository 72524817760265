import { makeStyles } from '@material-ui/core';
import { colors } from 'material';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  chipButton: {
    borderRadius: '8px',
    backgroundColor: '#eceffa',
    // border: '1px solid ' + colors.orange,
    display: 'flex',
    gridGap: '4px',
    alignItems: 'center',
    justifyContent: 'left',
    padding: '4px 12px',
    // color: 'white',
    color: colors.blue70,
    // color: colors.orange,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.9,
    },

    '& p': {
      // color: colors.orange,
      // color: 'white',
      color: colors.blue70,
      fontWeight: 'bold',
      fontSize: '12px',
    },
  },
}));
