import React, { useCallback, useState } from 'react';
import { ActionButton } from '../ActionButton';
import { WebCamera } from './WebCamera';
import { ControlledModal } from 'components/GenericSimpleModal/ControlledModal';

interface CameraCaptureProps {
  onCaptureClick(base64Pic: string): void;
  onOpenModalClick?(): void;
  className?: string;
  colorButton?: string;
  submitText?: string | null;
}

export const CameraCapture = ({
  onCaptureClick,
  className,
  onOpenModalClick,
  colorButton,
  submitText,
}: CameraCaptureProps) => {
  const [openModal, setOpenModal] = useState(false);

  const handleShowModal = useCallback(() => {
    onOpenModalClick?.();
    setOpenModal(true);
  }, [onOpenModalClick]);

  const closeModalHandler = () => {
    setOpenModal(false);
  };
  const onCaptureClickHandler = (pic: string) => {
    closeModalHandler();
    onCaptureClick(pic);
  };

  return (
    <>
      <ActionButton
        variant="contained"
        type="submit"
        onClick={handleShowModal}
        color="secondary"
        size="large"
        customColor={colorButton}
        className={className}
      >
        {submitText || 'Capture camera'}
      </ActionButton>
      <ControlledModal
        isOpen={openModal}
        size="xs"
        closeHandler={closeModalHandler}
      >
        <WebCamera onCapturePic={onCaptureClickHandler} />
      </ControlledModal>
    </>
  );
};
