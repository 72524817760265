import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles((theme) => ({
  title: {
    color: colors.blue50,
    textWrap: 'nowrap',
  },
  line: {
    width: '100%',
    height: '1px',
    backgroundColor: '#E0E0E0',
    margin: '20px 0',
  },

  projectSection: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '40px',
    '@media (max-width: 1500px)': {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    '@media (max-width: 1280px)': {
      gap: '20px',
    },
    '@media (max-width: 1200px)': {
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '40px',
    },
    '@media (max-width: 900px)': {
      gap: '20px',
    },
    '@media (max-width: 800px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '50px',
    },
    '@media (max-width: 590px)': {
      gridTemplateColumns: 'repeat(1, 1fr)',
      gap: '30px',
    },
  },
}));
