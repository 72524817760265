import React, { ReactNode } from 'react';
import { LogoCogniflow, LogoIco } from 'assets';
import useStyle from './authformcard.styles';
import { Text } from 'components';
import { Box } from '@material-ui/core';

interface AuthFormCardProps {
  headerComponent?: ReactNode;
  subtitleComponent?: ReactNode;
  bodyComponent?: ReactNode;
  footerComponent?: ReactNode;
}

export const AuthFormCard = ({
  headerComponent,
  bodyComponent,
  subtitleComponent,
  footerComponent,
}: AuthFormCardProps) => {
  const classes = useStyle();

  const Check = () => <b className={classes.checkIcon}>&#10003;</b>;

  return (
    <>
      <div className={classes.loginForm}>
        <div>
          {!headerComponent ? (
            <header className={classes.headerSection}>
              <LogoCogniflow />
            </header>
          ) : (
            headerComponent
          )}
          {subtitleComponent}
          {bodyComponent}
          {footerComponent}
        </div>
      </div>
      <Box className={classes.infoAuthSection}>
        <Text variant="paragraph1" align="left">
          <span className={classes.infoTitle}>
            <Check /> Start with ready-to-use AI models
          </span>
          Sentiment Analysis, Face Recognition, Text Extractor from images or
          documents (OCR), or Audio transcription.
        </Text>
        <Text variant="paragraph1" align="left">
          <span className={classes.infoTitle}>
            <Check /> Build your own AI without any code
          </span>
          Text, Image or Audio Classification, Object Detection,
          Question-Answering bot.
        </Text>

        <Text variant="paragraph1" align="left">
          <span className={classes.infoTitle}>
            <Check /> Easy to Integrate and deploy
          </span>
          API, Zapier, Bubble, Microsoft Excel, Google Sheets.
        </Text>

        <div className={classes.logoIcon}>
          <LogoIco />
        </div>

        <div className={classes.filter}></div>
      </Box>
    </>
  );
};
