import React, { FC, ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { Text } from 'components';

import useStyles from './SectionTitleDivider.styles';

export interface SectionTitleDividerProps {
  children: ReactNode;
}

export const SectionTitleDivider = ({ children }: SectionTitleDividerProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" gridGap="24px">
      <Box>
        <Text variant="h4" align="left" className={classes.sectionTitle}>
          {children}
        </Text>
      </Box>

      <Box className={classes.line}></Box>
    </Box>
  );
};
