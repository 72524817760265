import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  modalWrapper: {
    display: 'flex',
    justifyContent: 'center',
    outline: 'none',
    alignItems: 'center',
    '&:active': {
      border: 'none',
    },
    borderRadius: '16px',
    overflow: 'hidden',
  },
  container: {
    borderRadius: '16px',

    width: '448px',
    backgroundColor: 'white',
    position: 'relative',
    boxShadow: '0 8px 16px 0 rgba(72, 79, 121, 0.15)',
    overflow: 'hidden',
  },
  headerElement: {
    top: '0',
    position: 'absolute',
    width: '447px',
    height: '126px',
    backgroundColor: colors.blue10,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  headerElementContrapart: {
    position: 'absolute',
    clipPath: 'ellipse(81% 100% at 50% 0%)',
    backgroundColor: 'white',
    top: '100px',
    width: '447px',
    height: '126px',
    transform: 'rotate(180deg)',
  },
  content: {
    position: 'relative',
    marginTop: '45px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'bold',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.blue70,
    margin: '0',
    padding: '20px 0',
  },
  bodyText: {
    padding: '0 48px 48px 48px',
    '& p': {
      textAlign: 'center',
    },
  },
  closeIcon: { padding: '10px', cursor: 'pointer' },
  link: {
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.orange,
    textDecoration: 'underline',
    marginLeft: 5,
    cursor: 'pointer',
  },
}));
