import React, { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { PROJECT_TASK } from 'types';

import { assets } from '../../cardAssets';

import useStyles from './CardLayout.styles';

type CardLayoutProps = {
  children: ReactNode;
  projectTask: PROJECT_TASK;
};

export const CardLayout = ({ children, projectTask }: CardLayoutProps) => {
  const classes = useStyles({
    projectTask,
  });

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.image}>
        <img src={assets[projectTask].bg} alt="card background" />
        <Box className={classes.imgBg}></Box>
      </Box>

      <Box className={classes.background}></Box>

      <Box className={classes.ellipse}>
        <img src={assets[projectTask].ellipse} alt="card ellipse" />
      </Box>

      <Box className={classes.content}>
        <Box display="flex" justifyContent="flex-start" mb="18px">
          {assets[projectTask].icon}
        </Box>
        {children}
      </Box>
    </Box>
  );
};
