import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  dragAndDropIcon: {
    height: '50px',
    width: '50px',
  },

  dragAndDrop: {
    border: `2px dashed ${colors.blue15}`,
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px 60px',
  },

  text: {
    fontWeight: 'bold',
  },

  fileSelector: {
    color: colors.orange,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      opacity: '70%',
    },
  },

  infoText: {
    color: colors.blue20,
    fontSize: '12px',
  },

  dragging: {
    backgroundColor: colors.blue5,
  },
}));
