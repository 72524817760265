import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  inputButton: {
    cursor: 'pointer',
    backgroundColor: '#fff',
    borderRadius: '3px',
    minWidth: '350px',
    '& p': {
      color: '#a4a9c8',
    },

    border: '1px solid #e0e0e0',
    display: 'flex',
    '&:hover': {
      borderColor: '#828282',
    },
  },

  inputText: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    padding: '11px 14px',
  },

  inputIcon: {
    width: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f7f9ff',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
  },
}));
