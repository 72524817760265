import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useState,
} from 'react';
import { ExpConfig, ExperimentValues } from 'types';

type Payload = {
  [key: string]: string | number | undefined | ExpConfig | boolean;
};

const initialProjectValues: ExperimentValues = {
  title: '',
  description: '',
  train_url: '',
  validation_url: '',
  type: 0,
  config: {},
};

type ProjectCreationContextProps = {
  expValues: ExperimentValues;
  projectNavTexts: { title: string; subtitle: string };
  setProjectProps(payload: Payload): void;
  resetProjectValues(task?: number): void;
  setProjectNavTextsHandler(payload: any): void;
};

const ProjectCreationContext = createContext<ProjectCreationContextProps>({
  expValues: initialProjectValues,
  projectNavTexts: { title: '', subtitle: '' },
  setProjectProps: () => {},
  resetProjectValues: () => {},
  setProjectNavTextsHandler: () => {},
});

export const useProjectCreationContext = () =>
  useContext(ProjectCreationContext);

export const ProjectCreationProvider: FC<{}> = ({ children }) => {
  const [expValues, setExpValues] = useState<ExperimentValues>(
    initialProjectValues
  );

  const [projectNavTexts, setProjectNavTexts] = useState({
    title: 'Create a new project',
    subtitle: 'lorem ipsum dolor sit amet lorem ipsum dolor sit amet',
  });

  const setProjectNavTextsHandler = useCallback((payload: any) => {
    setProjectNavTexts((s) => ({ ...s, ...payload }));
  }, []);

  const setProjectProps = useCallback((payload: Payload) => {
    setExpValues((s) => ({ ...s, ...payload }));
  }, []);

  const resetProjectValues = useCallback((task?: number) => {
    setExpValues({ ...initialProjectValues, task: task || 0 });
  }, []);

  return (
    <ProjectCreationContext.Provider
      value={{
        expValues,
        projectNavTexts,
        setProjectProps,
        resetProjectValues,
        setProjectNavTextsHandler,
      }}
    >
      {children}
    </ProjectCreationContext.Provider>
  );
};
