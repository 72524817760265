import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as FullStory from '@fullstory/browser';
import { getEnv } from './services/env';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-multi-carousel/lib/styles.css';
import 'react-edit-text/dist/index.css';

const FULL_STORY_ORG_ID = getEnv('REACT_APP_FULL_STORY');

if (FULL_STORY_ORG_ID) {
  FullStory.init({
    orgId: FULL_STORY_ORG_ID,
  });
}

if (window.self === window.top || window.location.pathname.includes('/embed')) {
  ReactDOM.render(<App />, document.getElementById('root'));
} else {
  ReactDOM.render(
    <div>Not a secure page</div>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
