import { PredictResponse } from 'types';

type TableData = { [key: string]: string }[];

export const getTableData = ({ result }: PredictResponse): TableData => {
  if (result.length > 1) {
    return [{ result: result }] as TableData;
  }
  if (result.length === 1 && Object.keys(result[0]).length > 1) {
    return Object.entries(result[0]).map(([key, value]) => ({ [key]: value }));
  }

  if (result.length === 1 && Object.keys(result[0]).length === 1) {
    return result as [];
  }

  return [];
};
