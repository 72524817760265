import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

import { colors } from 'material/colors';

export default makeStyles<ICogniflowTheme>(() => ({
  bouncingLoader: {
    display: 'flex',
    justifyContent: 'center',
  },

  bouncingLoaderItem: {
    width: '8px',
    height: '8px',
    margin: '0 3px',
    borderRadius: '50%',
    backgroundColor: colors.blue70,
    opacity: 1,
    animation: '$bouncingLoaderAnimation 0.6s infinite alternate',
  },

  '@keyframes bouncingLoaderAnimation': {
    to: {
      opacity: 0.1,
      transform: 'translateY(-16px)',
    },
  },

  bouncingLoaderItem2: {
    animationDelay: '0.2s',
  },

  bouncingLoaderItem3: {
    animationDelay: '0.4s',
  },
}));
