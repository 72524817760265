import React, { FC, ReactNode } from 'react';
import {
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableContainerProps,
} from '@material-ui/core';

export interface TableProps extends TableContainerProps {
  cols: ReactNode[];
  rows: ReactNode;
  headerOptions?: ReactNode;
}

export const Table: FC<TableProps> = ({
  cols,
  rows,
  className,
  headerOptions,
}: TableProps) => {
  return (
    <TableContainer className={className}>
      <MaterialTable>
        <TableHead>
          <TableRow
            style={{
              borderBottom: 'none',
            }}
          >
            {headerOptions}
          </TableRow>
          <TableRow>
            {cols.map((col, idx) => (
              <TableCell key={idx}>{col}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </MaterialTable>
    </TableContainer>
  );
};
