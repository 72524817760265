import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import classnames from 'classnames';
import { MaterialTextVariant } from 'types';
import useStyles from 'material/overrides/CustomTextVariants';

interface TextProps extends Omit<TypographyProps, 'variant'> {
  variant?: string | undefined;
}

export const Text = ({ variant, className, ...props }: TextProps) => {
  const classes: { [key: string]: string } = useStyles();
  const isCustom = Object.keys(classes).indexOf(variant || '') > -1;

  return (
    <Typography
      className={
        isCustom ? classnames(classes[variant || ''], className) : className
      }
      variant={isCustom ? undefined : (variant as MaterialTextVariant)}
      {...props}
    />
  );
};
