import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

type AlertProps = {
  showing: boolean;
  message: string | ReactNode;
  severity?: string;
  resetTime?: number;
  type?: string;
  position?: string;
  floating?: boolean;
  styles?: { [key: string]: string | number };
};

type AlertContextProps = {
  alert: AlertProps;
  setAlertHandler(values: AlertProps): void;
  resetAlert(): void;
};

const initialValues = {
  showing: false,
  message: '',
  severity: '',
  resetTime: 2500,
  type: '',
};

const contextInitialValues = {
  alert: initialValues,
  type: '',
  setAlertHandler: () => {},
  resetAlert: () => {},
};

const AlertContext = createContext<AlertContextProps>(contextInitialValues);

export const useAlertContext = () => useContext(AlertContext);

export const AlertContextProvider: FC<{}> = ({ children }) => {
  const [alert, setAlert] = useState<AlertProps>(initialValues);

  const setAlertHandler = useCallback((values: AlertProps) => {
    setAlert((s) => ({ ...s, ...values }));
  }, []);

  const resetAlert = useCallback(() => {
    setAlert(initialValues);
  }, []);

  return (
    <AlertContext.Provider
      value={{
        alert,
        setAlertHandler,
        resetAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
