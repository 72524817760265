// export const capitalize = (str: string): string => {
//   const [first, ...rest] = str;
//   return [first.toUpperCase(), ...rest].join('');
// };

import { EntitiesResponse } from 'types';

export const capitalize = (str: string): string =>
  str[0].toUpperCase() + str.slice(1);

export const removeCharFromStr = (char: string, str: string): string =>
  str.replace(new RegExp('_', 'g'), ' ');

export const splitTextByIndexes = (
  text: string,
  start: number,
  end: number,
  strong: boolean
): { text: string; highlighted: boolean; strong?: boolean }[] => [
  { text: text.slice(0, start), highlighted: strong },
  { text: text.slice(start, end), highlighted: true, strong },
  { text: text.slice(end), highlighted: strong },
];

export const splitStringIntoLabeledFragments = (
  fragments: EntitiesResponse[],
  text: string
) => {
  const entities = [];
  let lastIndex = 0;

  for (const entity of fragments) {
    const substr = text.substring(lastIndex, entity.start_index);
    if (substr.trim()) {
      entities.push({
        text: substr,
        label: '',
      });
    }
    entities.push(entity);
    lastIndex = entity.end_index;
  }

  entities.push({
    text: text.substring(lastIndex),
    label: '',
  });

  return entities;
};

export const getStartEndIdxOfSubstring = (
  str: string,
  sub: string
): { start: number; end: number } => ({
  start: str.indexOf(sub),
  end: str.indexOf(sub) + sub.length,
});

export const copyTextToClipboard = (
  text: string,
  containerRef?: React.MutableRefObject<null>
) => {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text);
  } else {
    // document.execCommand('copy') doesn't seem to work when there is an overlay of the document. In that case use an element container instead of document.body
    const container = containerRef?.current || document.body;
    const el = document.createElement('textarea');

    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';

    container.appendChild(el);
    el.select();
    const result = document.execCommand('copy');
    container.removeChild(el);

    return result;
  }
};

export const makeStringId = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const removeQuotesFromString = (str: string) => {
  if (typeof str !== 'string') {
    throw new Error('Input is not a string');
  }

  if (str.length < 2) {
    return str;
  }

  if (
    (str.startsWith('"') && str.endsWith('"')) ||
    (str.startsWith("'") && str.endsWith("'"))
  ) {
    return str.slice(1, -1);
  }

  return str;
};

export const generateId = () => {
  const timestamp = new Date().getTime();
  const random = Math.random().toString(36).substring(2, 9);

  return `${timestamp}-${random}`;
};

export const isJSON = (data: string) => {
  try {
    JSON.parse(data);
    return true;
  } catch (error) {
    return false;
  }
};

export const validateAudioVideoUrl = (url: string): boolean => {
  const googleDriveRegex = /^https?:\/\/drive\.google\.com\/.*(?:\/d\/|\/file\/d\/|id=)([a-zA-Z0-9_-]+).*/;

  /* eslint-disable-next-line */
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  const audioVideoRegex = /\.(mp3|wav|mp4|webm|m4a|mpga|mpeg)$/i;

  // Regex updated due to conflicts encountered, urls did not match the previous specification.
  // const cogniflowS3TmpUrlRegex = /^(https?):\/\/([a-zA-Z0-9.-]+)\.s3\.amazonaws\.com\/([a-zA-Z0-9.-]+)\/(.*)$/;
  const cogniflowS3TmpUrlRegex = /^(https?):\/\/([a-zA-Z0-9.-]+)\.amazonaws\.com\/([a-zA-Z0-9.-]+)\/(.*)$/;

  return (
    googleDriveRegex.test(url) ||
    youtubeRegex.test(url) ||
    audioVideoRegex.test(url) ||
    cogniflowS3TmpUrlRegex.test(url)
  );
};
