import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  optionDescription: {
    marginTop: 4,
    marginRight: 8,
  },
  hr: {
    borderBottom: '1px solid #d8ddeb',
    paddingBottom: '20px',
  },

  settingsTitle: {
    marginBottom: '24px',
  },

  settingsElement: {
    marginBottom: '24px',
  },
}));
