import React from 'react';
import { ErrorMessageModal, UpgradeFlowModals } from 'components';
import { useUserPlanContext } from 'context';

export const PlanModals = () => {
  const {
    showPlanExceededModal,
    showUpgradePlanModal,
    exceedPlanModalData,
    closeUpgradePlanModal,
    closePlanExceededModal,
  } = useUserPlanContext();

  return (
    <>
      <ErrorMessageModal
        title={exceedPlanModalData.title}
        description={exceedPlanModalData.description}
        open={showPlanExceededModal}
        onConfirm={exceedPlanModalData.confirmCb}
        onClose={closePlanExceededModal}
        buttonText={exceedPlanModalData.buttonText}
      />
      <UpgradeFlowModals
        open={showUpgradePlanModal}
        onClose={closeUpgradePlanModal}
      />
    </>
  );
};
