import { Overrides } from '@material-ui/core/styles/overrides';
import { colors } from '../colors';

export const Button: Overrides['MuiButton'] = {
  root: {
    textTransform: 'none',
    letterSpacing: 'normal',
  },
  contained: {
    borderRadius: 8,
    '&$disabled': {
      backgroundColor: colors.blue10,
    },
  },
  textPrimary: {
    fontSize: 16,
    color: colors.blue70,
  },
  textSecondary: {
    fontFamily: 'PTSerif',
    fontWeight: 'normal',
    lineHeight: '1.5',
    fontSize: 16,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  containedSecondary: {
    color: '#fff',
  },
  containedSizeLarge: {
    fontSize: 14,
    padding: '0 32px',
    height: 48,
    fontWeight: 600,
  },
  outlinedSizeLarge: {
    fontSize: 14,
    padding: '0 32px',
    height: 48,
    fontWeight: 600,
  },
};
