import { Overrides } from '@material-ui/core/styles/overrides';

export const StepLabel: Overrides['MuiStepLabel'] = {
  label: {
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 'normal',
    textTransform: 'uppercase',
    fontFamily: '"Poppins", sans-serif',
    '&$active': {
      fontWeight: 600,
    },
    '&$alternativeLabel': {
      marginTop: 8,
    },
  },
};

export const StepIcon: Overrides['MuiStepIcon'] = {
  text: {
    fontSize: 12,
    fontFamily: 'Poppins',
    fontWeight: 600,
  },
};

export const Step: Overrides['MuiStep'] = {
  root: {},
};
