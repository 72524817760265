import React, { FC, ReactNode, useMemo } from 'react';
import { Box, Button, Card } from '@material-ui/core';
import useStyles from './PlanCard.styles';
import classnames from 'classnames';

import { Text } from 'components';
import { PlanData, planIndex, PlAN_IDS, User } from 'types';

import { getEnv } from 'services';

export interface PlanCardProps extends PlanData {
  index: number;
  user: User | undefined | null;
  isCurrentPlan?: boolean;
  onUpgradeHandler(planId: string): void;
  disabled?: boolean;
}

const allIncludedDataMap: { [key: string]: string } = {
  [PlAN_IDS.STARTER]: 'Free',
  [PlAN_IDS.TEAM]: 'Starter',
  [PlAN_IDS.BUSINESS]: 'Team',
};

export const PlanCard: FC<PlanCardProps> = ({
  id,
  isCurrentPlan,
  planName,
  description,
  price,
  period,
  properties,
  user,
  users,
  trainingHours,
  credits,
  onUpgradeHandler,
  disabled = false,
}) => {
  const classes = useStyles();

  const canDoUpgrade = useMemo(() => {
    if (!user) return false;

    return planIndex[user.commercial_plan.id] < planIndex[id];
  }, [user, id]);

  const isFree = useMemo(() => id === PlAN_IDS.FREE, [id]);

  const redirectToStripe = () => {
    window.location.href = `${getEnv(
      'REACT_APP_CUSTOMER_PORTAL_LINK_STRIPE'
    )}?prefilled_email=${user?.email}`;
  };

  const FeatureItem = ({
    icon,
    children,
    iconSize,
  }: {
    icon?: ReactNode;
    children: ReactNode;
    iconSize?: string;
  }) => (
    <Box display="flex" gridGap="8px" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        fontSize={isFree ? '12px' : iconSize}
      >
        {isFree ? <>&#10004;</> : icon || <>&#10004;</>}
      </Box>
      <Text variant="paragraph1" align="left">
        {children}
      </Text>
    </Box>
  );

  return (
    <Card
      className={classnames({
        [classes.planCard]: true,
        [classes.currentPlanCard]: isCurrentPlan,
      })}
    >
      <Box className={classes.planCardHeader}>
        <Text variant="h3" align="left" className={classes.cardTitle}>
          {planName}
        </Text>
        <Text variant="paragraph1" align="left">
          {description}
        </Text>
      </Box>

      <Box className={classes.planPrice}>
        <Text variant="h2" style={{ position: 'relative' }} align="left">
          <span className={classes.priceSym}>$</span>
          {price}
        </Text>
        <Text variant="paragraph1" align="left">
          {period}
        </Text>
      </Box>

      {!disabled && (
        <>
          {canDoUpgrade ? (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={classnames({
                [classes.upgradeButton]: true,
              })}
              onClick={() => {
                onUpgradeHandler(id);
              }}
            >
              {'Upgrade'}
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={classnames({
                [classes.upgradeButton]: true,
              })}
              disabled={isCurrentPlan}
              onClick={redirectToStripe}
            >
              {isCurrentPlan ? 'Current plan' : 'Downgrade'}
            </Button>
          )}
        </>
      )}

      <Box className={classes.planFeatures}>
        <Box display="flex" flexDirection="column" gridGap="8px">
          <FeatureItem iconSize="12px">{credits} credits/mo</FeatureItem>
          <FeatureItem iconSize="12px">
            {trainingHours} training hours/mo
          </FeatureItem>

          <FeatureItem iconSize="12px">{users}</FeatureItem>

          {!isFree && (
            <FeatureItem iconSize="12px">
              All included in plan <b>{allIncludedDataMap[id]}</b>
            </FeatureItem>
          )}
          {properties.map((text: string) => (
            <FeatureItem icon={<>&#43;</>}>{text}</FeatureItem>
          ))}
        </Box>
      </Box>
    </Card>
  );
};
