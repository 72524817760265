import React, { FC, ChangeEvent } from 'react';
import cn from 'classnames';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import useStyles from './SingleSelect.styles';

export interface Option {
  label: string;
  value: any;
}

export interface SingleSelectProps {
  className?: string;
  label?: string;
  value: any;
  options: Option[];
  onChange: (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => void;
}

export const SingleSelect: FC<SingleSelectProps> = ({
  className,
  label,
  value,
  options = [],
  onChange,
}) => {
  const classes = useStyles();
  return (
    <FormControl
      className={cn(classes.container, className)}
      variant="outlined"
    >
      <InputLabel id="test">{label}</InputLabel>
      <Select label={label} value={value} autoWidth onChange={onChange}>
        {options?.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
