import React, { FC, ReactNode } from 'react';
import useStyles from './FAQ.styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Text } from 'components';
import { FaqsData } from './FaqsData';

export interface FAQProps {}

export const FAQ: FC<FAQProps> = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Text variant="h2" className={classes.faqsTitle}>
        Frequently asked questions
      </Text>

      <div className={classes.faqContainer}>
        {FaqsData.map(
          (
            { question, answer }: { question: string; answer: ReactNode },
            idx
          ) => (
            <Accordion key={idx}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Text className={classes.heading}>{question}</Text>
              </AccordionSummary>
              <AccordionDetails>
                <Text variant="paragraph1" align="left">
                  {answer}
                </Text>
              </AccordionDetails>
            </Accordion>
          )
        )}
      </div>
    </div>
  );
};
