import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

const flexBetween = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

interface StyleProps {
  progressColor: string;
}

export default makeStyles(() => ({
  percentageWrapper: {
    ...flexBetween,
  },
  percentageNumber: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    color: colors.blue70,
    margin: '0 8px 0 0',
  },
  percentage: {
    '& .MuiLinearProgress-root': {
      backgroundColor: colors.blue10,
    },
  },

  progress: ({ progressColor }: StyleProps) => ({
    height: '8px',
    width: '60px',
    borderRadius: '16px',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: progressColor,
    },
  }),
}));
