import React from 'react';
import { Box, Button } from '@material-ui/core';
import { Text } from 'components';
import { CardLayout } from './CardLayout';

import useStyles from './CreateProjectCard.styles';
import { useHistory } from 'react-router-dom';

export interface CreateProjectCardProps {
  title: string;
  description: string;
  to: string;
  projectTask: number;
}

export const CreateProjectCard = ({
  title,
  description,
  projectTask,
  to,
}: CreateProjectCardProps) => {
  const classes = useStyles();

  const { push } = useHistory();

  const handleClick = () => {
    push(to);
  };

  return (
    <CardLayout projectTask={projectTask}>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box mb="30px">
          <Text variant="paragraph1" align="left" className={classes.title}>
            {title}
          </Text>
          <Text
            variant="paragraph1"
            align="left"
            className={classes.description}
          >
            {description}
          </Text>
        </Box>
        <Box display="flex" justifyContent="flex-start">
          <Button
            size="large"
            variant="outlined"
            className={classes.button}
            onClick={handleClick}
          >
            Create
          </Button>
        </Box>
      </Box>
    </CardLayout>
  );
};
