import React, { FC, ReactNode } from 'react';
import { Box } from '@material-ui/core';
import useStyles from './TestMessageContainer.styles';

export interface TestMessageContainerProps {
  children: ReactNode;
}

export const TestMessageContainer: FC<TestMessageContainerProps> = ({
  children,
}) => {
  const classes = useStyles();
  return <Box className={classes.messageContainer}>{children}</Box>;
};
