import { SeverityScheme } from 'types';

export const colors = {
  blue90: '#141623',
  blue70: '#272c4d',
  blue50: '#484f79',
  blue20: '#a4a9c8',
  blue15: '#d8ddeb',
  blue10: '#eceffa',
  blue5: '#f7f9ff',
  orange: '#ff6a61',
  lightOrange: '#ffeceb59',
  errorRed: '#ff4343',
  lightGray: '#eceffa',
  greenSuccess: '#4CAF50',
};

export const severityScheme: SeverityScheme = {
  error: { backgroundColor: colors.errorRed, color: 'white' },
  success: { backgroundColor: colors.greenSuccess, color: 'white' },
  warning: { backgroundColor: colors.orange, color: 'white' },
  info: { backgroundColor: colors.blue20, color: 'white' },
  default: { backgroundColor: colors.blue20, color: 'white' },
};
