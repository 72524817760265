import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useState,
} from 'react';
import { getModel } from 'services';
import { Log, Model } from 'types';

type LogsData = {
  [key: string]: Log[];
};

type ExperimentContextProps = {
  model: Model | undefined;
  logs: LogsData;
  setModel(model: Model | undefined): void;
  addModelLogs(modelId: string): void;
};

const ExperimentContext = createContext<ExperimentContextProps>({
  model: undefined,
  logs: {},
  setModel: () => {},
  addModelLogs: () => {},
});

export const useExperimentContext = () => useContext(ExperimentContext);

export const ExperimentProvider: FC<{}> = ({ children }) => {
  const [model, setModel] = useState<Model>();

  const [logs, setLogs] = useState<LogsData>({});

  const setModelHandler = useCallback((model: Model) => {
    setModel(model);
  }, []);

  const addModelLogs = useCallback(
    (modelId: string) => {
      if (!logs[modelId]) {
        getModel(modelId).then((modelResponse: Model) => {
          setLogs((s) => ({ ...s, [modelId]: modelResponse.logs }));
        });
      }
    },
    [logs]
  );

  return (
    <ExperimentContext.Provider
      value={{ model, setModel: setModelHandler, addModelLogs, logs }}
    >
      {children}
    </ExperimentContext.Provider>
  );
};
