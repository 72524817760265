import React, { ReactNode } from 'react';
import { Box, Button } from '@material-ui/core';
import { Text } from 'components';

import { useHistory } from 'react-router-dom';

import useStyles from './ProjectTypeCard.styles';

export interface ProjectTypeCardProps {
  title: string;
  description: string;
  projectType?: number;
  image: ReactNode;
  to?: string;
  url?: string;
  onSelect?(): void;
}

export const ProjectTypeCard = ({
  title,
  description,
  image,
  to,
  url,
  onSelect,
}: ProjectTypeCardProps) => {
  const classes = useStyles();

  const { push } = useHistory();

  const handleSelect = () => {
    if (url) {
      window.open(url, '_blank');
      push('/projects/dashboard');
      return;
    }
    if (to) {
      push(to);
    }
    onSelect?.();
  };

  return (
    <Box className={classes.wrapper}>
      <Box mb="24px">
        <Box className={classes.image} mb="8px">
          {image}
        </Box>
        <Box className={classes.title} mb="4px">
          <Text variant="paragraph1">{title}</Text>
        </Box>
        <Box className={classes.description}>
          <Text
            variant="paragraph1"
            dangerouslySetInnerHTML={{ __html: description }}
          ></Text>
        </Box>
      </Box>

      <Button
        size="large"
        variant="contained"
        color="secondary"
        className={classes.selectButton}
        fullWidth
        onClick={handleSelect}
      >
        Select
      </Button>
    </Box>
  );
};
