import React, { ReactNode, useEffect, useCallback, useState } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { Grid, CircularProgress, Typography, Box } from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { uploadFinishedAtom } from 'hooks/useUploadProgress';
import classNames from 'classnames';
import { DeleteIcon } from 'assets';
import useStyles from './dropzone.styles';
import { useExpCreationContext } from 'context';

interface Props {
  percent: number;
  onDrop: DropzoneOptions['onDrop'];
  children: ReactNode;
  draggingLabel: string;
  filename?: string;
  error?: boolean;
  customClass?: string;
  uploadType: string;
  accept?: string;
  maxFileSize?: string;
}

function Dropzone({
  percent,
  onDrop,
  children,
  filename,
  draggingLabel,
  error,
  customClass,
  uploadType,
  accept,
  maxFileSize,
}: Props) {
  const classes = useStyles();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    accept: accept || '',
  });

  const { expValues, setExpProps } = useExpCreationContext();

  const fileUploaded = React.useMemo(() => {
    if (uploadType === 'train_url') {
      return expValues.train_url;
    }
    return expValues.validation_url;
  }, [expValues, uploadType]);
  const [isFinishedUpload, setFinished] = useRecoilState(uploadFinishedAtom);
  const [typeOfProgress, setType] = useState<
    'static' | 'determinate' | 'indeterminate'
  >('static');
  const [isDeleted, setIsDeleted] = useState<boolean>(true);

  useEffect(() => {
    setFinished(false);
  }, [setFinished]);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setIsDeleted(false);
    }
  }, [acceptedFiles, setIsDeleted, filename]);

  const handleDeleteData = useCallback(
    (e) => {
      e.stopPropagation();
      if (acceptedFiles.length > 0) {
        acceptedFiles.splice(0, 1);
        setIsDeleted(true);
      }
      setExpProps({
        validation_url: '',
      });
    },
    [acceptedFiles, setIsDeleted, setExpProps]
  );

  useEffect(() => {
    if (percent === 100 && !isFinishedUpload) {
      setType('determinate');
    } else {
      setType('static');
    }
  }, [isFinishedUpload, percent, setType]);

  return (
    <div
      {...getRootProps()}
      className={classNames(
        customClass ? customClass : classes.background,
        { [classes.backgroundOnDrag]: isDragActive },
        { [classes.backgroundActive]: !isDeleted || fileUploaded },
        { [classes.dropzone]: true }
      )}
    >
      <input {...getInputProps()} />
      <div
        className={
          !isDeleted ? classes.backgroundProgress : classes.backgroundDefault
        }
        style={{ width: `${isDeleted ? percent : 0}%` }}
      />
      {isDragActive ? (
        <Typography className={classes.content} variant="body1" align="center">
          {draggingLabel}
        </Typography>
      ) : (
        <>
          {isDeleted ? (
            <>
              {!fileUploaded ? (
                <Grid container justify="center" alignItems="center">
                  {children}
                </Grid>
              ) : (
                <Grid
                  className={classes.uploading}
                  container
                  justify="flex-start"
                  alignItems="center"
                >
                  <div className={classes.loadingContainer}>
                    <CircularProgress
                      size={48}
                      color="secondary"
                      variant="static"
                      value={!fileUploaded ? percent : 100}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="button"
                        component="div"
                        className={classes.progressLabel}
                      >{`${Math.round(100)}%`}</Typography>
                    </Box>
                  </div>
                  <Grid direction="column" alignContent="flex-start">
                    <Typography variant="h3" className={classes.filename}>
                      {filename}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.uploadMessage}
                    >
                      Click to change your dataset (max upload{' '}
                      {maxFileSize || '2GB'}){' '}
                    </Typography>
                  </Grid>
                  <Box className={classes.deleteDatasetIcon}>
                    {uploadType === 'validation_url' && (
                      <DeleteIcon onClick={handleDeleteData} />
                    )}
                  </Box>
                </Grid>
              )}
            </>
          ) : (
            <Grid
              className={classes.uploading}
              container
              justify="flex-start"
              alignItems="center"
            >
              <div className={classes.loadingContainer}>
                {typeOfProgress === 'static' ? (
                  <CircularProgress
                    size={48}
                    color="secondary"
                    variant="static"
                    value={percent}
                  />
                ) : (
                  <CircularProgress
                    size={48}
                    color="secondary"
                    variant="indeterminate"
                    value={99}
                  />
                )}

                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="button"
                    component="div"
                    className={classes.progressLabel}
                  >{`${Math.round(
                    typeOfProgress === 'static' ? percent : 99
                  )}%`}</Typography>
                </Box>
              </div>
              <Box
                display="flex"
                flexDirection="column"
                alignContent="flex-start"
              >
                <Typography variant="h3" className={classes.filename}>
                  {filename}
                </Typography>
                <Typography variant="body1" className={classes.uploadMessage}>
                  Click to change your dataset (max upload{' '}
                  {maxFileSize || '2GB'}){' '}
                </Typography>
              </Box>
              <Box className={classes.deleteDatasetIcon}>
                {uploadType === 'validation_url' && (
                  <DeleteIcon onClick={handleDeleteData} />
                )}
              </Box>
            </Grid>
          )}
        </>
      )}
    </div>
  );
}

export default React.memo(Dropzone);
