import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  formSection: {
    paddingLeft: '12px 40px',
  },
  headerTitle: {
    fontFamily: 'Poppins',
    fontSize: '32px',
    fontWeight: 600,
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.blue20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  formTitleSection: {
    // height: '88px',
    padding: '0px 40px',
  },
  footerSection: {
    // backgroundColor: colors.blue10,
    height: '80px',
    alignSelf: 'end',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  signupLink: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: colors.orange,
    alignSelf: 'flex-end',
    marginBottom: '24px',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));
