import React from 'react';
import ChevronIcon from '@material-ui/icons/ChevronLeftRounded';
import useStyles from './chevron.styles';

export interface ChevronProps {
  direction: 'up' | 'down';
}

export const Chevron = ({ direction }: ChevronProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ChevronIcon
        className={direction === 'up' ? classes.iconUp : classes.iconDown}
      />
    </div>
  );
};
