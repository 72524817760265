import React, { useMemo } from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import useStyles from './action-button.styles';

interface ActionButtonProps extends ButtonProps {
  loading?: boolean;
  customColor?: string;
}

export const ActionButton = React.memo(
  ({
    disabled = false,
    loading = false,
    customColor,
    children,
    ...props
  }: ActionButtonProps) => {
    const classes = useStyles();
    const backgroundColor = useMemo(
      () => (!disabled && !loading ? customColor : ''),
      [disabled, loading, customColor]
    );

    return (
      <Button
        className={classes.containedSecondary}
        disabled={disabled || loading}
        style={{ backgroundColor }}
        {...props}
      >
        {loading && (
          <CircularProgress
            color={'secondary'}
            size={24}
            style={{ marginRight: 16, color: customColor }}
          />
        )}
        {children}
      </Button>
    );
  }
);
