import { makeStyles } from '@material-ui/core';

type CardStyleProps = {
  isDark: boolean;
};

export default makeStyles(() => ({
  card: ({ isDark }: CardStyleProps) => ({
    '& p': {
      color: isDark ? 'white' : '',
    },
    '& h2': {
      color: isDark ? 'white' : '',
    },
    position: 'relative',
    height: '400px',
    width: '280px',
    borderRadius: '16px',
    padding: '32px',
    backgroundColor: isDark ? '#484f79' : 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: 'rgba(255, 106, 97, 0.3) 0px 0px 20px',
  }),
  discount: {
    position: 'absolute',
    top: 10,
    right: 10,
    padding: '8px 12px',
    background: '#ff6a61',
    color: '#fff',
    borderRadius: 8,
    fontWeight: 'bold',
  },
  headerDescriptionContainer: {
    marginBottom: '32px',
  },
  planNameText: {
    fontFamily: 'Poppins',
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '12px',
  },
  descriptionText: {
    fontFamily: 'Poppins',
    fontSize: '16px',
  },
  priceInfo: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '24px',
  },
  price: {
    position: 'relative',
  },
  priceSym: {
    position: 'absolute',
    fontSize: '14px',
    left: '-12px',
    fontWeight: 'normal',
  },
  oldPrice: {
    textDecoration: 'line-through',
    opacity: 0.4,
    fontWeight: 300,
    fontSize: 20,
  },
  period: {},
  upgradeButton: {
    marginBottom: '32px',
    height: '48px',
    borderRadius: '8px',
    boxShadow: 'rgba(255, 106, 97, 0.5) 0px 0px 30px',
  },
  property: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 'normal',
  },
  propertyContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    '&:svg g g': {
      fill: 'black',
    },
  },
  additionalPrediction: ({ isDark }: CardStyleProps) => ({
    color: isDark ? 'white' : '',
    fontWeight: 'normal',
    fontSize: '12px',
    fontFamily: 'Poppins',
  }),
}));
