import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>((theme) => ({
  root: {
    width: '100%',
    '& p': {
      lineHeight: '1.6',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  faqContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  faqsTitle: {
    marginBottom: '50px',
  },
}));
