import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { deleteExperiment } from 'services/experiments';
import {
  currentExperimentState,
  experimentError,
  experimentLoading,
} from 'store/experiments';
import { ConfirmDelete } from '../Modals/ConfirmDelete';
import { Experiment, STATUS_FINISHED } from 'types';
import { ActionButton } from 'components';
import { deleteLastVisitedProject } from 'services';

type DeleteExperimentProps = {
  experiment?: Experiment;
};

export const DeleteExperiment = ({
  experiment: propsExperiment,
}: DeleteExperimentProps) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const storeExperiment = useRecoilValue(currentExperimentState);
  const setExperimentError = useSetRecoilState(experimentError);
  const [expLoading, setExperimentLoading] = useRecoilState(experimentLoading);
  const [allowDeleteExp, setAllowDelete] = useState<boolean>(false);

  const experiment = propsExperiment || storeExperiment;

  const handleDelete = useCallback(() => {
    if (experiment) {
      setExperimentLoading(true);
      deleteExperiment(experiment.id)
        .then(() => {
          deleteLastVisitedProject(experiment.id);
          // TODO: remove this reload and update state accordingly
          history.push('/projects/dashboard');
          window.location.reload();
        })
        .catch((err) => {
          setExperimentError(err.message);
        })
        .finally(() => {
          setExperimentLoading(false);
        });
    }
  }, [history, setExperimentLoading, setExperimentError, experiment]);
  const openModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (experiment) {
      setAllowDelete(experiment.status >= STATUS_FINISHED);
    }
  }, [experiment, allowDeleteExp]);

  return (
    <>
      {open && (
        <ConfirmDelete
          onCancel={closeModal}
          onClose={closeModal}
          onConfirm={handleDelete}
        />
      )}
      {allowDeleteExp && (
        <ActionButton
          size="large"
          variant="contained"
          color="secondary"
          onClick={openModal}
          disabled={expLoading}
        >
          Delete
        </ActionButton>
      )}
    </>
  );
};
