import React from 'react';
import { Box } from '@material-ui/core';
import { Text } from 'components';

import useStyles from './SearchTag.styles';

export interface SearchTagProps {
  projectTask: number;
  name: string;
  onClick?: () => void;
  isSelected?: boolean;
}

export const SearchTag = ({
  name,
  projectTask,
  onClick,
  isSelected,
}: SearchTagProps) => {
  const classes = useStyles({ projectTask, isSelected });

  return (
    <Box className={classes.tag} onClick={onClick}>
      <Text variant="paragraph1">{name}</Text>
    </Box>
  );
};
