import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  modalWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    '&:active': {
      border: 'none',
    },
  },
  planCard: {
    width: 320,
    height: 420,
  },
}));
