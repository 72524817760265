import React, { useEffect, useState } from 'react';
import { User } from 'types';
import { Grid, Modal, Typography } from '@material-ui/core';
import useStyles from './upgradeplanmodal.styles';
import { IconClose } from 'assets';
import { LongSwitch } from 'components/LongSwitch';
import { PlanCard } from './PlanCard';
import { plansInfo, PlanData } from './plans';
import { useAuthenticationContext } from 'context';

type UpgradePlanModalProps = {
  open: boolean;
  onClose(): void;
  onUpgradeHandler(planId: string, planPeriodIndex: number): void;
};

export const UpgradePlanModal = ({
  onClose,
  onUpgradeHandler,
  ...props
}: UpgradePlanModalProps) => {
  const classes = useStyles();
  const { user } = useAuthenticationContext();

  const [planCardsContent, setPlanCardsContent] = useState<PlanData[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setPlanCardsContent(plansInfo[selectedIndex]);
  }, [selectedIndex]);

  const onSelect = (index: number) => {
    setSelectedIndex(index);
  };

  return (
    <Modal {...props} className={classes.modalWrapper}>
      <Grid className={classes.modalContainer}>
        <Grid className={classes.header}>
          <div></div>
          <Typography variant="h2" className={classes.title}>
            Upgrade your plan
          </Typography>
          <div className={classes.closeIcon}>
            <IconClose
              onClick={onClose}
              style={{
                cursor: 'pointer',
              }}
            />
          </div>
        </Grid>

        <Grid className={classes.content}>
          <Grid className={classes.switchContainer}>
            <LongSwitch
              options={[
                { text: 'annualy', helperText: '-20%' },
                { text: 'monthly' },
              ]}
              selectedIndex={selectedIndex}
              selectIndexHandler={onSelect}
            />
          </Grid>

          {user && (
            <Grid className={classes.planCards}>
              {planCardsContent.map((card, idx) => (
                <PlanCard
                  idx={idx}
                  key={idx}
                  user={user as User}
                  currentPlanId={user ? user.commercial_plan?.id : ''}
                  onUpgradeHandler={onUpgradeHandler}
                  planPeriodIndex={selectedIndex}
                  {...card}
                />
              ))}
            </Grid>
          )}
        </Grid>
        <p className={classes.containerUnderlinedSubtitle}>
          Do you want to downgrade?{' '}
          <span
            onClick={() => {
              window.location.href = `mailto:support@cogniflow.ai?subject=Commercial plan downgrade request`;
            }}
          >
            contact us
          </span>
        </p>
      </Grid>
    </Modal>
  );
};
