import React from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { CircularProgress, Grid, Typography, Box } from '@material-ui/core';
import classNames from 'classnames';
import { UploadIcon } from 'assets';

import useStyles from './dropzone.styles';

export interface SmallDropzoneProps {
  percent: number;
  onDrop: DropzoneOptions['onDrop'];
  title?: string;
  subtitle?: string;
  error?: boolean;
  imgSrc?: string;
  dropzoneOptions?: DropzoneOptions;
}

export function SmallDropzone({
  percent,
  onDrop,
  title,
  subtitle,
  error,
  imgSrc,
  dropzoneOptions,
}: SmallDropzoneProps) {
  const classes = useStyles();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: percent > 0 && percent < 100,
    ...dropzoneOptions,
  });

  return (
    <div {...getRootProps()} className={classes.dropzoneWrapper}>
      <Grid container direction="row" wrap="nowrap">
        <Grid item>
          {!percent && !imgSrc && (
            <div
              className={classNames(classes.uploadIcon, {
                [classes.activeIcon]: isDragActive,
              })}
            >
              <UploadIcon className={classes.uploadIconImage} />
            </div>
          )}
          {percent > 0 && percent < 100 && (
            <div className={classes.loadingContainer}>
              <CircularProgress
                color="secondary"
                variant="static"
                value={percent}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >{`${Math.round(percent)}%`}</Typography>
              </Box>
            </div>
          )}
          {imgSrc && (
            <div className={classes.imgPreviewContainer}>
              <div
                style={{ backgroundImage: `url(${imgSrc})` }}
                className={classes.imgPreview}
              />
            </div>
          )}
        </Grid>
        {title && (
          <Grid
            item
            container
            direction="column"
            alignItems="flex-start"
            justify="center"
            className={classes.text}
          >
            <Typography variant="h4">{title}</Typography>
            <Typography variant="body1">{subtitle}</Typography>
          </Grid>
        )}
      </Grid>
      <input {...getInputProps()} />
    </div>
  );
}
