import React from 'react';
import { Box } from '@material-ui/core';
import { Text } from 'components';
import { DeleteExperiment } from '../DeleteExperiment';
import { UpdateExperimentVisibility } from '../UpdateExperimentVisibility';
import { Experiment } from 'types';

import classNames from 'classnames';

import useStyles from './experimentsettings.styles';

export const ExperimentSettings = ({
  experiment,
  closeSettingsModal,
}: {
  experiment: Experiment;
  closeSettingsModal?(): void;
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Text variant="h2" className={classes.settingsTitle}>
        Settings
      </Text>

      <Box
        display="flex"
        justifyContent="space-between"
        className={classNames(classes.settingsElement, classes.hr)}
      >
        <Box>
          <Text variant="paragraph1">
            <b>Visibility</b>
          </Text>
          <Text variant="paragraph1" className={classes.optionDescription}>
            Change your experiment visibility
          </Text>
        </Box>

        <UpdateExperimentVisibility
          experiment={experiment}
          closeSettingsModal={closeSettingsModal}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.settingsElement}
      >
        <Box>
          <Text variant="paragraph1">
            <b>Delete experiment</b>
          </Text>
          <Text variant="paragraph1" className={classes.optionDescription}>
            Once you delete an experiment, there is no going back.
          </Text>
        </Box>

        <DeleteExperiment experiment={experiment} />
      </Box>
    </Box>
  );
};
