import {
  format,
  intervalToDuration,
  formatDistance,
  formatDuration,
} from 'date-fns';
import { TOKENS } from 'types';

export const getTZDateFromUTC = (date: string | Date) => {
  const offset = new Date(date).getTimezoneOffset();
  const time = new Date(date).getTime();
  const newDate = new Date(time - offset * 60 * 1000);
  return newDate;
};

export const fromUTC = (date: string | Date) => {
  const dateUTC =
    `${date}`.includes('Z') || `${date}`.includes('+00:00')
      ? date
      : `${date}+00:00`;
  return new Date(dateUTC);
};

export const getFormatedFromUTC = (date: string | Date, pattern: string) => {
  return format(fromUTC(date), pattern);
};

export const getFormattedDate = (date: string | Date, pattern: string) =>
  format(date as Date, pattern);

export const getFormatedLocalDate = (date: Date, pattern: string) => {
  return format(date, pattern);
};

export const getUtcDate = (date: string | Date) => {
  const splited = `${date}`.split('T');
  const [year, month, day] = splited[0].split('-');
  const [hours, min, sec] = splited[1].split(':');

  const nDate = new Date(
    Date.UTC(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      parseInt(hours),
      parseInt(min),
      parseInt(sec)
    )
  );
  return nDate;
};

export const timeTookToRun = (startDate: Date, endDate: Date) => {
  let message = '-';
  if (startDate && endDate) {
    const diff = intervalToDuration({
      start: getUtcDate(startDate),
      end: getUtcDate(endDate),
    });
    message = `${diff.hours} hr ${diff.minutes} min ${diff.seconds} sec`;
  }
  if (startDate && !endDate) {
    const diff = intervalToDuration({
      start: getUtcDate(startDate),
      end: new Date(),
    });
    message = `(${diff.hours} hr ${diff.minutes} min ${diff.seconds} sec):Running`;
  }
  return message;
};

export const getTimeAgoString = (date: string): string =>
  formatDistance(new Date(date), new Date(), { addSuffix: true });

export const timesDifferenceFormatted = (start: string, end: string) => {
  const units = [
    { name: 'hours', short: 'hr' },
    { name: 'minutes', short: 'min' },
    { name: 'seconds', short: 'sec' },
  ];
  return units.reduce(
    (ac, { name, short }) => ac.replace(name, short),
    formatDuration(
      intervalToDuration({
        start: new Date(start),
        end: new Date(end),
      })
    )
  );
};

export const getInviteTokenCreationDate = (expirationDate: string) => {
  const date = new Date(expirationDate);

  date.setHours(date.getHours() - TOKENS.USER_COMPANY_INVITE_TOKEN_EXPIRATION);
  return date;
};

// export const hasPassed24Hours = (date: string) => {
//   const initialDate = new Date(date);

//   const currentDate = new Date();
//   const past24hours = new Date();

//   past24hours.setTime(initialDate.getTime() + 1000 * 60 * 60 * 24);
//   return currentDate > past24hours;
// };

export const has24HoursPassed = (startDate: Date, endDate: Date) => {
  let difference = endDate.valueOf() - startDate.valueOf();
  let differenceInHours = difference / (1000 * 60 * 60);

  return differenceInHours >= 24;
};

export const millisecondsUntilDate = (date: string) => {
  const futureDate = new Date(date);
  const currentTime = new Date().getTime();
  const targetTime = new Date(futureDate).getTime();

  return targetTime - currentTime;
};
