import { useMemo } from 'react';

import { EXPERIMENT_TASK, EXPERIMENT_TYPE } from 'types';

type UseExperimentConditionsProps = {
  type?: string | number;
  task?: string | number;
};

export const useExperimentConditions = ({
  type,
  task,
}: UseExperimentConditionsProps) => {
  const typeNum = useMemo(() => parseInt(type as string), [type]);

  const isImage = typeNum === EXPERIMENT_TYPE.IMAGE;
  const isText = typeNum === EXPERIMENT_TYPE.TEXT;
  const isAudio = typeNum === EXPERIMENT_TYPE.AUDIO;

  const isExtractor = task === EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR;

  return {
    isImage,
    isText,
    isAudio,
    isExtractor,
  };
};
