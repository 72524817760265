import { http } from './http';
import { ApplicationWhatsapp } from 'types';

export const updateWhatsappToken = (
  applicationID: string,
  newWhatsappToken: string
): Promise<ApplicationWhatsapp> =>
  http.patch(
    `application/${applicationID}/whatsapp`,
    JSON.stringify({
      access_token: newWhatsappToken,
    })
  );
