import React, {
  FC,
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { http } from 'services/http';
import { getEnv } from 'services/env';
import Centrifuge, { SubscribePrivateResponseData } from 'centrifuge';
import { useAuthenticationContext } from './AuthenticationContext';
import { useConstructor } from 'hooks';

const REACT_APP_CENTRIFUGO_URL = getEnv('REACT_APP_CENTRIFUGO_URL');

type CentrifugeContextType = {
  client: any;
};

export const useCentrifuge = () => {
  return useContext(CentrifugeContext);
};

export const CentrifugeContext = createContext<Partial<CentrifugeContextType>>(
  {}
);

export const CentrifugeProvider: FC<{}> = ({ children }) => {
  const [client, setClient] = useState();
  const ref = useRef<any>(null);

  const { token } = useAuthenticationContext();

  useConstructor(() => {
    ref.current = new Centrifuge(
      REACT_APP_CENTRIFUGO_URL || 'ws://localhost:8000/connection/websocket',
      {
        onPrivateSubscribe: async (ctx, cb) => {
          const data = await http.post<SubscribePrivateResponseData>(
            'subscription/',
            JSON.stringify(ctx.data)
          );
          cb({ status: 200, data });
        },
      }
    );
  });

  useEffect(() => {
    if (token && ref.current) {
      const client = ref.current;
      client.on('connect', () => {
        setClient(client);
      });
      client.setToken(token);
      client.connect();
    }
  }, [token]);

  return (
    <CentrifugeContext.Provider value={{ client }}>
      {children}
    </CentrifugeContext.Provider>
  );
};
