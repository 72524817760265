import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles((theme) => ({
  initials: {
    width: 46,
    height: 46,
    fontSize: 28,
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: 8,
    boxShadow: '0 9px 16px 0 rgba(72, 79, 121, 0.15)',
    border: `1px solid white`,
  },
  initialsText: {
    lineHeight: 1,
    color: theme.palette.primary.main,
    fontSize: 28,
    textTransform: 'uppercase',
  },
  userImage: {
    borderRadius: 8,
    boxShadow: '0 9px 16px 0 rgba(72, 79, 121, 0.15)',
    border: `1px solid ${colors.blue15}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    width: '100%',
  },
}));
