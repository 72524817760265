import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  line: {
    width: '100%',
    height: '1px',
    backgroundColor: '#E0E0E0',
    margin: '20px 0',
  },

  sectionTitle: {
    color: colors.blue50,
    textWrap: 'nowrap',
  },
}));
