import { useRef, useState } from 'react';

const micRecorder = require('mic-recorder-to-mp3');

type UseMicRecordingProps = Partial<{
  onStartRecording(): void;
}>;

export const useMicRecording = ({ onStartRecording }: UseMicRecordingProps) => {
  const recorder = useRef(
    new micRecorder({
      bitRate: 128,
    })
  );
  const [mediaBlob, setMediaBlob] = useState<File | null>(null);

  const startRecording = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: true });

    recorder.current
      .start()
      .then(() => {
        onStartRecording?.();
      })
      .catch((e: Error) => {
        console.error(e);
      });
  };

  const stopRecording = () => {
    recorder.current
      .stop()
      .getMp3()
      .then(([buffer, blob]: any) => {
        const file = new File(buffer, 'myaduioexample.mp3', {
          type: blob.type,
          lastModified: Date.now(),
        });

        setMediaBlob(file);
      });
  };

  return {
    startRecording,
    mediaBlob,
    stopRecording,
  };
};
