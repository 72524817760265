import React, { useMemo } from 'react';
import { Grid, Box, Typography, Collapse, Divider } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { FolderIcon, DownloadIcon } from 'assets';
import { PercentageData, Text } from 'components';

import useStyles from './datasetmoda.styles';
import { twoDecimalsFixed } from 'utils';

type StatsProps = {
  category: string;
  amount: number;
};

type DatasetsModalProps = {
  name: string;
  url: string;
  stats: StatsProps[];
  description: string;
};

export const ModalItem = ({
  name,
  description,
  stats,
  url,
}: DatasetsModalProps) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const total = useMemo(
    () => stats.reduce((prev, curr) => prev + curr.amount, 0),
    [stats]
  );

  const getPercentage = (value: number) =>
    Number(twoDecimalsFixed((value / total) * 100));

  const onDownloadClickHandler = () => {
    window.open(url, '_blank');
  };

  return (
    <Grid className={classes.itemContainer}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.itemBox}
      >
        <Box display="flex" alignItems="center" gridGap="8px">
          {/* <Radio value={name} /> */}
          <FolderIcon />
          <Typography variant="h4">{name}</Typography>
        </Box>

        <Box display="flex" gridGap="12px" alignItems="center">
          <DownloadIcon cursor="pointer" onClick={onDownloadClickHandler} />
          <Box onClick={handleClick} display="flex" alignItems="center">
            {open ? (
              <ExpandLess cursor="pointer" />
            ) : (
              <ExpandMore cursor="pointer" />
            )}
          </Box>
        </Box>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box className={classes.dataContainer}>
          <Text variant="paragraph1" className={classes.itemDescription}>
            {description}
          </Text>
          <Box className={classes.StatsContainer}>
            <Box
              className={classes.statsItem}
              display="flex"
              alignItems="center"
            >
              <Box>
                <Text variant="h4">Examples</Text>
              </Box>
              <Box display="flex" justifyContent="center">
                <Box className={classes.amount}>
                  <Text variant="h4">{total}</Text>
                </Box>
              </Box>
              <Box></Box>
            </Box>
            <Divider className={classes.divider} />
            <Box display="flex" flexDirection="column" gridGap="12px">
              {stats.map(({ category, amount }: StatsProps) => (
                <Box
                  key={category}
                  className={classes.statsItem}
                  display="flex"
                  alignItems="center"
                >
                  <Box>
                    <Text
                      className={classes.categoryText}
                      variant="h4"
                      title={category}
                    >
                      {category}
                    </Text>
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Box className={classes.amount}>
                      <Text variant="h4">{amount}</Text>
                    </Box>
                  </Box>
                  <Box>
                    <PercentageData
                      amount={3}
                      percentage={getPercentage(amount) as number}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Grid>
  );
};
