import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  customRoot: {},
  modalContainer: {
    //width: '840px',
  },
  modalTitle: {
    padding: '0px 24px',
  },
  dialogContent: {
    //padding: '0px 48px 24px 48px',
  },
  dialogContainer: {
    borderRadius: '16px',
  },
  paper: {
    margin: 0,
    width: 840,
    padding: 12,
    borderRadius: 16,
    overflowX: 'hidden',
  },
}));
