import React from 'react';
import { Text } from 'components';
import { Box } from '@material-ui/core';
import { Lightbulb } from 'assets';

import useStyles from './Hints.styles';
import { ProjectCreationTips } from 'components';

import { EmojiObjectsOutlined } from '@material-ui/icons';

type HintsProps = {
  type: number;
  task: number;
};

export const Hints = ({ type, task }: HintsProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mb="24px">
      <div className={classes.info}>
        <ul className={classes.listContainer}>
          <ProjectCreationTips task={task} type={type} />
        </ul>
      </div>
    </Box>
  );
};
