import React, { FunctionComponent, ReactNode, useState } from 'react';

import { GenericSimpleModal } from './GenericSimpleModal';
import { GenericSimpleModalProps } from 'types';

interface Props extends GenericSimpleModalProps {
  openModalButton: ReactNode;
  preventBackdropClose?: boolean;
}

export const UnControlledModal: FunctionComponent<Props> = ({
  children,
  size = 'md',
  openModalButton,
  preventBackdropClose,
}: Props) => {
  const [open, setOpen] = useState(false);

  const openModalHandler = () => {
    setOpen(true);
  };

  const closeHandler = (event?: any, reason?: any) => {
    if (reason === 'backdropClick' && preventBackdropClose) {
      return;
    }
    setOpen(false);
  };
  const isFunction = typeof children === 'function';

  return (
    <>
      {openModalButton && (
        <span onClick={openModalHandler}>{openModalButton}</span>
      )}

      <GenericSimpleModal size={size} closeHandler={closeHandler} isOpen={open}>
        {isFunction ? children({ closeHandler }) : children}
      </GenericSimpleModal>
    </>
  );
};
