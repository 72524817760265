import { Log } from 'types';

class FetchError extends Error {
  public statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
}

const getLastErrorLogMessage = (logs: Log[]) => {
  const errorLogs = logs.filter((log) => log.type === 2);

  if (errorLogs.length > 0) {
    return errorLogs[errorLogs.length - 1].message;
  }
  return '';
};

export { FetchError, getLastErrorLogMessage };
