import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  longSwitchContainer: {
    display: 'inline-flex',
    justifyContent: 'space-evenly',
    gap: '3px',
    backgroundColor: '#d8ddeb',
    alignItems: 'center',
    padding: '2px',
    borderRadius: '15px',
  },
  option: {
    width: '200px',
    height: '15px',
    borderRadius: '15px',
    backgroundColor: '#d8ddeb',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: '12px',
    },
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: 'white',
  },
  option1: {},
  option2: {},
  helperText: {
    color: '#ff6a61',
    fontFamily: 'Poppins',
    fontSize: '10px',
    fontWeight: 'bold',
    marginLeft: '8px',
  },
}));
