import React from 'react';
import { Dialog, Grid, IconButton, Typography } from '@material-ui/core';
import { ActionButton } from 'components/ActionButton';
import useStyles from './confirm-delete.styles';
import { TrashLogo } from 'assets';
import { IconClose } from 'assets';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
}

export const ConfirmDelete = ({ onClose, onConfirm, onCancel }: Props) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open
      classes={{
        paper: classes.paper,
      }}
    >
      <IconButton onClick={onClose} className={classes.close}>
        <IconClose />
      </IconButton>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
      >
        <TrashLogo />
        <Typography className={classes.title} variant="h3" align="center">
          Are you sure you want to delete this experiment?
        </Typography>
        <Typography className={classes.subtitle} variant="body1" align="center">
          This action is irreversible and you will no longer be able to
          integrate with its models. You will also lose its models and datasets.
        </Typography>
        <ActionButton
          className={classes.confirmButton}
          size="large"
          color="secondary"
          fullWidth
          variant="contained"
          onClick={onConfirm}
        >
          Yes, delete experiment
        </ActionButton>
        <ActionButton
          size="large"
          color="secondary"
          fullWidth
          variant="outlined"
          onClick={onCancel}
        >
          No, keep experiment
        </ActionButton>
      </Grid>
    </Dialog>
  );
};
