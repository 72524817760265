import React from 'react';
import { Typography, Modal } from '@material-ui/core';
import { IconClose, MessageIcon } from 'assets';
import useStyles from './verifyemailmodal.styles';

type VerifyEmailModalProps = {
  email: string;
  open: boolean;
  onClose(): void;
  onResend(): void;
  onLogout?(): void;
};

export const VerifyEmailModal = ({
  email,
  open,
  onClose,
  onResend,
  onLogout,
}: VerifyEmailModalProps) => {
  const classes = useStyles();

  return (
    <Modal open={open} className={classes.modalWrapper}>
      <div className={classes.container}>
        <div className={classes.headerElement}>
          <IconClose className={classes.closeIcon} onClick={onClose} />
        </div>
        <div className={classes.headerElementContrapart}></div>
        <div className={classes.content}>
          <MessageIcon />

          <Typography className={classes.title} variant="body1" align="center">
            Thank you!
          </Typography>

          <div className={classes.bodyText}>
            <Typography>
              In a few minutes, you will receive an email with the payment
              instructions.
            </Typography>
            <br />

            <Typography>
              Once the payment is confirmed your plan will be automatically
              upgraded.
            </Typography>
            <br />
            <Typography>
              If you didn't receive the email or got questions, please contact
              us at{' '}
              <span
                className={classes.link}
                onClick={() => {
                  window.location.href = `mailto:info@cogniflow.ai`;
                }}
              >
                support@cogniflow.ai
              </span>
            </Typography>
          </div>
        </div>
      </div>
    </Modal>
  );
};
