import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

interface SendButtonProps {
  disabled: boolean | undefined;
}

export default makeStyles(() => ({
  sendButton: ({ disabled }: SendButtonProps) => ({
    cursor: 'pointer',
    fill: disabled ? colors.blue15 : colors.orange,
    transition: 'fill 0.3s',
    pointerEvents: disabled ? 'none' : 'all',
  }),
}));
