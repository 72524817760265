import React from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { Box, LinearProgress } from '@material-ui/core';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import { Text } from 'components/Text';
import useStyles from './dropzone.styles';
import icoUser from 'assets/ico-user.svg';

export interface Props {
  percent: number;
  onDrop: DropzoneOptions['onDrop'];
  title?: string;
  subtitle?: string;
  error?: boolean;
  imgSrc?: string;
  dropzoneOptions?: DropzoneOptions;
}

export function AvatarDropzone({
  imgSrc,
  dropzoneOptions,
  onDrop,
  percent,
}: Props) {
  const classes = useStyles();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      const maxSize = 1 * 1024 * 1024;
      for (const file of acceptedFiles) {
        if (file.size > maxSize) {
          toast.warning(
            'The image must be smaller than 1MB. Please select a smaller image.',
            {
              position: toast.POSITION.TOP_CENTER,
              toastId: 'modal-toast',
              autoClose: 5000,
            }
          );
          return;
        }
      }
      onDrop(acceptedFiles);
    },
    noClick: !!imgSrc,
    ...dropzoneOptions,
  });

  return (
    <div {...getRootProps()} className={classes.dropzoneWrapper}>
      <Box position="relative">
        <Box
          className={classNames(classes.uploadIcon, {
            [classes.activeIcon]: isDragActive,
          })}
          style={{
            width: '100%',
            margin: 0,
            height: 120,
            boxSizing: 'border-box',
          }}
          display="flex"
          flexDirection="column"
          gridGap={6}
        >
          <Box style={{ width: 64, height: 64, borderRadius: '100%' }}>
            <img src={icoUser} style={{ maxWidth: '100%' }} alt="user icon" />
          </Box>
          <Text variant="paragraph1">
            Drag the image here or click to browse.
          </Text>
        </Box>
        {percent > 0 && percent < 100 && (
          <LinearProgress
            color="secondary"
            style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
          />
        )}
      </Box>
      <input {...getInputProps()} />
    </div>
  );
}
