import { useEffect } from 'react';

type useBeforeUnloadProps = {
  when: boolean;
  message: string;
};

export const useBeforeUnload = ({ when, message }: useBeforeUnloadProps) => {
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (when) {
        event.preventDefault();
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [when, message]);
};
