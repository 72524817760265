import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  messageContainer: {
    padding: '38px',
    borderRadius: 10,
    boxShadow: `0 8px 16px 0 rgba(72, 79, 121, 0.15)`,
    backgroundColor: '#FFFF',
  },
}));
