import { Dialog, Grid, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { IconClose } from 'assets';
import { StopLogo2 } from 'assets';
import { ActionButton } from 'components/ActionButton';
import useStyles from './errormessagemodal.styles';

interface Props {
  onClose(): void;
  onConfirm(): void;
  title: string;
  description: string;
  message?: string;
  open: boolean;
  buttonText: string;
}

export const ErrorMessageModal = ({
  title,
  onConfirm,
  description,
  message,
  onClose,
  buttonText,
  open,
}: Props) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      classes={{
        paper: classes.paper,
      }}
    >
      <IconButton onClick={onClose} className={classes.close}>
        <IconClose />
      </IconButton>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
      >
        <StopLogo2 />
        <Typography className={classes.title} variant="h3" align="center">
          {title}
        </Typography>
        <Typography className={classes.subtitle} variant="body1" align="center">
          {description}
        </Typography>
        {message && (
          <Typography
            className={classes.subtitle}
            variant="body1"
            align="center"
          >
            <b>{message}</b>
          </Typography>
        )}
        {buttonText && (
          <ActionButton
            className={classes.confirmButton}
            size="large"
            color="secondary"
            fullWidth
            variant="contained"
            onClick={onConfirm}
          >
            {buttonText}
          </ActionButton>
        )}
      </Grid>
    </Dialog>
  );
};
