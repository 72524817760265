import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Text } from 'components';

type StyleProps = {
  bg?: string;
  line?: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    background: 'transparent',
  },
  dotted: ({ line }: StyleProps) => ({
    position: 'absolute',
    width: '100%',
    top: '50%',
    transform: 'translateY(-50%)',

    backgroundImage:
      'linear-gradient(to right, gray 33%, rgba(255,255,255,0) 0%)',
    backgroundPosition: 'center',
    backgroundSize: line ? '1px 1px' : '5px 1px',
    backgroundRepeat: 'repeat-x',
    height: '5px',
  }),

  text: ({ bg }: StyleProps) => ({
    position: 'relative',
    zIndex: 1,
    backgroundColor: bg,
    display: 'block',
    margin: 'auto',
    width: '45px',
    fontSize: '12px',
    borderRadius: '4px',
  }),
}));

type HorizontalDottedLineProps = {
  text: string;
  line?: boolean;
  bg?: string;
};

export const HorizontalDivider = ({
  text,
  line = false,
  bg = 'white',
}: HorizontalDottedLineProps) => {
  const classes = useStyles({
    line,
    bg,
  });
  return (
    <Box className={classes.container}>
      <div className={classes.dotted}></div>
      <Text variant="paragraph1" className={classes.text}>
        {text}
      </Text>
    </Box>
  );
};
