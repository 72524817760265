import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  title: {
    textAlign: 'center',
    marginBottom: '36px',
  },

  formContainer: {
    position: 'relative',
    borderRadius: '4px',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },

  input: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      // borderColor: 'rgb(192, 192, 192)',
      boxShadow: '0 9px 16px 0 rgba(72, 79, 121, 0.15)',
    },
    '& label': {
      fontFamily: 'Poppins',
      fontSize: '15px',
    },
    '& input': {
      fontFamily: 'Poppins',
      fontSize: '14px',
    },
    width: '100%',
  },

  textarea: {
    fontFamily: 'Poppins',
    boxSizing: 'border-box',
    overflow: 'auto !important',
    fontSize: '15px',
    width: '100%',
    border: '1px solid #d8ddeb',
    borderRadius: '4px',
    '&:focus': {
      border: '1px solid #afb3bd',
      boxShadow: '0 9px 16px 0 rgba(72, 79, 121, 0.15)',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    padding: '12px 14px',
    '&::placeholder': {
      color: '#abafcc',
    },
  },

  helpIcon: {
    cursor: 'pointer',
  },
  helpText: {
    fontSize: '12px',
    marginTop: '4px',
    marginLeft: '14px',
    maxWidth: '342px',
  },
  helpContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  helpTitle: {
    color: 'white',
    textAlign: 'center',
  },

  helpDescription: {
    color: 'white',
    textAlign: 'justify',
    marginTop: '32px',
    lineHeight: 1.6,

    '& span': {
      display: 'block',
    },
  },
}));
