import React, { FC, ReactNode } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Text } from 'components';

import useStyles from './ChipButton.styles';

export interface ChipButtonProps {
  title: string;
  icon?: ReactNode;
  onClick?(): void;
}

export const ChipButton: FC<ChipButtonProps> = ({
  title,
  icon,
  onClick,
}: ChipButtonProps) => {
  const classes = useStyles();
  return (
    <Box onClick={onClick} className={classes.chipButton}>
      <Box>{icon}</Box>
      <Text variant="paragraph1">{title}</Text>
    </Box>
  );
};
