import { Overrides } from '@material-ui/core/styles/overrides';
import { colors } from '../colors';

export const OutlinedInput: Overrides['MuiOutlinedInput'] = {
  input: {
    paddingTop: 12,
    paddingBottom: 12,
    lineHeight: '1.5',
    backgroundColor: '#ffff',
  },
  notchedOutline: {
    borderColor: colors.blue15,
  },
};

export const InputLabel: Overrides['MuiInputLabel'] = {
  shrink: {
    color: colors.blue50,
  },
  outlined: {
    transform: 'translate(14px, 14px) scale(1)',
    color: colors.blue20,
  },
};

export const InputBase: Overrides['MuiInputBase'] = {
  root: {
    backgroundColor: '#ffff',
  },
};
