import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  chip: {
    padding: '5px',
    fontSize: '8px',
    backgroundColor: colors.lightGray,
    // color: colors.blue20,
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
}));
