import { stringify } from 'querystring';
import { ModelsQuery, ModelsResponse, Model } from 'types';
import { http } from './http';

export const getModels = (query: ModelsQuery): Promise<ModelsResponse> =>
  http.get(`model/?${stringify(query)}`);

export const getModel = (id: string): Promise<Model> => http.get(`model/${id}`);

export const deleteModel = (id: string): Promise<Model> =>
  http.delete(`model/${id}`);

export const toBase64File = (inputFile: File) => {
  // Always return a Promise
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(inputFile);
    // Wait till complete
    reader.onload = function () {
      resolve(reader.result);
    };
    // Make sure to handle error states
    reader.onerror = function (e: any) {
      reject(e);
    };
  });
};

export const convertBase64ToFile = function (image: string): Blob {
  const byteString = atob(image);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const newBlob = new Blob([ab], {
    type: 'image/jpeg',
  });
  return newBlob;
};
