import { Box, TableCell, TableRow } from '@material-ui/core';
import React, { FC } from 'react';
import { Experiment, PredictResponse } from 'types';

import { Table, Text } from 'components';
import { getTableData, removeQuotesFromString } from 'utils';

import { useRecoilState } from 'recoil';
import { currentExperimentState } from 'store/experiments';

import useStyles from './ExtractorResult.styles';
import { PencilOutlined } from 'assets';
import { EditEntitiesModal } from './EditEntitiesModal';
import { useAuthenticationContext } from 'context';

import { toast } from 'react-toastify';
export interface ExtractorResultProps {
  response: PredictResponse;
}

export const ExtractorResult: FC<ExtractorResultProps> = ({ response }) => {
  const data = getTableData(response);
  const classes = useStyles();
  const { user } = useAuthenticationContext();

  const [currentExperiment, setCurrentExperiment] = useRecoilState(
    currentExperimentState
  );

  const saveExperimentHandler = (experiment: Experiment) => {
    if (!currentExperiment) return;

    toast.success('Entities saved successfully', {
      position: toast.POSITION.TOP_CENTER,
      toastId: 'modal-toast',
    });

    setCurrentExperiment({
      ...currentExperiment,
      config: experiment.config,
    });
  };

  const isOwner = currentExperiment?.id_user === user?.id;

  return (
    <Box className={classes.tableWrapper}>
      <Table
        className={classes.tableContainer}
        headerOptions={
          <>
            <TableCell>
              <Text variant="h3" align="left" className={classes.headerTitle}>
                Entities
              </Text>
            </TableCell>
            <TableCell>
              {currentExperiment && isOwner && (
                <EditEntitiesModal
                  onSave={saveExperimentHandler}
                  experiment={currentExperiment}
                  button={
                    <Box className={classes.headerOptions}>
                      {
                        <>
                          <PencilOutlined />
                          <Text
                            variant="h3"
                            align="left"
                            className={classes.headerEditEntities}
                          >
                            Edit entities
                          </Text>
                        </>
                      }
                    </Box>
                  }
                />
              )}
            </TableCell>
          </>
        }
        cols={[
          <Text variant="paragraph1" align="center">
            <b>Entity name</b>
          </Text>,
          <Text variant="paragraph1" align="center">
            <b>Entity value</b>
          </Text>,
        ]}
        rows={data.map((entity, idx) => {
          const entityName = Object.keys(entity)[0];
          const value = Object.values(entity)[0];
          const entityValue =
            typeof value === 'string'
              ? removeQuotesFromString(value)
              : JSON.stringify(value, null, 2);

          return (
            <TableRow key={idx}>
              <TableCell align="center">
                <Text
                  variant="paragraph1"
                  style={{
                    // backgroundColor: entityColors[entityName as string],
                    borderRadius: '5px',
                    // color: isDark(entityColors[entityName as string] || '#fff')
                    //   ? 'white'
                    //   : 'black',
                    padding: '0 8px',
                    fontWeight: 'normal',
                  }}
                >
                  {entityName}
                </Text>
              </TableCell>
              <TableCell>
                <Text
                  variant="paragraph1"
                  align="justify"
                  style={{
                    whiteSpace: typeof value === 'string' ? 'normal' : 'pre',
                    fontWeight: 'normal',
                  }}
                >
                  {entityValue}
                </Text>
              </TableCell>
            </TableRow>
          );
        })}
      />
    </Box>
  );
};
