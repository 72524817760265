import { makeStyles } from '@material-ui/core';
import { PROJECT_TASK } from 'types';

type CardLayoutStylesProps = {
  projectTask: PROJECT_TASK;
};

const classifierBackgroundGradient =
  'linear-gradient(90deg, #66ADFF 0%, #2F8AF4 49.06%, #117AF3 100%)';

const backgroundGradient: Record<number, string> = {
  [PROJECT_TASK.CHATBOT]:
    'linear-gradient(90deg, #AE2DFC 0%, #7D2FF4 49.06%, #3C33E8 100%)',
  [PROJECT_TASK.EXTRACTOR]:
    'linear-gradient(160deg, #FE987F -9.73%, #CB457C 52.58%, #ED3D85 86.74%);',
  [PROJECT_TASK.CLASSIFIER_TEXT]: classifierBackgroundGradient,
  [PROJECT_TASK.CLASSIFIER_IMAGE]: classifierBackgroundGradient,
  [PROJECT_TASK.CLASSIFIER_AUDIO]: classifierBackgroundGradient,
  [PROJECT_TASK.OBJECT_DETECTION]:
    'linear-gradient(90deg, #7ADE9C 0%, #4DB39B 45.42%, #2F9079 100%)',
};

const imageBackgroundGradient: Record<number, string> = {
  [PROJECT_TASK.CHATBOT]:
    'linear-gradient(0deg, #AE2DFC 0%, rgba(174, 45, 252, 0.77) 0.01%, #7D2FF4 21.06%, rgba(60, 51, 232, 0.48) 100%)',
  [PROJECT_TASK.EXTRACTOR]:
    'linear-gradient(0deg, rgba(254, 152, 127, 0.87) 6.2%, rgba(226, 94, 148, 0.86) 42.51%, rgba(226, 93, 147, 0.86) 62.42%)',
};

export default makeStyles(() => ({
  wrapper: {
    overflow: 'hidden',
    borderRadius: '24px',
    width: '305px',
    height: '335px',
    position: 'relative',
    boxShadow: '0px 16px 24px 0px rgba(72, 79, 121, 0.15)',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  background: ({ projectTask }: CardLayoutStylesProps) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    background: backgroundGradient[projectTask],
    width: '100%',
    height: '100%',
    borderRadius: '24px',
    transform: 'rotate(180deg)',
    zIndex: 1,
  }),
  image: ({ projectTask }: CardLayoutStylesProps) => {
    const isChatbot = projectTask === PROJECT_TASK.CHATBOT;

    const imgWidth: Record<number, string> = {
      [PROJECT_TASK.CHATBOT]: '100%',
      [PROJECT_TASK.EXTRACTOR]: 'auto',
      [PROJECT_TASK.CLASSIFIER_AUDIO]: '100%',
      [PROJECT_TASK.CLASSIFIER_IMAGE]: '100%',
      [PROJECT_TASK.CLASSIFIER_TEXT]: '100%',
      [PROJECT_TASK.OBJECT_DETECTION]: '80%',
    };

    const imgHeight: Record<number, string> = {
      [PROJECT_TASK.CHATBOT]: '100%',
      [PROJECT_TASK.EXTRACTOR]: 'auto',
      [PROJECT_TASK.CLASSIFIER_AUDIO]: 'auto',
      [PROJECT_TASK.CLASSIFIER_IMAGE]: 'auto',
      [PROJECT_TASK.CLASSIFIER_TEXT]: 'auto',
      [PROJECT_TASK.OBJECT_DETECTION]: 'auto',
    };

    const borderRadius = isChatbot
      ? {
          borderBottomRightRadius: '24px',
          borderTopRightRadius: '24px',
        }
      : {};

    return {
      zIndex: 2,
      position: 'absolute',
      top: '0',
      right: '0',
      width: '50%',
      height: '100%',

      '& img': {
        width: imgWidth[projectTask],
        height: imgHeight[projectTask],
        position: 'absolute',
        right: '0',
        top: '0',
        ...borderRadius,
      },
    };
  },

  imgBg: ({ projectTask }: CardLayoutStylesProps) => {
    const chatbotCustomStyle = {
      width: '100%',
      height: '100%',
      borderBottomRightRadius: '24px',
      borderTopRightRadius: '24px',
    };

    const customStyle = {
      width: '144.1px',
      height: '216px',
      borderBottomLeftRadius: '12px',
    };

    const style =
      projectTask === PROJECT_TASK.CHATBOT ? chatbotCustomStyle : customStyle;

    const hasImageGradient =
      projectTask === PROJECT_TASK.CHATBOT ||
      projectTask === PROJECT_TASK.EXTRACTOR;

    return {
      position: 'absolute',
      top: 0,
      right: 0,
      background: hasImageGradient
        ? imageBackgroundGradient[projectTask]
        : 'none',
      ...style,
    };
  },

  content: {
    height: '100%',
    position: 'relative',
    zIndex: 2,
    padding: '32px 24px',
    boxSizing: 'border-box',
  },

  ellipse: {
    position: 'absolute',
    top: '65px',
    left: 0,
    zIndex: 2,
  },
}));
