import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(({ colors, breakpoints }) => ({
  container: {
    height: '74px',
    borderBottom: `1px solid ${colors.blue15}`,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  logo: {
    width: 250,
    [breakpoints.down('sm')]: {
      width: 240,
    },
  },
  accountInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '32px',
    position: 'relative',
  },

  caption: {
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
  },
  plan: {
    color: 'white',
  },
  planInfoContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'start',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  usageChip: {
    borderRadius: '5px',
    fontSize: '8px',
    padding: '0',
    height: '20px',
  },
}));
