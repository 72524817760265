import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useState,
} from 'react';
import { ExpConfig, ExperimentValues } from 'types';

type Payload = {
  [key: string]: string | number | undefined | ExpConfig | boolean;
};

const initialExperimentValues: ExperimentValues = {
  title: '',
  description: '',
  train_url: '',
  validation_url: '',
  type: 0,
  config: {},
};

type ExperimentCreationContextProps = {
  expValues: ExperimentValues;
  setExpProps(payload: Payload): void;
  resetExperimentValues(): void;
};

const ExperimentCreationContext = createContext<ExperimentCreationContextProps>(
  {
    expValues: initialExperimentValues,
    setExpProps: () => {},
    resetExperimentValues: () => {},
  }
);

export const useExpCreationContext = () =>
  useContext(ExperimentCreationContext);

export const ExperimentCreationProvider: FC<{}> = ({ children }) => {
  const [expValues, setExpValues] = useState<ExperimentValues>(
    initialExperimentValues
  );

  const setExpProps = useCallback((payload: Payload) => {
    setExpValues((s) => ({ ...s, ...payload }));
  }, []);

  const resetExperimentValues = useCallback(() => {
    setExpValues(initialExperimentValues);
  }, []);

  return (
    <ExperimentCreationContext.Provider
      value={{
        expValues,
        setExpProps,
        resetExperimentValues,
      }}
    >
      {children}
    </ExperimentCreationContext.Provider>
  );
};
