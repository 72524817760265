import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  info: {
    width: '100%',
    padding: '24px 12px',
    backgroundColor: colors.blue10,
    position: 'relative',
    boxSizing: 'border-box',
  },
  img: {
    position: 'absolute',
    left: 0,
    top: -42,
  },
  infoTitle: {
    marginLeft: 40,
  },
  listContainer: {
    textAlign: 'left',
    '& span': {
      fontFamily: 'Poppins',
    },
  },
}));
