import React from 'react';
import { Tooltip } from '@material-ui/core';
import useStyles from './chiptag.styles';

interface ChipTagProps {
  text?: string;
  tag: string;
  tooltip?: string | undefined;
  color?: string;
}

const TAG_COLORS: { [key: string]: string } = {
  CARDINAL: 'pink',
  DATE: 'red',
  EVENT: 'green',
  FAC: 'purple',
  GPE: 'orange',
  LANGUAGE: 'pink',
  LAW: 'red',
  LOC: 'green',
  MONEY: 'purple',
  NORP: 'orange',
  ORDINAL: 'pink',
  ORG: 'red',
  PERCENT: 'green',
  PERSON: 'purple',
  PRODUCT: 'orange',
  QUANTITY: 'pink',
  TIME: 'red',
  WORK_OF_ART: 'green',
};

export const ChipTag = ({ text, tag, tooltip, color }: ChipTagProps) => {
  const classes = useStyles({
    tagColor: color || TAG_COLORS[tag],
    color,
  });

  return (
    <span className={classes.chipTagWrapper}>
      {text && <span className={classes.chipText}>{text}</span>}
      {tag && (
        <Tooltip title={tooltip || ''}>
          <span className={classes.chip}>{tag}</span>
        </Tooltip>
      )}
    </span>
  );
};
