import React from 'react';
import { Text } from 'components';
import { Box } from '@material-ui/core';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import classnames from 'classnames';

import { UploadIcon } from 'assets';
import useStyles from './DragAndDrop.styles';
import { PROJECT_TASK } from 'types';

export interface DragAndDropProps {
  onDrop: DropzoneOptions['onDrop'];
  accept?: string;
  task?: number;
}

export const DragAndDrop = ({ onDrop, accept, task }: DragAndDropProps) => {
  const classes = useStyles();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept || '',
  });

  const maxFileSizeTextMap: Record<number, string> = {
    [PROJECT_TASK.CHATBOT]: '100MB',
  };

  const maxFileSizeText = maxFileSizeTextMap[task || 0] || '2GB';

  return (
    <Box>
      <div
        className={classnames({
          [classes.dragAndDrop]: true,
          [classes.dragging]: isDragActive,
        })}
        {...getRootProps()}
      >
        <Box>
          <UploadIcon className={classes.dragAndDropIcon}></UploadIcon>

          <Box mt="12px" display="flex" flexDirection="column" gridGap="8px">
            <Text variant="paragraph1" className={classes.text}>
              Drag and Drop your documents here
            </Text>

            <Text variant="paragraph1" className={classes.fileSelector}>
              {PROJECT_TASK.CHATBOT === task ? 'Choose files' : 'Choose a file'}
            </Text>
          </Box>
        </Box>
      </div>

      <Box mt="4px" display="flex" justifyContent="space-between">
        <Text variant="paragraph1" className={classes.infoText}>
          Supported formats: {accept}
        </Text>
        <Text variant="paragraph1" className={classes.infoText}>
          Max file size: {maxFileSizeText}
        </Text>
      </Box>
      <input {...getInputProps()} />
    </Box>
  );
};
