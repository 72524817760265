import React, {
  useState,
  ChangeEvent,
  ReactNode,
  useMemo,
  useEffect,
} from 'react';
import {
  Box,
  Select,
  Typography,
  Button,
  Tooltip,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { HelpIcon } from 'assets';
import useStyles from './editcsvcolumnmodal.styles';
import { ControlledModal } from 'components/GenericSimpleModal/ControlledModal';
import { SimpleMessage } from 'components';

type EditColumnsModalProps = {
  options: string[];
  open: boolean;
  closeHandler(): void;
  onSaveHandler(selectedCols: { text: string; label: string }): void;
};

export const EditCsvColumnModal = ({
  options,
  open,
  closeHandler,
  onSaveHandler,
}: EditColumnsModalProps) => {
  const classes = useStyles();

  const [selected, setSelected] = useState({
    text: '',
    label: '',
  });

  const [touched, setTouched] = useState(false);

  const handleChange = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: ReactNode
  ) => {
    setTouched(true);
    setSelected((s) => ({
      ...s,
      [e.target.name || '']: (e.target.value as string).replace(
        /\r\n|\n|\r/,
        ''
      ),
    }));
  };

  const handleSaveCols = () => {
    if (isInvalidSelection && selected.label !== '' && selected.text !== '')
      return;
    onSaveHandler({
      text: selected.text || 'Text',
      label: selected.label || 'Label',
    });
    resetValues();
  };

  const isInvalidSelection = useMemo(() => selected.text === selected.label, [
    selected,
  ]);

  const emptyValue = useMemo(() => !selected.text || !selected.label, [
    selected,
  ]);

  const resetValues = () => {
    setSelected({
      text: '',
      label: '',
    });
    setTouched(false);
  };

  const onCloseModal = () => {
    resetValues();
    closeHandler();
  };

  useEffect(() => {
    options.includes('Text') && setSelected((s) => ({ ...s, text: 'Text' }));
    options.includes('Label') && setSelected((s) => ({ ...s, label: 'Label' }));
  }, [options]);

  return (
    <ControlledModal isOpen={open} closeHandler={onCloseModal} size="sm">
      <Typography variant="h3" className={classes.title}>
        Select columns
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.dropdownsSection}
      >
        <Box display="flex" alignItems="center">
          <Typography>Text:</Typography>
          <Tooltip title="Text column indicates which column contains the text entries that Cogniflow will use for training.">
            <HelpIcon />
          </Tooltip>
        </Box>
        <FormControl variant="filled">
          <InputLabel id="text-select" className={classes.label}>
            Select column for texts
          </InputLabel>
          <Select
            name="text"
            labelId="text-select"
            value={selected.text}
            onChange={handleChange}
            className={classes.dropdown}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.dropdownsSection}
      >
        <Box display="flex" alignItems="center">
          <Typography>Label:</Typography>
          <Tooltip title="Label column indicates which column contains the categories/classes that Cogniflow will assign to the text entries during training.">
            <HelpIcon />
          </Tooltip>
        </Box>

        <FormControl variant="filled">
          <InputLabel id="label-select" className={classes.label}>
            Select column for Labels
          </InputLabel>
          <Select
            name="label"
            labelId="label-select"
            className={classes.dropdown}
            value={selected.label}
            onChange={handleChange}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {touched && isInvalidSelection && (
        <Box className={classes.error}>
          <SimpleMessage type="error" icon={true}>
            "Text" and "Label" columns must be different
          </SimpleMessage>
        </Box>
      )}

      <Box display="flex" justifyContent="flex-end">
        <Button
          id="save-new-columns"
          size="large"
          color="secondary"
          variant="contained"
          onClick={handleSaveCols}
          disabled={(touched && isInvalidSelection) || emptyValue}
        >
          Save
        </Button>
      </Box>
    </ControlledModal>
  );
};
