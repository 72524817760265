export const twoDecimalsRound = (num: number) => Math.round(num * 100) / 100;

export const twoDecimalsFixed = (num: number) =>
  (num.toString().match(/^-?\d+(?:\.\d{0,2})?/) ?? [0])[0];

type PointInsideRectProps = {
  rectX: number;
  rectY: number;
  rectW: number;
  rectH: number;
  pointX: number;
  pointY: number;
};

export const rectContainsPoint = ({
  rectX,
  rectY,
  rectW,
  rectH,
  pointX,
  pointY,
}: PointInsideRectProps) =>
  pointX >= rectX &&
  pointX <= rectW + rectX &&
  pointY >= rectY &&
  pointY <= rectH + rectY;
