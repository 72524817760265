import { getEnv } from './env';
import { FetchError } from 'utils/errors';

type fetchFn = <T>(url: string, body?: any, headers?: object) => Promise<T>;

interface IHttp {
  baseUrl: string;
  headers: object;
  get: fetchFn;
  post: fetchFn;
  put: fetchFn;
  delete: fetchFn;
  patch: fetchFn;
}

class Http implements IHttp {
  baseUrl: string;
  headers: object;

  constructor(baseUrl: string) {
    if (!baseUrl) {
      throw new Error('No api url specified for build');
    }
    this.baseUrl = baseUrl;
    this.headers = {
      'Content-Type': 'application/json',
    };
  }

  private fetch(method: string): fetchFn {
    return (url: string, body?: any, headers?: object) => {
      return fetch(`${this.baseUrl}/${url}`, {
        method,
        headers: {
          ...this.headers,
          ...(headers ?? {}),
        },
        body,
      }).then((response) =>
        response.json().then((result) => {
          if (response.ok) {
            return result;
          }

          throw new FetchError(
            result.detail || result.message,
            response.status
          );
        })
      );
    };
  }

  setToken(token: string) {
    this.headers = {
      ...this.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  post = this.fetch('POST');

  get = this.fetch('GET');

  put = this.fetch('PUT');

  delete = this.fetch('DELETE');

  patch = this.fetch('PATCH');
}

const REACT_APP_API_URL = getEnv('REACT_APP_API_URL');
const REACT_APP_PREDICT_URL = getEnv('REACT_APP_PREDICT_URL');

export const http = new Http(REACT_APP_API_URL as string);
export const httpPredict = new Http(REACT_APP_PREDICT_URL as string);
export const httpGoogle = new Http('https://www.googleapis.com' as string);
