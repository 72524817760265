import React, { useCallback, useState, useEffect } from 'react';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/Camera';
import CameraFrontIcon from '@material-ui/icons/CameraFront';
import CamerarRearIcon from '@material-ui/icons/CameraRear';
import { GenericModalState } from '../GenericModal/GenericModal';
import { atom, useSetRecoilState } from 'recoil';
import Webcam from 'react-webcam';
import useStyles from './capturecamera.styles';

export const snapshotState = atom<string | null>({
  key: 'snapshotStateImage',
  default: null,
});

type WebCameraProps = {
  onCapturePic(base64Pic: string): void;
};

export const WebCamera = ({ onCapturePic }: WebCameraProps) => {
  const classes = useStyles();
  const [webcam, setWebcam] = useState<Webcam | null>(null);
  const setTogelModal = useSetRecoilState(GenericModalState);
  const [devices, setDevices] = useState<MediaDeviceInfo[] | null>([]);
  const [isCameraReady, setCameraStatus] = useState(false);
  const [isFront, setFron] = useState(true);
  const [constrain, setConstrain] = useState({
    width: 1000,
    facingMode: 'user',
    height: 720,
  });

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((res) => {
        const found = res.filter((item) => item.kind === 'videoinput');
        console.log(found);
        setDevices(found);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setDevices]);

  const handleCapture = useCallback(() => {
    const capturedBase64 =
      webcam?.getScreenshot({ width: 1000, height: 720 }) || '';

    onCapturePic(capturedBase64);
    setTogelModal(false);
  }, [webcam, setTogelModal, onCapturePic]);

  const videoRef = useCallback(
    (node) => {
      setWebcam(node);
    },
    [setWebcam]
  );

  const handleError = useCallback((error) => {
    console.log(error);
  }, []);

  const handleUserMedia = useCallback(() => setCameraStatus(true), [
    setCameraStatus,
  ]);

  const handleFront = useCallback(() => {
    if (devices && devices?.length > 0) {
      setConstrain((prev) => ({
        ...prev,
        facingMode: 'user',
      }));
      setFron(true);
    }
  }, [devices, setConstrain]);

  const handleBack = useCallback(() => {
    if (devices && devices?.length > 0) {
      setConstrain((prev) => ({
        ...prev,
        facingMode: 'environment',
      }));
      setFron(false);
    }
  }, [devices, setConstrain]);

  return (
    <Grid container direction="column">
      <>
        {isCameraReady || (
          <Grid className={classes.cameraWarning} container justify="center">
            <Grid
              container
              direction="column"
              justify="center"
              alignContent="center"
            >
              <Grid container justify="center" alignItems="center">
                <IconButton value="camera-front">
                  <CameraFrontIcon />
                </IconButton>
                <Typography>Grant permission to camera</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Webcam
          className={
            isCameraReady ? classes.captureCameraOn : classes.captureCameraOff
          }
          audio={false}
          height={'100%'}
          ref={videoRef}
          screenshotFormat="image/jpeg"
          width={'100%'}
          style={{ margin: '0' }}
          onUserMedia={handleUserMedia}
          onUserMediaError={handleError}
          videoConstraints={constrain}
        />
      </>

      <Grid container justify="center">
        <Tooltip title="Click to take a picture">
          <IconButton value="camera-iris" onClick={handleCapture}>
            <CameraIcon color="secondary" fontSize="large" />
          </IconButton>
        </Tooltip>

        {devices && devices.length > 1 ? (
          <>
            {isFront && (
              <IconButton onClick={handleBack} value="camera-front">
                <CamerarRearIcon />
              </IconButton>
            )}
            {!isFront && (
              <IconButton onClick={handleFront} value="camera-front">
                <CameraFrontIcon />
              </IconButton>
            )}
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};
