import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles((theme) => ({
  dropzoneContainer: {
    position: 'relative',
    height: 60,
    cursor: 'pointer',
  },
  progressBar: {
    position: 'absolute',
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'none',
    },
  },
  imgPreview: {
    height: 64,
    width: 64,
    display: 'inline-block',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  imgPreviewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 88,
    width: 88,
    borderRadius: 8,
    boxShadow: '0 8px 16px 0 rgba(72, 79, 121, 0.15)',
  },
  uploadIcon: {
    width: 72,
    height: 72,
    border: `2px dashed ${colors.blue15}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    margin: 8,
    backgroundColor: '#ffff',
  },
  activeIcon: {
    border: `2px dashed ${colors.orange}`,
  },
  loadingContainer: {
    width: 72,
    height: 72,
    margin: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  uploadIconImage: {
    width: 32,
  },
  text: {
    marginLeft: 32,
  },
  dropzoneWrapper: {
    cursor: 'pointer',
    paddingRight: 0,
    '&:focus': {
      outline: 'none',
    },
  },
  backgroundProgress: {
    position: 'absolute',
    height: '100%',
    width: 0,
    backgroundColor: colors.blue70,
  },
  background: {
    height: 128,
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: 5,
    border: `2px dashed ${colors.blue15}`,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      outline: 'none',
    },
    backgroundColor: '#ffff',
    cursor: 'pointer',
  },
  backgroundOnDrag: {
    border: `2px dashed ${colors.orange}`,
  },
  backgroundActive: {
    backgroundColor: colors.blue50,
    border: 'none',
  },
  content: {
    width: '100%',
  },
  filename: {
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  uploadMessage: {
    color: colors.orange,
    textAlign: 'left',
  },
  uploading: {
    zIndex: 2,
  },
  progressLabel: {
    color: '#fff',
    fontWeight: 'bold',
  },
  imageContainerResult: {
    backgroundColor: '#FFFF',
    /*  paddingLeft:20,
    paddingRight:20*/
  },
  imageCover: {
    margin: 0,
  },
  dragDropTitle: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: colors.blue20,
  },
  deleteDatasetIcon: {},
  backgroundDefault: {
    position: 'absolute',
    height: '100%',
    width: 0,
  },

  dropzone: {
    padding: '0 20px',
  },
}));
