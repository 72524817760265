import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core';
import { IconClose } from 'assets';
import { GenericSimpleModalProps } from 'types';
import useStyles from './genericsimplemodal.styles';

export const GenericSimpleModal = ({
  children,
  size,
  isOpen = false,
  closeHandler,
}: GenericSimpleModalProps) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={closeHandler}
      maxWidth={size}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      classes={{
        paper: classes.paper,
      }}
    >
      <Grid
        container
        item
        direction="column"
        className={classes.modalContainer}
      >
        <DialogTitle className={classes.modalTitle}>
          <Grid container item sm={12} md={12} justify="flex-end">
            <IconButton aria-label="close" onClick={closeHandler}>
              <IconClose />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.customRoot,
          }}
          className={classes.dialogContent}
        >
          {children}
        </DialogContent>
      </Grid>
    </Dialog>
  );
};
