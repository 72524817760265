export enum PlAN_IDS {
  GUEST = '00bfba11-1122-3190-abaf-ab9040d21301',
  FREE = 'eeffaabb-ae29-4992-bb8f-139448dd9d02',
  FREE_TRIAL = 'c5f5c304-5d78-4107-8bf9-4456c204039b',
  STARTER = '5b98aae4-2b11-4577-8a5c-d0c3a78eaa79',
  PERSONAL = 'd1aac1db-ae00-4101-120f-136446d09bca',
  PROFESSIONAL = 'abffbacb-de91-4002-bb1f-233441dd9201',
  TEAM = 'd1c9827b-1a2d-4a06-bdf4-f8e1f958caf0',
  BUSINESS = '20caf3eb-3515-4024-b7ac-38711720aaed',
  ENTERPRISE = 'cbfcb0cb-1e9c-200d-db1d-133141cd9001',
}

export const planIndex: { [key: string]: number } = {
  [PlAN_IDS.FREE]: 0,
  [PlAN_IDS.FREE_TRIAL]: 1,
  [PlAN_IDS.STARTER]: 2,
  [PlAN_IDS.PERSONAL]: 3,
  [PlAN_IDS.PROFESSIONAL]: 4,
  [PlAN_IDS.TEAM]: 5,
  [PlAN_IDS.BUSINESS]: 6,
  [PlAN_IDS.ENTERPRISE]: 7,
};

export const planList = Object.keys(planIndex);

export type PlanData = {
  id: string;
  planName: string;
  description: string;
  price: number;
  discountPercentage?: number;
  period: string;
  actionButtonText: string;
  properties: string[];
  hasActionButton?: boolean;
  isSpecial?: boolean;
  credits?: string;
  additionalPredictionText?: string[];
  trainingHours?: number;
  users?: number | string;
};
