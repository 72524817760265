import React, { ReactNode, useState } from 'react';
import { Extractor, Text, UnControlledModal } from 'components';
import { EntityProps, Experiment } from 'types';

import useStyles from './EditEntitiesModal.style';

import { Box, Button } from '@material-ui/core';
import { updateExperimentConfig } from 'services';

import { ToastContainer, toast } from 'react-toastify';

type EditEntitiesModalProps = {
  button: ReactNode;
  experiment: Experiment;
  onSave: (experiment: Experiment) => void;
};

export const EditEntitiesModal = ({
  button,
  experiment,
  onSave,
}: EditEntitiesModalProps) => {
  const classes = useStyles();

  const [entities, setEntities] = useState<EntityProps[]>(
    experiment.config.entities as EntityProps[]
  );

  const saveConfig = (cb: any) => {
    updateExperimentConfig(experiment.id, {
      ...experiment.config,
      entities,
    })
      .then((res) => {
        cb();
        onSave(res);
      })
      .catch((err) => {
        let message = 'Error saving config';

        switch (err.statusCode) {
          case 400:
            message = 'Request is invalid';
            break;
          case 401:
            message = 'You are not authorized to perform this action';
            break;
          case 404:
            message =
              'You can not edit an experiment that does not belong to you';
            break;
        }
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
          toastId: 'modal-toast',
        });
      });
  };

  const handleExtractorChange = (entities: EntityProps[]) => {
    setEntities(entities);
  };

  return (
    <UnControlledModal
      openModalButton={button}
      size="md"
      preventBackdropClose={true}
    >
      {({ closeHandler }) => (
        <>
          <Box mb="18px">
            <Text variant="h3" align="center">
              Edit Entities
            </Text>
          </Box>

          <ToastContainer autoClose={2000} />

          <Box className={classes.extractorWrapper}>
            <Extractor
              key="modal-extractor"
              onEntitiesChange={handleExtractorChange}
              initialEntities={experiment.config.entities as EntityProps[]}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              size="large"
              color="secondary"
              variant="contained"
              className={classes.saveButton}
              onClick={() => {
                saveConfig(closeHandler);
              }}
            >
              Save
            </Button>
          </Box>
        </>
      )}
    </UnControlledModal>
  );
};
