import React, { forwardRef } from 'react';
import cn from 'classnames';
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Box,
} from '@material-ui/core';
import { SearchIcon } from 'assets';
import useStyles from './SearchInput.styles';
import { Text } from 'components';

export interface SearchInputProps extends React.HTMLProps<HTMLElement> {
  inputClassName?: string;
  placeholder: string;
  isStatic?: boolean;
  onSearch: (search: string) => void;
}

export const SearchInput = forwardRef(
  (
    {
      className,
      inputClassName,
      onClick,
      isStatic = false,
      placeholder,
    }: SearchInputProps,
    ref
  ) => {
    const classes = useStyles();

    return isStatic ? (
      <Box className={classes.inputButton} onClick={onClick}>
        <Box className={classes.inputText}>
          <Text variant="paragraph1">{placeholder}</Text>
        </Box>
        <Box className={classes.inputIcon}>
          <SearchIcon />
        </Box>
      </Box>
    ) : (
      <form className={className}>
        <FormControl className={classes.textContainer}>
          <OutlinedInput
            placeholder="Search for a project"
            onClick={onClick}
            className={cn(classes.textField, inputClassName)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton type="submit">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            inputProps={{
              'aria-label': 'search',
              maxLength: 254,
              ref,
            }}
          />
        </FormControl>
      </form>
    );
  }
);
