import React, { ReactNode } from 'react';
import { Dialog, Grid, DialogContent, Box } from '@material-ui/core';
import useStyles from './confirmvisibility.styles';
import { ActionButton } from 'components/ActionButton';

type ConfirmVisibilityProps = {
  currentVisibility: number | undefined;
  open: boolean;
  company: string;
  handleClose(): void;
  confirmHandler(): void;
  cancelHandler(): void;
};

const getLabel = (organization?: string): { [key: number]: ReactNode } => ({
  0: 'If you change the visibility to "Private", all users will lose access to the experiment except you. Do you want to continue?',
  2: (
    <>
      If you change the visibility to "Organization" any user in the
      organization <b>{organization}</b> will get access to the experiment. Do
      you want to continue?
    </>
  ),
  3: 'If you change the visibility to "Community" any user in the platform will get access to the experiment. Do you want to continue?',
});

export const ConfirmVisibility = ({
  open,
  currentVisibility,
  company,
  handleClose,
  confirmHandler,
  cancelHandler,
}: ConfirmVisibilityProps) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={handleClose}
      maxWidth="xs"
      aria-labelledby="customized-dialog-title"
      open={open}
      classes={{
        paper: classes.paper,
      }}
    >
      <Grid
        container
        item
        direction="column"
        className={classes.modalContainer}
      >
        <Box display="flex" justifyContent="center">
          <img
            src="https://static.userguiding.com/ext-assets/1651176536305_group_14_2x.png"
            width={200}
            alt="company"
          ></img>
        </Box>
        <DialogContent
          classes={{
            root: classes.customRoot,
          }}
          className={classes.dialogContent}
        >
          {getLabel(company)[currentVisibility as number]}
        </DialogContent>
      </Grid>
      <Grid className={classes.actionButtons}>
        <ActionButton
          size="large"
          variant="contained"
          color="secondary"
          onClick={confirmHandler}
        >
          Continue
        </ActionButton>
        <ActionButton
          size="large"
          variant="outlined"
          color="secondary"
          onClick={cancelHandler}
        >
          Cancel
        </ActionButton>
      </Grid>
    </Dialog>
  );
};
