/**
 * TODO
 * Implement properly plan time period tab change
 */

import { PlAN_IDS } from 'types';

export type PlanData = {
  id?: string;
  planName: string;
  description: string;
  price: number;
  discountPercentage?: number;
  period: string;
  actionButtonText: string;
  properties: string[];
  hasActionButton?: boolean;
  isDark?: boolean;
  additionalPredictionText: string[];
};

export const plansInfo: { [key: number]: PlanData[] } = {
  0: [
    {
      id: 'eeffaabb-ae29-4992-bb8f-139448dd9d02',
      planName: 'Free',
      description: 'Learn AI or validate Cogniflow',
      price: 0,
      period: 'free',
      actionButtonText: 'Upgrade',
      hasActionButton: false,
      properties: [
        '50 predictions/day',
        '5 training/month (*)',
        '1 user',
        'Non-commercial use',
      ],
      additionalPredictionText: ['(*) up to 10 training hours/month'],
    },
    {
      id: 'd1aac1db-ae00-4101-120f-136446d09bca',
      planName: 'Personal',
      description: 'Start your first real AI products',
      price: 40,
      period: 'per month | billed annually (USD)',
      hasActionButton: true,
      actionButtonText: 'Upgrade',
      properties: [
        '5,000 predictions/month',
        '10 trainings/month (*)',
        '1 user',
        'email support',
        'commercial use',
      ],
      additionalPredictionText: ['(*) up to 25 training hours/month'],
    },
    {
      id: 'abffbacb-de91-4002-bb1f-233441dd9201',
      planName: 'Professional',
      description: 'Scaling AI, most advanced',
      price: 200,
      period: 'per month | billed annually (USD)',
      actionButtonText: 'Upgrade',
      hasActionButton: true,
      properties: [
        '50,000 predictions/month (*)',
        '50 trainings/month (**)',
        'up to 5 users for labeling',
        'premium suppport',
        'commercial use',
        'object detection',
      ],
      isDark: true,
      additionalPredictionText: [
        '(*) Additional prediction at $0.002',
        '(**) up to 150 training hours/month',
        'additional hour at $2',
      ],
    },
    {
      id: 'cbfcb0cb-1e9c-200d-db1d-133141cd9001',
      planName: 'Enterprise',
      description:
        'Large volume, more collaboration features, more performance',
      price: 0,
      period: '',
      actionButtonText: 'Contact us',
      hasActionButton: true,
      properties: [
        'Volume discounts',
        'unlimited users',
        'active learning',
        'premium support',
        'commercial use',
        'object detection',
        'dedicated onboarding',
        'consultancy',
      ],
      additionalPredictionText: [],
    },
  ],
  1: [
    {
      id: 'eeffaabb-ae29-4992-bb8f-139448dd9d02',
      planName: 'Free',
      description: 'Learn AI or validate Cogniflow',
      price: 0,
      period: 'free',
      actionButtonText: 'Upgrade',
      hasActionButton: false,
      properties: [
        '50 predictions/day',
        '5 training/month (*)',
        '1 user',
        'Non-commercial use',
      ],
      additionalPredictionText: ['(*) up to 10 training hours/month'],
    },
    {
      id: 'd1aac1db-ae00-4101-120f-136446d09bca',
      planName: 'Personal',
      description: 'Start your first real AI products',
      price: 50,
      period: 'per month | billed monthly (USD)',
      hasActionButton: true,
      actionButtonText: 'Upgrade',
      properties: [
        '5,000 predictions/month',
        '10 trainings/month (*)',
        '1 user',
        'email support',
        'commercial use',
      ],
      additionalPredictionText: ['(*) up to 25 training hours/month'],
    },
    {
      id: 'abffbacb-de91-4002-bb1f-233441dd9201',
      planName: 'Professional',
      description: 'Scaling AI, most advanced',
      price: 250,
      period: 'per month | billed monthly (USD)',
      actionButtonText: 'Upgrade',
      hasActionButton: true,
      properties: [
        '50,000 predictions/month (*)',
        '50 trainings/month (**)',
        'up to 5 users for labeling',
        'premium suppport',
        'commercial use',
        'object detection',
      ],
      isDark: true,
      additionalPredictionText: [
        '(*) Additional prediction at $0.002',
        '(**) up to 150 training hours/month',
        'additional hour at $2',
      ],
    },
    {
      id: 'cbfcb0cb-1e9c-200d-db1d-133141cd9001',
      planName: 'Enterprise',
      description:
        'Large volume, more collaboration features, more performance',
      price: 0,
      period: '',
      actionButtonText: 'Contact us',
      hasActionButton: true,
      properties: [
        'Volume discounts',
        'unlimited users',
        'active learning',
        'premium support',
        'commercial use',
        'object detection',
        'dedicated onboarding',
        'consultancy',
      ],
      additionalPredictionText: [],
    },
  ],
};

interface PromotionalPlansInfoProps {
  [key: string]: (params: any) => PlanData;
}

export const promotionalPlansInfo: PromotionalPlansInfoProps = {
  [PlAN_IDS.FREE_TRIAL]: ({
    id,
    limit_prediction_count,
    limit_prediction_period,
    limit_training_hours,
    limit_training_period,
    default_expiration_days,
  }) => ({
    id,
    planName: 'Free Trial',
    description: 'Explore our paid feature',
    price: 0,
    period: `for ${default_expiration_days} days`,
    hasActionButton: true,
    actionButtonText: 'Start Free Trial',
    properties: [
      `${limit_prediction_count} credits per ${limit_prediction_period}`,
      'Usage of GPT-4 model',
      'Deploy your models as Web Apps in 1 click',
      'Request access to Chat with your spreadsheet (Preview)',
    ],
    additionalPredictionText: [``],
  }),
};
