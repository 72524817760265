import { Box } from '@material-ui/core';
import { ProjectCard, Text } from 'components';
import React from 'react';
import Carousel from 'react-multi-carousel';
import { Experiment } from 'types';

import useStyles from './ProjectSection.styles';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

type ProjectSectionProps = {
  title: string;
  projects: Experiment[];
};

export const ProjectSection = ({ title, projects }: ProjectSectionProps) => {
  const classes = useStyles();
  return (
    <Box>
      <Box display="flex" alignItems="center" gridGap="24px" mb="30px">
        <Box>
          <Text variant="h4" align="left" className={classes.title}>
            {title}
          </Text>
        </Box>

        <Box className={classes.line}></Box>
      </Box>
      {/* <Carousel responsive={responsive} partialVisible={true}>
        {projects.map((project, index) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignContent="center"
            key={project.id}
          >
            <ProjectCard
              key={`${project.id}-${index}`}
              projectTask={project.task}
              {...project}
            />
          </Box>
        ))}
      </Carousel> */}

      <Box className={classes.projectSection}>
        {projects.slice(0, 4).map((props) => (
          <ProjectCard key={props.id} projectTask={props.task} {...props} />
        ))}
      </Box>
    </Box>
  );
};
