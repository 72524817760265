import React, { FC } from 'react';
import { Box, Button, Card } from '@material-ui/core';
import useStyles from './CurrentPlanCard.styles';
import { Text } from 'components';

export interface CurrentPlanCardProps {}

export const CurrentPlanCard: FC<CurrentPlanCardProps> = () => {
  const classes = useStyles();

  const upgradeHandler = () => {
    window.location.href = `mailto:info@cogniflow.ai?subject=Enterprise plan upgrade&body=Hi, my name is #NAME#.%0D%0A%0D%0AI'm looking to know more about your custom plans.%0D%0A%0D%0A#Optional you can give us more information about your needs#%0D%0A%0D%0AThank you!`;
  };

  return (
    <Card className={classes.specialPlanCard}>
      <Box className={classes.planName}>
        <Text variant="h3" align="left">
          Enterprise
        </Text>
      </Box>

      <Box className={classes.planDetails}>
        <Box className={classes.detailSide}>
          <Text variant="paragraph1" align="left">
            Scale to custom volume, consultancy with our experts, custom
            deployments and more.
          </Text>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          gridGap="8px"
        >
          <Button
            variant="outlined"
            className={classes.enterpriseButton}
            onClick={upgradeHandler}
          >
            Contact us
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
