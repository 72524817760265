import { useEffect, useState } from 'react';

export const useLoadImage = (imgUrl: string) => {
  const [image, setImage] = useState<HTMLImageElement | null>(null);

  useEffect(() => {
    const HTMLImage = new Image();

    HTMLImage.onload = function () {
      setImage(HTMLImage);
    };
    HTMLImage.src = imgUrl;
  }, [setImage, imgUrl]);

  return { image };
};
