import { makeStyles, fade } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  errorMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: fade(colors.errorRed, 0.1),
    height: '48px',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '10px',
    borderRadius: '8px',
  },
  infoMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: colors.blue10,
    height: '48px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
    borderRadius: '8px',
    margin: ' 24px 0px 24px 0px',
  },
  message: {
    fontFamily: 'PTSerif',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue90,
    marginLeft: '5px',
  },
}));
