import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  accuracyBox: {
    // padding: '18px 0 18px 18px',
  },
  scoreLabelNumber: {
    fontFamily: 'Poppins',
    fontSize: '32px',
    fontWeight: 600,
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.blue20,
  },
  scoreTextLabe: {
    fontFamily: 'Poppins',
    fontSize: '8px',
    fontWeight: 600,
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.blue50,
  },
  scoreLabelContainer: {
    width: '48px',
    textAlign: 'left',
    marginLeft: '8px',
  },
}));
