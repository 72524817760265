import { ReactNode } from 'react';

import { OCRResponse, PredictResponse } from './predict.types';
import { ExperimentTest } from './experiments.types';

export interface TaskComponentProps extends ExperimentTest {
  prediction: PredictResponse | null;
  imgUrl: string;
  task?: number;
  showJsonTab?: boolean;
}

export type OCRControlData = Partial<{
  showTextLocation: boolean;
  text: string;
  confidence?: number;
}>;

export type ModalSize = false | 'md' | 'xs' | 'sm' | 'lg' | 'xl' | undefined;

export type GenericSimpleModalProps = {
  isOpen?: boolean;
  children:
    | ((data: { closeHandler(): void }, error?: any) => ReactNode)
    | ReactNode;
  size: ModalSize;
  closeHandler?(): void;
};

export type MaterialTextVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'button'
  | 'caption'
  | 'overline';

export type StatsProps = {
  category: string;
  amount: number;
};

export type DatasetProps = {
  name: string;
  stats: StatsProps[];
  url: string;
  description: string;
};

export type ODBoxProps = Partial<{
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  category: string;
  confidence: number;
  color: string;
}>;

export interface OCRBoxProps extends Partial<OCRResponse> {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  category?: string;
  confidence: number;
  color: string;
  mouseOn?: boolean;
}

export const TEST_PAGE_OPACITY_FACTOR = 0.5;

export type AuthenticationData = {
  accessToken: string;
  email: string;
};

export type EntityProps = {
  name: string;
  description: string;
  input_format: string;
  output_format: string;
  id: string;
  color: string;
};
