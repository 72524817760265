import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import classnames from 'classnames';

import { Link, useParams } from 'react-router-dom';

import { Text } from 'components/Text';
import { LogoCogniflow } from 'assets';

import useStyles from './Navbar.styles';
import { UserAndPlanInfo } from 'components';

import { useHistory } from 'react-router-dom';

export interface NavbarProps {}

export const Navbar = () => {
  const classes = useStyles();
  const { tab } = useParams<{ tab: string }>();

  const [tabs, setTabs] = useState([
    {
      label: 'Dashboard',
      path: '/dashboard',
      selected: tab === 'dashboard',
      value: 'dashboard',
    },
    {
      label: 'Community',
      path: '/community',
      selected: tab === 'community',
      value: 'community',
    },
  ]);

  const { push } = useHistory();

  const changeTab = (tabName: string) => {
    setTabs(tabs.map((tab) => ({ ...tab, selected: tab.value === tabName })));
  };

  const handleTabChange = (value: string, path: string) => {
    changeTab(value);

    push('/projects' + path);
  };

  useEffect(() => {
    changeTab(tab);
  }, [tab]);

  return (
    <Box
      className={classes.navbar}
      height="64px"
      display="flex"
      justifyContent="space-between"
    >
      <Box display="flex" alignSelf="center">
        <Link to="/projects/dashboard">
          <LogoCogniflow className={classes.logo} />
        </Link>
      </Box>
      {tab && (
        <Box className={classes.tabs}>
          {tabs.map(({ label, selected, path }) => (
            <Box
              key={label}
              className={classnames({
                [classes.navbarItem]: true,
                [classes.selected]: selected,
              })}
              onClick={() => {
                handleTabChange(label.toLowerCase(), path);
              }}
            >
              <Text variant="paragraph1">{label}</Text>
            </Box>
          ))}
        </Box>
      )}
      <Box display="flex" alignSelf="center">
        <UserAndPlanInfo />
      </Box>
    </Box>
  );
};
