import { useState, useCallback, useEffect } from 'react';
import { getPresignedUrl } from 'services/files';
import { useRecoilState, atom } from 'recoil';
import { ALERT_TYPES, EXPERIMENT_TASK, PresignedUploadResponse } from 'types';
import { useAlertContext } from 'context';

export const uploadFinishedAtom = atom<boolean>({
  key: 'uploadCompleted',
  default: false,
});

export const useUploadProgress = (task?: number | string) => {
  const [fileName, setFileName] = useState('');
  const [progress, setProgress] = useState(0);
  const [presignedResponse, setPresignedResponse] = useState<
    PresignedUploadResponse | undefined
  >();
  const [isFinished, setFinished] = useRecoilState(uploadFinishedAtom);
  const [error, setError] = useState<Error | undefined>();
  const [isStarted, setIsStarted] = useState(false);

  const { setAlertHandler, resetAlert } = useAlertContext();

  const progressHandler = useCallback(
    ({ loaded, total }) => {
      const currentProgress = (loaded / total) * 100;
      setProgress(currentProgress);
    },
    [setProgress]
  );

  const uploadComplete = useCallback(() => {
    setProgress(100);
    setFinished(true);
    setIsStarted(false);
  }, [setProgress, setFinished, setIsStarted]);

  const uploadFailed = useCallback(() => {
    setProgress(0);
    if (setError) {
      setError(new Error("Couldn't upload the file"));
    }
  }, [setProgress, setError]);
  const handleFetch = useCallback(
    (url: string, file: File, fields: { [key: string]: string }) => {
      const ajax = new XMLHttpRequest();
      ajax.upload.onprogress = (e) => {
        progressHandler(e);
      };
      ajax.upload.onloadend = () => {
        uploadComplete();
      };
      ajax.upload.onerror = () => {
        uploadFailed();
      };
      ajax.upload.onabort = () => {
        uploadFailed();
      };
      ajax.open('POST', url);
      const data = new FormData();
      Object.keys(fields).forEach((key) => {
        data.set(key, fields[key]);
      });
      data.set('file', file);
      data.set('success_action_status', '201');
      ajax.send(data);
    },
    [uploadComplete, uploadFailed, progressHandler]
  );

  const uploadFile = useCallback(
    async (files: File[]) => {
      let [file] = files;
      if (
        parseInt(task as string) === EXPERIMENT_TASK.TEXT_QANDA &&
        file.size > 100 * 1024 * 1024
      ) {
        setAlertHandler({
          showing: true,
          message: `File size should not exceed 100MB`,
          type: ALERT_TYPES.FULLWIDTH_BAR,
          resetTime: 10000,
          severity: 'error',
          position: 'top',
          styles: { position: 'fixed' },
        });

        return;
      }

      setProgress(0.1);
      try {
        const presignedUrl = await getPresignedUrl(file.name);

        if (presignedUrl && presignedUrl.url) {
          setFileName(file.name);
          setPresignedResponse(presignedUrl);
          setIsStarted(true);
          handleFetch(presignedUrl.url, file, presignedUrl.s3_fields);
        } else {
          setProgress(0);
        }
      } catch (error) {
        setProgress(0);
      }
    },
    [
      task,
      setProgress,
      handleFetch,
      setPresignedResponse,
      setIsStarted,
      setAlertHandler,
    ]
  );

  const resetProgress = useCallback(() => {
    setProgress(0);
  }, [setProgress]);

  useEffect(() => {
    return () => {
      resetAlert();
    };
  }, [resetAlert]);

  return {
    uploadFile,
    resetProgress,
    progress,
    presignedResponse,
    error,
    isFinished,
    isStarted,
    fileName,
  };
};
