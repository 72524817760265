import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  helper: {
    backgroundColor: '#484F79',
    color: 'white',
    padding: '24px',
    boxSizing: 'border-box',
    // width: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',

    position: 'relative',
  },

  text: {
    color: 'white',
  },

  pointer: ({ pointerHeight }: any) => ({
    position: 'absolute',

    top: pointerHeight || '148px',
    border: '1px solid white',
    left: '-15px',

    borderBottom: '20px solid transparent',
    borderTop: '20px solid transparent',
    borderRight: '20px solid #484F79',
  }),

  button: {
    border: '1px solid white',
    padding: '12px 32px',
    width: 'fit-content',
    margin: '0 auto',
  },
}));
