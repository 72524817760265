import { Overrides } from '@material-ui/core/styles/overrides';
import { colors } from '../colors';

export const Chip: Overrides['MuiChip'] = {
  root: {
    fontFamily: '"PT Serif", serif',
    fontSize: 12,
    lineHeight: 12,
  },
  colorPrimary: {
    backgroundColor: colors.blue70,
  },
};
