import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useAuthenticationContext } from 'context';

export const AuthRoute = (props: RouteProps) => {
  const { isAuthenticated } = useAuthenticationContext();

  if (isAuthenticated === null) {
    return <p>Loading...</p>;
  }

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
};
