import { makeStyles } from '@material-ui/core';
import { colors } from 'material';

type StyleProps = {
  progress?: number;
};

export default makeStyles(() => ({
  progressText: {
    fontSize: '12px',
  },
  progressBg: {
    height: '7px',
    borderRadius: '24px',
    backgroundColor: '#D9D9D9',
    width: '100%',
  },

  progress: ({ progress }: StyleProps) => ({
    height: '7px',
    borderRadius: '24px',
    width: `${progress}%`,
    backgroundColor: colors.orange,
    transition: 'width 0.5s',
  }),
}));
