import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  title: {
    marginBottom: '24px',
  },

  dropdownsSection: {
    marginBottom: '18px',
  },
  dropdown: {
    width: '300px',
    borderRadius: '10px',
    '&::before': {
      content: '',
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
  },
  error: {
    margin: '8px 0',
  },
  label: {
    '&.MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(11px, 4px) scale(0.65)',
    },
    '&.MuiInputLabel-filled': {
      transform: 'translate(15px, 16px) scale(1)',
    },
  },
}));
