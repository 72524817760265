import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './longswitch.style';

type OptionsProps = {
  text: string;
  helperText?: string;
};

type LongSwitchProps = {
  options: OptionsProps[];
  selectedIndex: number;
  selectIndexHandler(index: number): void;
};

export const LongSwitch = ({
  options,
  selectedIndex,
  selectIndexHandler,
}: LongSwitchProps) => {
  const classes = useStyles();

  return (
    <Grid className={classes.longSwitchContainer}>
      {options.map((option, idx) => (
        <Grid
          className={`${classes.option} ${
            idx === selectedIndex ? classes.selected : ''
          }`}
          key={option.text}
          onClick={() => {
            selectIndexHandler(idx);
          }}
        >
          <Typography>{option.text}</Typography>
          <span className={classes.helperText}>{option.helperText}</span>
        </Grid>
      ))}
    </Grid>
  );
};
