import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { Text } from 'components';
import { AlertProps, Severity } from 'types';

import useStyles from './FullwidthBarAlert.styles';
import { IconClose } from 'assets';

export const FullwidthBarAlert: FC<AlertProps> = ({
  showing,
  message,
  severity,
  position = 'bottom',
  floating = true,
  resetAlert,
  styles,
}) => {
  const classes = useStyles({
    severity: severity as Severity,
    position,
    floating,
    styles,
  });
  return showing ? (
    <Grid className={classes.fullwidthBar}>
      <div className={classes.content}>
        <Text variant="paragraph1">{message}</Text>
        <IconClose className={classes.closeIcon} onClick={resetAlert} />
      </div>
    </Grid>
  ) : null;
};
