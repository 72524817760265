import { atom, selector } from 'recoil';
import { Experiment, EXPERIMENT_TASK } from 'types';

export const experimentsQueryState = atom<{ [key: string]: string }>({
  key: 'experimentsQueryState',
  default: {},
});

export const currentExperimentState = atom<Experiment | undefined>({
  key: 'currentExperimentState',
  default: undefined,
});

export const experimentLoading = atom<boolean>({
  key: 'experimentLoading',
  default: false,
});

export const experimentError = atom<string>({
  key: 'experimentError',
  default: '',
});

export const isSpeech2TextTask = selector<boolean>({
  key: 'isSpeech2TextTask',
  get: ({ get }) => {
    const experiment = get(currentExperimentState);

    return experiment?.task === EXPERIMENT_TASK.AUDIO_SPEECH2TEXT;
  },
});

export const experimentName = atom<string>({
  key: 'experimentName',
  default: 'New experiment',
});
