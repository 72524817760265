import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

type StyleProps = {
  bg?: string;
};

export default makeStyles<ICogniflowTheme>(({ colors }) => ({
  apiKeyContainer: ({ bg }: StyleProps) => ({
    padding: '6px 12px',
    backgroundColor: bg ? bg : colors.blue5,
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  apiKeyText: {
    fontSize: '12px',
  },
  copyToClipboard: {
    cursor: 'pointer',
  },
}));
