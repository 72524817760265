import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'material/colors';

type StylesProps = {
  selected: boolean;
  highlighted: boolean;
};

const centerItems = {
  display: 'flex',
  alignItems: 'center',
};

const useStyles = makeStyles((theme) => ({
  searchElement: ({ highlighted }: StylesProps) => ({
    ...centerItems,
    gap: '12px',
    cursor: 'pointer',
    backgroundColor: highlighted ? colors.blue10 : 'transparent',
    borderRadius: '8px',
    padding: '8px',
  }),

  imageContainer: {
    ...centerItems,
    width: '40px',
    height: '40px',
    borderRadius: '8px',
    flexShrink: 0,
  },

  info: {
    maxHeight: '100px',
    overflow: 'hidden',
  },

  elementTitle: {
    fontSize: '16px',
    color: colors.blue90,
  },

  image: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },

  elementDescription: {
    fontSize: '12px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    'line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },

  elementType: {
    fontSize: '10px',
    color: colors.blue70,
    marginBottom: '4px',
  },
}));

export default useStyles;
