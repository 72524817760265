import React, { useEffect, useState } from 'react';
import { User } from 'types';
import { Grid, Box } from '@material-ui/core';
import useStyles from '../upgradeplanmodal.styles';
import { PlanCard } from '../PlanCard';
import { plansInfo, PlanData } from './appSumoPlans';
import { useAuthenticationContext } from 'context';
import { Text } from 'components/Text';

type UpgradePlanModalProps = {
  open: boolean;
  onClose(): void;
  onUpgradeHandler(planId: string, planPeriodIndex: number): void;
};

export const AppSumo = ({
  onClose,
  onUpgradeHandler,
  ...props
}: UpgradePlanModalProps) => {
  const classes = useStyles();
  const { user } = useAuthenticationContext();

  const [planCardsContent, setPlanCardsContent] = useState<PlanData[]>([]);
  // eslint-disable-next-line
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setPlanCardsContent(plansInfo[selectedIndex]);
  }, [selectedIndex]);

  return (
    <Box {...props}>
      <Grid className={classes.modalContainer}>
        <Grid className={classes.appsumoPlanTitle}>
          <Text variant="h2" align="center">
            Plans and pricing
          </Text>
        </Grid>

        <Grid className={classes.content}>
          {user && (
            <Grid className={classes.planCards}>
              {planCardsContent.map((card, idx) =>
                user.commercial_plan.id === card.id ? (
                  <PlanCard
                    idx={idx}
                    key={idx}
                    user={user as User}
                    currentPlanId={user ? user.commercial_plan.id : ''}
                    onUpgradeHandler={onUpgradeHandler}
                    planPeriodIndex={selectedIndex}
                    {...card}
                  />
                ) : (
                  ''
                )
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
