import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  modal: {
    '& .MuiDialog-container': {
      display: 'flex',
      alignItems: 'flex-start',
    },
    padding: '0',
    overflow: 'hidden',
  },
  modalContainer: {
    overflow: 'hidden',
    width: '500px',
  },

  dialogContent: {
    minHeight: '150px',
    overflowY: 'hidden',
    paddingBottom: '12px',
  },

  searchElementsContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    gap: '12px',
    padding: '0',
    maxHeight: '500px',

    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
    },
    '&::-webkit-scrollbar-thumb:active': {
      background: 'rgba(0, 0, 0, 0.6)',
    },
  },
  inputContainer: {
    marginBottom: '12px',
  },
}));
