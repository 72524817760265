import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  tableWrapper: {
    '& .MuiTableCell-root': {
      border: 'none',
      '& p': {
        fontWeight: '',
        fontSize: '12px',
      },
    },
    '& table tbody': {
      '& tr:nth-child(odd)': {
        backgroundColor: colors.blue5,
      },
    },

    '& table thead tr': {
      borderTop: `1px solid ${colors.blue15}`,
    },
  },
  tableCol: {
    fontWeight: 'bold',
    fontSize: '12px',
  },
  icon: {
    fill: colors.blue70,
    cursor: 'pointer',
  },
  editIcon: {
    width: '25px',
    height: '25px',
    position: 'relative',
    top: '2.5px',
  },
  entryTextBg: {
    borderRadius: '5px',
    padding: '2px 5px',
    fontSize: '12px',
  },
}));
