import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { UserInfo } from '../UserInfo';
import useStyles from './UserAndPlanInfo.styles';
import { PlanInfo } from 'components/PlanInfo';
import { useAuthenticationContext } from 'context';
import { CustomChip } from 'components/CustomChip';
import { PlAN_IDS, User } from 'types';
import { UpgradeFlowModals } from 'components/UpgradeFlowModals';
import { VerifyEmailModal } from 'components/VerifyEmailModal';
import { useHistory } from 'react-router-dom';

import { StyledTextLink } from 'components/StyledTextLink';

export const UserAndPlanInfo = () => {
  const [showUsage, setShowUsage] = useState(false);
  const { user, logout } = useAuthenticationContext();
  const classes = useStyles();
  const [isPlanUpgradeOpen, setIsPlanUpgradeOpen] = useState<boolean>(false);
  const [isVerifyEmailOpen, setIsVerifyEmailOpen] = useState<boolean>(false);
  const { push } = useHistory();

  const onCloseHandler = () => {
    setIsPlanUpgradeOpen(false);
  };

  const onCloseVerifyEmailHandler = () => {
    setIsVerifyEmailOpen(false);
  };

  const isFree = useMemo(() => user?.commercial_plan.id === PlAN_IDS.FREE, [
    user,
  ]);

  return (
    <Box>
      <Box className={classes.accountInfoContainer}>
        {showUsage && user && (
          <PlanInfo user={user as User} onClose={() => setShowUsage(false)} />
        )}

        <Box className={classes.planInfoContainer}>
          <Typography variant="caption" className={classes.caption}>
            <span className={classes.plan}>
              {user && user.commercial_plan?.name} plan
            </span>
            <CustomChip
              label="SEE USAGE"
              onClick={() => {
                setShowUsage(true);
              }}
            />
          </Typography>
          {isFree && (
            <StyledTextLink
              underlined
              onClick={() => push('/settings/subscription')}
            >
              Upgrade your plan
            </StyledTextLink>
          )}
        </Box>
        <UserInfo user={user} onLogoutClick={logout} />
      </Box>
      <UpgradeFlowModals open={isPlanUpgradeOpen} onClose={onCloseHandler} />
      <VerifyEmailModal
        email={user ? user.email : ''}
        open={isVerifyEmailOpen}
        onClose={onCloseVerifyEmailHandler}
        onResend={() => {}}
        onLogout={logout}
      />
    </Box>
  );
};
