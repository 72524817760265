export enum ALERT_TYPES {
  DEFAULT = 'default',
  FULLWIDTH_BAR = 'fullwidthbar',
}

export type AlertProps = {
  showing: boolean;
  resetAlert(): void;
  message: string | React.ReactNode;
  severity?: string;
  type?: string;
  position?: string;
  floating?: boolean;
  styles?: { [key: string]: string | number };
};
