import React, { useEffect, useState, Suspense, lazy } from 'react';

import {
  AlertToast,
  PromotionalPlanModal,
  PlanModals,
  AuthRoute,
} from 'components';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { useAuthenticationContext } from 'context';
import { confirmAlert } from 'react-confirm-alert';

const Login = lazy(() =>
  import('pages/Login').then((module) => ({ default: module.Login }))
);
const ActivateAccount = lazy(() =>
  import('pages/ActivateAccount').then((module) => ({
    default: module.ActivateAccount,
  }))
);
const ForgotPasswd = lazy(() =>
  import('pages/ForgotPasswd').then((module) => ({
    default: module.ForgotPasswd,
  }))
);
const Reset = lazy(() =>
  import('pages/ForgotPasswd').then((module) => ({ default: module.Reset }))
);
const Experiments = lazy(() =>
  import('pages/Experiments').then((module) => ({
    default: module.Experiments,
  }))
);
const ViewExperiment = lazy(() =>
  import('pages/ViewExperiment').then((module) => ({
    default: module.ViewExperiment,
  }))
);
const Settings = lazy(() =>
  import('pages/Settings').then((module) => ({ default: module.Settings }))
);
const Application = lazy(() =>
  import('pages/Application').then((module) => ({
    default: module.Application,
  }))
);
const Signup = lazy(() =>
  import('pages/Signup').then((module) => ({ default: module.Signup }))
);
const PaymentConfirmation = lazy(() =>
  import('pages/PaymentConfirmation').then((module) => ({
    default: module.PaymentConfirmation,
  }))
);
const Embed = lazy(() =>
  import('pages/Embed').then((module) => ({ default: module.Embed }))
);
const Projects = lazy(() =>
  import('pages/Projects').then((module) => ({ default: module.Projects }))
);
const CreateProject = lazy(() =>
  import('pages/CreateProject').then((module) => ({
    default: module.CreateProject,
  }))
);
const Admin = lazy(() =>
  import('pages/Admin').then((module) => ({ default: module.Admin }))
);
const BuildingProject = lazy(() =>
  import('pages/BuildingProject').then((module) => ({
    default: module.BuildingProject,
  }))
);
const CreateExperiment = lazy(() =>
  import('pages/CreateExperiment').then((module) => ({
    default: module.CreateExperiment,
  }))
);

export default function Routes() {
  const { user } = useAuthenticationContext();
  const [promotionalPlanId, setPromotionalPlanId] = useState<string | null>(
    null
  );
  const [showPromo, setShowPromo] = useState(true);

  useEffect(() => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      let pcode = queryParams.get('pcode') || '';
      if (pcode) localStorage.setItem('showPromo', pcode);

      pcode = localStorage.getItem('showPromo') || '';
      if (user && pcode) {
        setPromotionalPlanId(pcode);
        localStorage.removeItem('showPromo');
      }
    } catch {}
  }, [user]);

  return (
    <Router
      getUserConfirmation={(message, callback) => {
        confirmAlert({
          message,
          buttons: [
            {
              label: 'Yes',
              onClick: () => callback(true),
            },
            {
              label: 'No',
              onClick: () => callback(false),
            },
          ],
        });
      }}
    >
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <AuthRoute exact path="/">
              <Redirect to="/projects/dashboard" />
            </AuthRoute>

            <AuthRoute path="/experiments" component={Experiments} />

            <AuthRoute path="/experiment/:id" component={ViewExperiment} />

            <Route exact path="/login" component={Login} />

            <Route path="/signup" component={Signup} />

            <Route exact path="/activation" component={ActivateAccount} />

            <Route exact path="/forgot" component={ForgotPasswd} />

            <Route exact path="/reset" component={Reset} />

            <AuthRoute path="/create" component={CreateExperiment} />

            <AuthRoute path="/new-project" component={CreateProject} />

            <AuthRoute path="/settings" component={Settings} />

            <AuthRoute path="/projects/:tab" component={Projects} />

            <Route path="/app/:applicationId" component={Application} />

            <Route
              path="/payment_confirmation"
              component={PaymentConfirmation}
            />

            <Route path="/embed" component={Embed} />

            <AuthRoute
              path="/building/:experimentId"
              component={BuildingProject}
            />

            <AuthRoute path="/admin" component={Admin} />
          </Switch>
        </Suspense>
      </div>

      <PlanModals />
      <PromotionalPlanModal
        open={showPromo}
        onClose={() => setShowPromo(false)}
        planId={promotionalPlanId}
      />
      <AlertToast />
    </Router>
  );
}
