import React, { FC } from 'react';
import { Tooltip } from '@material-ui/core';
import { ResendIcon } from 'assets';

import useStyles from './SendButton.styles';

export interface SendButtonProps {
  tooltip?: string;
  onSend(): void;
  disabled?: boolean;
}

export const SendButton: FC<SendButtonProps> = ({
  tooltip,
  onSend,
  disabled,
}) => {
  const classes = useStyles({
    disabled,
  });

  return (
    <Tooltip title={tooltip || ''}>
      <ResendIcon className={classes.sendButton} onClick={onSend} />
    </Tooltip>
  );
};
