import { makeStyles } from '@material-ui/core';
import { colors } from 'material';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  docsLink: {
    color: colors.orange,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));
