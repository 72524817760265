const INVALID_ENTRIES = ['weighted avg', 'accuracy', 'macro avg'];

export const getArray2dMaxValue = (matrix: Array<Array<number>>) =>
  Math.max(...matrix.flat());

export const getArraySum = (array: number[]) =>
  array.reduce((acc: number, value: number) => acc + value, 0);

export const filterModelCategories = (classificationReport: any) =>
  Object.keys(classificationReport).filter(
    (entry) => !INVALID_ENTRIES.includes(entry)
  );
