import React, { useEffect, useMemo, useState } from 'react';
import { Experiment, EXPERIMENT_VISIBILITY_NUM } from 'types/experiments.types';
import { useAuthenticationContext } from 'context';
import { updateExperiment } from 'services/experiments';
import {
  createStyles,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  withStyles,
} from '@material-ui/core';
import { useExperimentState } from 'hooks';
import { ConfirmVisibility } from 'components';
import { http } from 'services/http';
import { Company } from 'types';

const BootstrapInput = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      fontWeight: 'bold',
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 14,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Poppins',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  })
)(InputBase);

interface Props {
  experiment: Experiment;
  closeSettingsModal?(): void;
}

export const UpdateExperimentVisibility = React.memo(
  ({ experiment, closeSettingsModal }: Props) => {
    const { user } = useAuthenticationContext();
    const [visibility, setVisibility] = useState(experiment.visibility);
    const { updateExperimentProp } = useExperimentState();
    const [selected, setSelected] = useState<number>();
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [company, setCompany] = useState('');

    const experimentOptions = useMemo(
      () => [
        {
          value: EXPERIMENT_VISIBILITY_NUM.COMMUNITY,
          label: 'Community',
        },
        ...(user?.id_company
          ? [
              {
                value: EXPERIMENT_VISIBILITY_NUM.COMPANY,
                label: 'Organization',
              },
            ]
          : []),
        {
          value: EXPERIMENT_VISIBILITY_NUM.PRIVATE,
          label: 'Private',
        },
      ],
      [user]
    );

    useEffect(() => {
      http.get('company/').then((res) => {
        setCompany((res as Company).name);
      });
    }, []);

    const handleChange = async (
      event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
    ) => {
      const visibility = event.target.value as number;
      setSelected(visibility);
      setConfirmModal(true);
      try {
      } catch {}
    };

    const closeConfirmModal = () => {
      setConfirmModal(false);
      setSelected(visibility);
      setSelected(undefined);
    };

    const confirmVisibilityChange = async () => {
      updateExperimentProp({ visibility: selected });
      setVisibility(selected as number);
      await updateExperiment(experiment.id, { visibility: selected });
      closeSettingsModal?.();
      setConfirmModal(false);
    };

    const cancelVisibilityChange = () => {
      setConfirmModal(false);
      setSelected(undefined);
    };

    return (
      <FormControl>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={visibility}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          {experimentOptions.map(({ value, label }) => (
            <MenuItem value={value} key={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <ConfirmVisibility
          currentVisibility={selected}
          company={company}
          open={confirmModal}
          handleClose={closeConfirmModal}
          confirmHandler={confirmVisibilityChange}
          cancelHandler={cancelVisibilityChange}
        />
      </FormControl>
    );
  }
);
