import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  customRoot: {},
  modalContainer: {
    // width: '430px',
    flex: 1,
    width: '50%',
  },
  modalTitle: {
    padding: '0px 24px',
  },
  dialogContent: {
    //padding: '0px 48px 24px 48px',
  },
  dialogContainer: {
    borderRadius: '16px',
    padding: 12,
  },
  paper: ({ isDouble }: any) => ({
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'unset !important',
      backgroundColor: 'blue',
      // width: isDouble ? '860px' : '430px',
      // maxWidth: isDouble ? '860px' : '430px',
    },
    maxWidth: 'unset !important',
    margin: 0,
    width: isDouble ? '860px' : '430px',
    borderRadius: 16,
    overflowX: 'hidden',
  }),
  layout: {
    display: 'flex',
  },

  helper: {
    backgroundColor: '#484F79',
    color: 'white',
    flex: 1,
    width: '50%',
  },
}));
