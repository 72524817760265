import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(({ breakpoints }) => ({
  specialPlanCard: {
    paddingBottom: '24px',
    background: 'linear-gradient(to right, #ff6a61, #ffa07a)',
    '& p, & h1, & h2, & h3, & h4, & svg g': {
      color: 'white',
      fill: 'white',
    },
    '& button': {
      backgroundColor: 'white',
      color: 'black',
      border: 'none',
    },
  },

  header: {
    borderBottom: '1px solid #d8ddeb',
    padding: '12px 32px',
  },
  planName: {
    padding: '24px 32px',
  },
  link: {
    fontSize: '14px',
  },

  planDetails: {
    display: 'flex',
    gap: '24px',
    padding: '24px 32px',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  detailSide: {},

  priceContainer: {
    padding: '0 32px',
  },

  price: {
    borderTop: '1px solid #d8ddeb',
    padding: '24px 0',
  },

  enterpriseButton: {
    backgroundColor: colors.blue90,
    color: 'white',
    width: '300px',
  },
}));
