import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  modalDescription: {
    margin: '20px 0',
  },

  itemContainer: {
    backgroundColor: '#EFEFEF',
    borderRadius: '11px',
  },

  itemBox: {
    padding: '6px 19px 6px 10px',
    gap: '12px',
  },

  dataContainer: {
    padding: '0 19px 20px 19px',
  },

  itemDescription: {
    marginBottom: '15px',
  },

  StatsContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
  },

  statsItem: {
    '& > div': {
      flex: '1',
    },
  },

  categoryText: {
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    overflow: 'hidden',
    cursor: 'pointer',
  },

  amount: {
    backgroundColor: colors.blue10,
    borderRadius: '10px',
    '& p': {
      fontSize: '12px',
      fontFamily: 'Poppins',
      fontWeight: 600,
    },
    maxWidth: '100px',
    padding: '2px 8px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '12px',
  },

  divider: {
    margin: '12px 0',
  },
}));
