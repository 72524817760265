import { httpPredict } from './http';
import {
  PredictResponse,
  TextBody,
  ImageRequest,
  AudioRequest,
  LongTranscriptionRequest,
  EXPERIMENT_TYPE,
} from 'types';
import { getPredictionUrl, urlMap } from 'utils';

export const predictText = <T>(
  id: string,
  payload: TextBody,
  task: number,
  headers?: T
): Promise<PredictResponse> =>
  httpPredict.post(
    `${getPredictionUrl(task, EXPERIMENT_TYPE.TEXT)}/${id}`,
    JSON.stringify(payload),
    headers ? headers : {}
  );

export const predictImage = <T>(
  id: string,
  payload: ImageRequest,
  task: number,
  headers?: T
): Promise<PredictResponse> =>
  httpPredict.post(
    `${getPredictionUrl(task, EXPERIMENT_TYPE.IMAGE)}/${id}`,
    JSON.stringify(payload),
    headers ? headers : {}
  );

export const predictAudio = <T>(
  id: string,
  payload: AudioRequest,
  task: number,
  headers?: T
): Promise<PredictResponse> =>
  httpPredict.post(
    `${getPredictionUrl(task, EXPERIMENT_TYPE.AUDIO)}/${id}`,
    JSON.stringify(payload),
    headers ? headers : {}
  );

type ImageSimilarityPredictProps = {
  format_img1: string;
  format_img2: string;
  base64_img1: string;
  base64_img2: string;
  threshold?: number;
  modelId: string;
};

export const predictImageSimilarity = <T>(
  modelId: string,
  payload: any,
  headers?: T
) =>
  httpPredict.post(
    `image/image_verification/verify/${modelId}`,
    JSON.stringify(payload),
    headers ? headers : {}
  );

export const predictImageSimilarity1 = ({
  modelId,
  ...payload
}: ImageSimilarityPredictProps) =>
  Promise.resolve({
    processing_time: 2.35,
    result: {
      verified: true,
      similarity_score: 0.42,
      threshold: 0.3,
      similarity_metric: 'euclidean_l2',
    },
  });

export const predictLongTranscription = <T>(
  id: string,
  payload: LongTranscriptionRequest,
  task: number,
  headers?: T
) => {
  return httpPredict.post(
    `${urlMap[task]}-from-web/${id}`,
    JSON.stringify(payload),
    headers ? headers : {}
  );
};
