import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme, theme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(({ breakpoints }) => ({
  navbar: {
    backgroundColor: '#484F79',
    padding: '10px 195px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 50px',
    },
  },

  navbarItem: {
    display: 'flex',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
    height: '40px',
    alignItems: 'center',
    padding: '2px 24px',
    '& p': {
      fontWeight: 700,
      color: 'white',
    },
    cursor: 'pointer',
    backgroundColor: '#484F79',
  },

  selected: {
    '& p': {
      fontWeight: 700,
      color: '#484F79',
    },
    backgroundColor: '#D8DDEB',
  },

  logo: {
    position: 'relative',
    left: '-11px',
    width: 220,

    [breakpoints.down('sm')]: {
      width: 220,
    },
    [breakpoints.down('xs')]: {
      display: 'none',
    },
    '& path.cls-1': {
      fill: 'white',
    },
  },

  tabs: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    position: 'relative',
    bottom: '-10px',

    [breakpoints.down('sm')]: {
      bottom: '0',
    },
  },
}));
