import { makeStyles } from '@material-ui/core';
import { LoginBackground } from 'assets';
import { colors } from 'material/colors';

export default makeStyles((theme) => ({
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    backgroundColor: '#fff',
    // boxShadow: '0 16px 24px 0 rgba(72, 79, 121, 0.15)',
    borderRadius: '16px',
    overflow: 'hidden',
  },
  headerSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignContent: 'center',
    marginLeft: '30px',
    paddingTop: '12px',
    top: 0,
    left: 0,
    '& svg': {
      height: '60px',
      '& .cls-1': {
        fill: colors.blue90,
        stroke: 'none',
      },
    },
    marginBottom: '24px',
  },

  infoAuthSection: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '140px 70px',
    },
    position: 'relative',
    padding: '145px',
    width: '50%',
    height: '100%',
    background: `url(${LoginBackground}) no-repeat center center fixed`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '60px',
    '& p': {
      color: 'white',
      fontSize: '16px',
      zIndex: 1,
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
    boxSizing: 'border-box',
  },

  filter: {
    content: ' ',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    background:
      'linear-gradient(to right, rgba(255, 106, 97, 0.9), rgba(255, 160, 122, 0.9))',
  },

  logoIcon: {
    position: 'absolute',
    zIndex: 1,
    right: 30,
    top: 30,
    '& svg': {
      height: '60px',
      '& .cls-1': { stroke: 'white' },
    },
  },

  infoTitle: {
    marginBottom: '8px',
    fontWeight: 'bold',
    display: 'block',
  },

  checkIcon: {
    color: colors.blue10,
  },
}));
