import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    boxShadow: '0 8px 16px 0 rgba(255, 106, 97, 0.4)',
  },
  iconUp: {
    color: '#fff',
    transform: 'rotate(90deg)',
  },
  iconDown: {
    color: '#fff',
    transform: 'rotate(-90deg)',
  },
}));
