import React, { ReactNode } from 'react';
import useStyle from './signupformcard.styles';

import { Text } from 'components';
import { useHistory } from 'react-router-dom';
import { AuthFormCard } from '../AuthFormCard';

interface Props {
  children: ReactNode;
}

export const SignupFormCard = ({ children }: Props) => {
  const classes = useStyle();
  const { push } = useHistory();

  const handleLoginLink = () => {
    push('/login');
  };
  return (
    <AuthFormCard
      subtitleComponent={
        <div className={classes.formTitleSection}>
          <span className={classes.headerTitle}>Create your account</span>
        </div>
      }
      bodyComponent={<div className={classes.formSection}>{children}</div>}
      footerComponent={
        <div className={classes.footerSection}>
          <Text variant="paragraph1">
            Already have an account?{' '}
            <label
              onClick={handleLoginLink}
              className={classes.forgotPasswordLink}
            >
              Log in
            </label>
          </Text>
        </div>
      }
    />
  );
};
