import React from 'react';
import { Box } from '@material-ui/core';
import { ImagePlaceholder } from 'assets';
import classNames from 'classnames';

import { Text } from 'components';

import useStyles from './SearchElement.styles';

import { buildPublicUrl, getEnv } from 'services';
import { EXPERIMENT_TASK_LABEL, EXPERIMENT_TYPE_LABEL } from 'types';

export const SearchElement = ({
  highlighted,
  selected,
  name,
  image,
  highlightedIndex,
  description,
  type,
  task,
  id,
  ...props
}: any) => {
  const classes = useStyles({ highlighted, selected });

  const imageUrl = image
    ? buildPublicUrl(image, getEnv('REACT_APP_BUCKET_NAME') || '')
    : ImagePlaceholder;

  const goToExperiment = () => {
    window.location.href = `/experiments/${id}`;
  };

  return (
    <Box
      onClick={goToExperiment}
      className={classNames(classes.searchElement, 'dropdown-item')}
      {...props}
    >
      <Box className={classes.imageContainer}>
        <img src={imageUrl} alt={name} className={classes.image} />
      </Box>

      <Box className={classes.info}>
        <Text variant="paragraph1" className={classes.elementTitle}>
          {name}
        </Text>

        <Text variant="paragraph1" className={classes.elementType}>
          {EXPERIMENT_TYPE_LABEL[type]} | {EXPERIMENT_TASK_LABEL[task]}
        </Text>

        <Text variant="paragraph1" className={classes.elementDescription}>
          {description}
        </Text>
      </Box>
    </Box>
  );
};
