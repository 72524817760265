import React, { ReactNode } from 'react';
import { PROJECT_TASK } from 'types';
import {
  ChatbotBg,
  ChatbotIcon,
  ChatbotEllipse,
  ExtractorBg,
  ExtractorIcon,
  ExtractorEllipse,
  ClassifierBg,
  ClassifierIcon,
  ClassifierEllipse,
  ObjectBg,
  ObjectLogo,
  ObjectEllipse,
  DefaultEllipse,
  DefaultBg,
} from './assets';

type Assets = {
  bg: string;
  icon: ReactNode;
  ellipse: string;
};

const defaultAssets = {
  bg: DefaultBg,
  icon: null,
  ellipse: DefaultEllipse,
};

const classifierAssets = {
  bg: ClassifierBg,
  icon: <ClassifierIcon />,
  ellipse: ClassifierEllipse,
};

export const assets: Record<number, Assets> = {
  [PROJECT_TASK.CHATBOT]: {
    bg: ChatbotBg,
    icon: <ChatbotIcon />,
    ellipse: ChatbotEllipse,
  },
  [PROJECT_TASK.EXTRACTOR]: {
    bg: ExtractorBg,
    icon: <ExtractorIcon />,
    ellipse: ExtractorEllipse,
  },
  [PROJECT_TASK.CLASSIFIER_TEXT]: classifierAssets,
  [PROJECT_TASK.CLASSIFIER_IMAGE]: classifierAssets,
  [PROJECT_TASK.CLASSIFIER_AUDIO]: classifierAssets,
  [PROJECT_TASK.OBJECT_DETECTION]: {
    bg: ObjectBg,
    icon: <ObjectLogo />,
    ellipse: ObjectEllipse,
  },
  [PROJECT_TASK.DEFAULT]: defaultAssets,
};
