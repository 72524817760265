import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import './App.css';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { theme } from 'material/theme';
import { RecoilRoot } from 'recoil';
import Routes from './Routes';
import {
  AlertContextProvider,
  ApplicationContextProvider,
  AuthenticationProvider,
  CentrifugeProvider,
  UserPlanContextProvider,
} from 'context';
import { ToastContainer } from 'react-toastify';

import { GoogleOAuthProvider } from '@react-oauth/google';

import { getEnv } from 'services';

function App() {
  const googleClientID = getEnv('REACT_APP_GOOGLE_CLIENT_ID') || '';

  return (
    <div className="App">
      <RecoilRoot>
        <MuiThemeProvider theme={theme}>
          <AlertContextProvider>
            <GoogleOAuthProvider clientId={googleClientID}>
              <AuthenticationProvider>
                <UserPlanContextProvider>
                  <CentrifugeProvider>
                    <ApplicationContextProvider>
                      <>
                        <ToastContainer />
                        <Routes />
                      </>
                    </ApplicationContextProvider>
                  </CentrifugeProvider>
                </UserPlanContextProvider>
              </AuthenticationProvider>
            </GoogleOAuthProvider>
          </AlertContextProvider>
        </MuiThemeProvider>
      </RecoilRoot>
    </div>
  );
}

export default App;
