import React, { ReactNode } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { CircularProgress, Grid } from '@material-ui/core';

export interface TestDropzoneProps {
  percent?: number;
  onDrop: DropzoneOptions['onDrop'];
  error?: boolean;
  dropzoneOptions?: DropzoneOptions;
  children: ReactNode;
  customClass?: string;
  loading?: boolean;
}

export const TestDropzone = ({
  percent,
  onDrop,
  dropzoneOptions,
  children,
  customClass,
  loading,
}: TestDropzoneProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...dropzoneOptions,
  });

  return (
    <div
      {...getRootProps()}
      className={customClass}
      style={{ backgroundColor: 'white', cursor: 'pointer' }}
    >
      <Grid container direction="row" wrap="nowrap" justify="center">
        <Grid item>
          {!percent && (
            <Grid container justify="center" alignItems="center">
              {children}
            </Grid>
          )}
          {percent !== undefined && (
            <>
              {((percent > 0 && percent < 100) || loading) && (
                <Grid
                  container
                  justify="center"
                  className="pera"
                  alignItems="center"
                >
                  <CircularProgress disableShrink />
                </Grid>
              )}
            </>
          )}
          {percent === 100 && !loading && (
            <Grid
              container
              justify="center"
              className="pera"
              alignItems="center"
            >
              {children}
            </Grid>
          )}
        </Grid>
      </Grid>
      <input
        id="dropZoneInput"
        {...getInputProps({
          // Necessary to clean the previously uploaded file and trigger the onDrop event
          onClick: (event) => {
            const element: any = event.target;
            element.value = null;
          },
        })}
      />
    </div>
  );
};
