import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

const baseTextStyles = {
  fontFamily: 'Poppins',
  fontSize: 14,
  fontWeight: 400,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.33,
  letterSpacing: 'normal',
};

export default makeStyles(() => ({
  paragraph1: {
    ...baseTextStyles,
    color: colors.blue50,
  },
  error: {
    ...baseTextStyles,
    colors: colors.errorRed,
  },

  small: {
    ...baseTextStyles,
    fontSize: 10,
  },

  link: {
    ...baseTextStyles,
    color: colors.orange,
    textDecoration: 'underline',
    marginLeft: 5,
    cursor: 'pointer',
  },
}));
