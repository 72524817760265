import React, { useMemo, useState } from 'react';
import { UpgradePlanModal } from 'components/UpgradePlanModal';
import { VerifyEmailModal } from 'components/VerifyEmailModal';
import { useAuthenticationContext } from 'context';
import { http } from 'services/http';
import { AppSumo } from 'components/UpgradePlanModal/AppSumo.tsx';

const paymentMonthsMap: { [key: number]: number } = {
  0: 12,
  1: 1,
};

type UpgradeFlowModalsProps = {
  open: boolean;
  onClose(): void;
};

const currentlyOnAppsumoCampaing = false;

export const UpgradeFlowModals = ({
  open,
  onClose,
}: UpgradeFlowModalsProps) => {
  const [isVerifyEmailOpen, setIsVerifyEmailOpen] = useState<boolean>(false);
  const { user, logout } = useAuthenticationContext();
  const onUpgradeHandler = (planId: string, planPeriodIndex: number) => {
    http
      .put(
        'user/commercial-plan',
        JSON.stringify({
          new_commercial_plan_id: planId,
          payment_months: paymentMonthsMap[planPeriodIndex],
        }),
        {}
      )
      .then((response) => {
        onClose();
        setIsVerifyEmailOpen(true);
      });
  };

  const onCloseVerifyEmailHandler = () => {
    setIsVerifyEmailOpen(false);
  };

  const isAppSumoPlan = useMemo(
    () => user?.commercial_plan?.name.startsWith('AppSumo'),
    [user]
  );

  return (
    <>
      {isAppSumoPlan && currentlyOnAppsumoCampaing ? (
        <AppSumo
          open={open}
          onClose={onClose}
          onUpgradeHandler={onUpgradeHandler}
        />
      ) : (
        <UpgradePlanModal
          open={open}
          onClose={onClose}
          onUpgradeHandler={onUpgradeHandler}
        />
      )}
      <VerifyEmailModal
        email={user ? user.email : ''}
        open={isVerifyEmailOpen}
        onClose={onCloseVerifyEmailHandler}
        onResend={() => {}}
        onLogout={logout}
      />
    </>
  );
};
