import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './TypingLoader.styles';

export interface TypingLoaderProps {
  color?: string;
}

export const TypingLoader: FC<TypingLoaderProps> = ({ color }) => {
  const classes = useStyles({
    color: color || '',
  });
  return (
    <Grid className={classes.bouncingLoader}>
      <Grid className={classes.bouncingLoaderItem}></Grid>
      <Grid
        className={`${classes.bouncingLoaderItem} ${classes.bouncingLoaderItem2}`}
      ></Grid>
      <Grid
        className={`${classes.bouncingLoaderItem} ${classes.bouncingLoaderItem3}`}
      ></Grid>
    </Grid>
  );
};
