export const cleanObject = (obj: object) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) =>
      value === null || value === undefined || value === ''
        ? acc
        : ((acc[key] = value), acc),
    {} as { [key: string]: any }
  );

type ObjectResultProps = { [key: string]: number };

export const countObjectListKey = <T>(objList: T[], key: string) =>
  objList.reduce(
    (prev, curr: any) => ({
      ...prev,
      [curr[key]]: prev[curr[key]] ? prev[curr[key]] + 1 : 1,
    }),
    {} as ObjectResultProps
  );

export const getExperimentDescriptionText = (experimentDescription: string) => {
  if (!JSON.parse(experimentDescription).blocks) {
    return experimentDescription;
  }
  return JSON.parse(experimentDescription)
    .blocks.map((item: any) => item.text)
    .join(' ');
};
