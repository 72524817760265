import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './avatar.styles';

interface Props {
  img?: string;
  text?: string;
  className?: string;
}

export const Avatar = ({ img, text, className }: Props) => {
  const classes = useStyles();
  const initials = useMemo(() => {
    if (!text) {
      return '';
    }
    const splitted = text.split(' ').slice(0, 2);
    return splitted.map((s) => s[0]).join('');
  }, [text]);
  if (!text) {
    return null;
  }
  if (img) {
    return (
      <div className={`${classes.userImage} ${className ? className : ''}`}>
        <img src={img} alt={text} className={classes.img} />
      </div>
    );
  }
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={`${classes.initials} ${className ? className : ''}`}
    >
      <span className={classes.initialsText}>{initials}</span>
    </Grid>
  );
};
