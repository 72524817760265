import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  container: {
    maxWidth: 1128,
    height: '100%',
    margin: '0 auto',
  },
}));
