import React from 'react';
import { Text } from 'components';
import {
  Box,
  TableContainer,
  TableBody,
  TableRow,
  Table,
  TableCell,
  Paper,
} from '@material-ui/core';

const tableRows = [
  ['Face Similarity', '2 credits'],
  ['Text extraction from images (OCR)', '1 credit per image or page (PDF)'],
  ['Audio Transcription', '1 credit = 10 secs of audio'],
  ['Text, image, audio classification, and object detection', '1 credit'],
  ['Chat with your documents', '2 credits per message'],
  [
    'Smart Extractor (coming soon)',
    '2 credits per request, 40 credits using GPT-4',
  ],
];

export const FaqsData = [
  {
    question: 'What is an AI model?',
    answer: (
      <Text variant="paragraph1">
        An AI model is an algorithm or a set of algorithms that can learn from
        data and make predictions or decisions based on that learning. It can be
        trained on text, images, audio, or other types of data to be used in a
        wide range of applications, including image recognition, natural
        language processing, speech recognition, question-answering, and search.
      </Text>
    ),
  },
  {
    question: 'What type of AI models can I use or build?',
    answer: (
      <Box>
        <Text variant="paragraph1">
          We support several AI models capable of working on tasks to
          understand, analyze, or query text, image, or audio data. We offer
          ready-to-use or pre-trained models such as sentiment analysis, audio
          transcription, OCR to extract text from images, face recognition, etc.
          Please check all our models in Cogniflow dashboard under Public
          visibility.
        </Text>

        <br />
        <Text variant="paragraph1">
          We also offer the possibility of building your custom models to:
        </Text>
        <ul>
          <li>
            <Text variant="paragraph1">Classify a text, image, or audio.</Text>
          </li>
          <li>
            <Text variant="paragraph1">
              Identify and count specific elements or objects inside images.{' '}
            </Text>
          </li>
          <li>
            <Text variant="paragraph1">
              Create your own question-answering system or chatbot to ask
              questions about any documentation.
            </Text>
          </li>
          <li>
            <Text variant="paragraph1">
              Create your information extractor to get a structured result of
              the content of any document, image, or plain text. Some examples
              are passports, invoices, receipts, and ID scanners.
            </Text>
          </li>
        </ul>
      </Box>
    ),
  },
  {
    question: 'What is a credit?',
    answer: (
      <Box>
        <Text variant="paragraph1">
          A credit is the basic unit to compute the usage of AI models. For
          example, if you want to do a sentiment analysis in one piece of text,
          you will use 1 credit. Some models internally use other models or are
          more complex in computation that requires more credits to perform the
          task. For details, please check the table below:
        </Text>
        <br />
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {tableRows.map(([feature, credits], idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    <Text variant="paragraph1">{feature}</Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="paragraph1">{credits}</Text>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ),
  },
  {
    question: 'What are the hours to train models?',
    answer: (
      <Text variant="paragraph1">
        To train your own models, you need computation time. We offer excellent
        computation time in each plan.
        <br />
        <br />
        For example, training a custom sentiment analysis (text is positive or
        negative) with a dataset of 1000 samples/entries would take around 5
        minutes of computation time.
      </Text>
    ),
  },
  {
    question: 'What if I go over my credit limit?',
    answer: (
      <Text variant="paragraph1">
        To continue using the models, you need to upgrade your plan or wait
        until your monthly usage credit resets.
        <br /> <br />
        When the 80% of your monthly credits are reached, you will be notified
        over email.
      </Text>
    ),
  },
  {
    question: 'Do I need to know how to code?',
    answer: (
      <Text variant="paragraph1">
        Nope! Cogniflow makes it easy for you to train and use models without
        being a developer. We also offer several integrations and deployment
        options to make your life easier.
      </Text>
    ),
  },
];
