import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  entriesContainer: {
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
    },
    '&::-webkit-scrollbar-thumb:active': {
      background: 'rgba(0, 0, 0, 0.6)',
    },
  },
  addEntryWrapper: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  entitiesTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
  },
  tableContainer: {
    backgroundColor: '#fff',
    padding: '14px 28px 28px 28px',
    borderRadius: '16px',
  },
  actionButton: {
    borderRadius: '5px',
    width: '120px',
    padding: '10px',
    '& .MuiButton-label': {
      fontSize: '12px',
    },
  },
}));
